import ManageComparison from '@/organisms/comparison/ManageComparison';
import { EntityType } from '@/shared/enums';
import { useViewModeContext, useScenarioContext } from '@/shared/providers';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import InsightsTitle from '../InsightsTitle/InsightsTitle';
import ActivitySegments from './ActivitySegments';
import PropertySegments from './PropertySegments';
import RolesByTeam from './RolesByTeam';

const RoleInsights = () => {
  const { scenario, snapshotEntityMap, comparisonScenario } =
    useScenarioContext();
  const { id, scope, scopeEntity } = useViewModeContext();

  const entityType = scenario?.entity?.is_manager
    ? EntityType.MANAGER
    : scenario?.entity?.__type;

  const isActiveComparison = !!comparisonScenario;

  return (
    <Grid container spacing={2} pb={4}>
      <Grid item xs={12}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <InsightsTitle
            entityMap={snapshotEntityMap}
            id={id}
            scope={scope}
            scopeEntity={scopeEntity}
          />
          <ManageComparison />
        </Stack>
      </Grid>
      {scenario && (
        <>
          <Grid item xs={12}>
            <RolesByTeam
              levelType={entityType}
              showComparison={isActiveComparison}
            />
          </Grid>
          <Grid item xs={12}>
            <ActivitySegments
              levelType={entityType}
              showComparison={isActiveComparison}
            />
          </Grid>
          <Grid item xs={12}>
            <PropertySegments
              levelType={entityType}
              showComparison={isActiveComparison}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default RoleInsights;
