import { createOpenAI } from '@ai-sdk/openai';
import env from '@beam-australia/react-env';

const client = {
  createOpenAI: (options) => {
    let accessToken = '';

    if (typeof window !== 'undefined') {
      accessToken = window.localStorage.getItem('accessToken');
    }

    const openai = createOpenAI({
      apiKey: accessToken, // handled by the api proxy but required by this library
      baseURL: `${env('API_HOST')}/ai`, // API proxy
      baseFetch: (url, options) => {
        const headers = {
          ...options.headers,
          Authorization: `Bearer ${accessToken}`, // Add your custom token here
        };
        return fetch(url, { ...options, headers });
      },
      compatibility: 'strict',
    });

    return openai;
  },
};

const openai = client.createOpenAI();

export default openai;
