import { useRouter } from 'next/router';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
  useMemo,
} from 'react';
import { EntityType, ScopeType, ViewMode } from '@/shared/enums';
import { useAccess } from '@pkg/access/organisations';
import useBudgetVisibility from './hooks/useBudgetVisibility';
import { buildSearchParams, defaultScope, selectedScopeEntity } from './utils';

const DEFAULT_SCOPE_ENTITY = EntityType.GROUP;

export const ViewModeContext = createContext();
export const useViewModeContext = () => useContext(ViewModeContext);

const ViewModeProvider = ({ children }) => {
  const router = useRouter();
  const access = useAccess();
  const hasAdminAccess = access.model.manage;
  const urlSearchParams = new URLSearchParams(window.location.search);
  const viewMode = urlSearchParams.get('view');
  const scopeType = urlSearchParams.get('scope');
  const entity = selectedScopeEntity(urlSearchParams);
  const [view, setView] = useState(ViewMode[viewMode] ?? ViewMode.DIAGRAM);
  const [scope, setScope] = useState(
    ScopeType[scopeType] ?? defaultScope(viewMode)
  );
  const [scopeEntity, setScopeEntity] = useState(
    entity ?? DEFAULT_SCOPE_ENTITY
  );
  const selectedId = urlSearchParams.get(scopeEntity);
  const [id, setId] = useState(selectedId);
  const { canAccessBudget, showBudget, updateBudgetVisibility } =
    useBudgetVisibility();

  // Resets the scope based on a newly selected view mode.
  const selectViewMode = useCallback((value) => {
    setView(value);
    const scope = defaultScope(value);
    setScope(scope);
    setScopeEntity(DEFAULT_SCOPE_ENTITY);
    setId(null);
  }, []);

  // Resets the scope entity based on the selected scope.
  const selectScope = (value) => {
    setScope(value);
    const redirectActivityScope =
      value === ScopeType.ROLES || value === ScopeType.SPANS_LAYERS;
    if (redirectActivityScope && scopeEntity === EntityType.ACTIVITY) {
      setScopeEntity(EntityType.GROUP);
      setId(null);
    }
  };

  // Updates the scope entity and resets the id.
  const selectScopeEntity = (value) => {
    setScopeEntity(value);
    setId(null);
  };

  const selectId = (scopeEntity, value) => {
    setId(value);
    setScopeEntity(scopeEntity ?? DEFAULT_SCOPE_ENTITY);
  };

  useEffect(() => {
    const { query } = router;
    const { compare } = query;
    const viewChanged = query.view !== view;
    const scopeChanged = query.scope !== scope;

    // If nothing in the route has changed we can exit.
    if (!viewChanged && !scopeChanged && query[scopeEntity] === id) {
      return;
    }

    // We want to redirect to the summary if we're attempting to access a
    // restricted area.
    const redirectRestricted =
      scope === ScopeType.SPANS_LAYERS && !hasAdminAccess;

    if (redirectRestricted) {
      selectScope(ScopeType.SUMMARY);
    }

    let updatedScopeEntity = scopeEntity;
    let updatedId = id;

    // Reset the scope entity and id if the view or scope has changed.
    if (viewChanged) {
      updatedScopeEntity = DEFAULT_SCOPE_ENTITY;
      updatedId = null;
      setScopeEntity(updatedScopeEntity);
      setId(updatedId);
    }

    const queryParams = buildSearchParams({
      view,
      scope,
      scopeEntity: updatedScopeEntity,
      id: updatedId,
      compare,
      query,
    });

    router.push(queryParams, undefined, {
      shallow: true,
    });
  }, [view, scope, scopeEntity, id]);

  const context = useMemo(() => {
    return {
      canAccessBudget,
      id,
      scope,
      scopeEntity,
      selectViewMode,
      selectScope,
      selectScopeEntity,
      selectId,
      showBudget,
      updateBudgetVisibility,
      view,
    };
  }, [canAccessBudget, id, scope, scopeEntity, showBudget, view]);

  return (
    <ViewModeContext.Provider value={context}>
      {children}
    </ViewModeContext.Provider>
  );
};

export default ViewModeProvider;
