import { DesignLevel } from '@/lib/enums';

export const ContextType = Object.freeze({
  ACTIVITY: 'ACTIVITY',
  ACTIVITY_ADD: 'ACTIVITY_ADD',
  BULK_ACTIONS: 'BULK_ACTIONS',
  CALENDAR: 'CALENDAR',
  COMPARE: 'COMPARE',
  COPY_AS_SCENARIO: 'COPY_AS_SCENARIO',
  EXPORT: 'EXPORT',
  FILTERS: 'FILTERS',
  LENS: 'LENS',
  GROUP: 'GROUP',
  GROUP_ADD: 'GROUP_ADD',
  GROUP_ADD_BULK: 'GROUP_ADD_BULK',
  GROUP_ARCHIVE: 'GROUP_ARCHIVE',
  GROUP_PRIORITIZE: 'GROUP_PRIORITIZE',
  GROUP_SUPPORTERS: 'GROUP_SUPPORTERS',
  GROUP_REBASE: 'GROUP_REBASE',
  ORGANISATION: 'ORGANISATION',
  HISTORY: 'HISTORY',
  INSIGHTS: 'INSIGHTS',
  IMPORT: 'IMPORT',
  LIBRARY_ROLE: 'LIBRARY_ROLE',
  LIBRARY_ROLE_ADD: 'LIBRARY_ROLE_ADD',
  LIBRARY_ROLES: 'LIBRARY_ROLES',
  MANAGER: 'MANAGER',
  PERSON: 'PERSON',
  PROPERTIES: 'PROPERTIES',
  PULSE: 'PULSE',
  ROLE: 'ROLE',
  ROLE_ADD: 'ROLE_ADD',
  ROLE_ARCHIVE: 'ROLE_ARCHIVE',
  ROLE_COPY: 'ROLE_COPY',
  ROLE_INVITE: 'ROLE_INVITE',
  ROLE_MOVE: 'ROLE_MOVE',
  ROLE_CHANGE_PARENT: 'ROLE_CHANGE_PARENT',
  ROLE_OVERVIEW: 'ROLE_OVERVIEW',
  ROLE_PRIORITIZE: 'ROLE_PRIORITIZE',
  ROLE_PUBLISH: 'ROLE_PUBLISH',
  ROLE_SAVE_TO_LIBRARY: 'ROLE_SAVE_TO_LIBRARY',
  ROLE_UPDATE_FROM_LIBRARY: 'ROLE_UPDATE_FROM_LIBRARY',
  SCENARIO_DETAILS: 'SCENARIO_DETAILS',
  SEARCH: 'SEARCH',
  SETTINGS: 'SETTINGS',
  SHARE: 'SHARE',
  SKILL_PEOPLE: 'SKILL_PEOPLE',
  TAGS: 'TAGS',
  UNALLOCATED: 'UNALLOCATED',
});

export const DesignLayout = Object.freeze({
  DIAGRAM: 'DIAGRAM',
  LIST: 'LIST',
});

export const AllowedLayouts = Object.freeze({
  [DesignLevel.GROUP]: new Set([DesignLayout.DIAGRAM]),
  [DesignLevel.INSIGHTS]: new Set([]),
  [DesignLevel.MANAGER]: new Set([DesignLayout.DIAGRAM]),
  [DesignLevel.ORGANISATION]: new Set([DesignLayout.DIAGRAM]),
  [DesignLevel.PERSON]: new Set([DesignLayout.DIAGRAM]),
  [DesignLevel.ROLE]: new Set([DesignLayout.DIAGRAM, DesignLayout.LIST]),
  [DesignLevel.LIBRARY_ROLES]: new Set([DesignLayout.DIAGRAM]),
});

export const ExportableImageTypes = Object.freeze({
  PNG: 'PNG',
  SVG: 'SVG',

  /**
   * @param {String} [value]
   * @returns {String|undefined}
   */
  maxSize(value) {
    const maxSize = {
      [this.PNG]: 8000,
      [this.SVG]: null,
    };
    return maxSize[value?.toUpperCase()];
  },
});
