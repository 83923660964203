import { tool } from 'ai';
import { z } from 'zod';

const groupUpdate = tool({
  description: 'Update an existing group in the organizational design',
  parameters: z.object({
    payload: z
      .object({
        uuid: z.string().describe('The UUID of the group to update'),
        name: z.string().optional().describe('The name of the group'),
        tags: z
          .array(z.string())
          .optional()
          .describe('Array of tag UUIDs associated with this group'),
        parent_uuid: z
          .string()
          .optional()
          .describe('The UUID of the parent group'),
        group_uuid: z
          .string()
          .optional()
          .describe('The UUID of the group this group belongs to'),
      })
      .describe('The update payload for the group'),
    explanation: z
      .string()
      .describe(
        'An explanation of what is about to be updated. This step will be asked to be confirmed. Phrase it accordingly.'
      ),
  }),
  execute: async ({ uuid, payload, explanation }) => ({
    uuid,
    payload: {
      ...payload,
      updated_at: Date.now() / 1000, // Current epoch time in seconds
    },
    explanation,
  }),
});

export default groupUpdate;
