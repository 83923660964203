import { useRef, useState } from 'react';
import { PlanListHeading } from '@/organisms/plans';
import { EntityType } from '@/shared/enums';
import { usePlanContext } from '@/shared/providers';
import Box from '@mui/material/Box';
import PlanActivityType from './PlanActivityType';
import PlanActivityTypeList from './PlanActivityTypeList';

const PlanActivities = () => {
  const { planType, selectedSummaryItem, selectSummaryItem } = usePlanContext();
  const [selectedPlanItem, setSelectedPlanItem] = useState();
  const headingRef = useRef();
  const headingHeight = selectedPlanItem
    ? headingRef?.current?.offsetHeight || 44
    : 0;

  const selectItem = (item) => {
    if (!item) {
      selectSummaryItem();
      setSelectedPlanItem();
      return;
    }

    setSelectedPlanItem(item);
  };

  return (
    <>
      {selectedPlanItem && (
        <PlanListHeading
          entityType={EntityType.ACTIVITY}
          name="activities"
          title={selectedPlanItem?.props.title}
          onClick={() => selectItem()}
          ref={headingRef}
        />
      )}
      <Box
        height={`calc(100vh - 198px - ${headingHeight}px)`}
        sx={{
          overflowY: 'auto',
        }}
      >
        {!selectedPlanItem && <PlanActivityTypeList onSelect={selectItem} />}
        {selectedPlanItem && (
          <PlanActivityType
            activityType={selectedPlanItem}
            onSelect={selectItem}
          />
        )}
      </Box>
    </>
  );
};

export default PlanActivities;
