import { EntityPluralName, EntityType } from '@/shared/enums';

export default function determineEntityChange({
  metrics,
  entityCount,
  entityType,
}) {
  const metricType =
    EntityPluralName[entityType]?.toLowerCase() ??
    EntityPluralName[EntityType.ROLE]?.toLowerCase();
  const comparisonMetric = metrics[1]?.[metricType] || 0;
  const scenarioMetric = metrics[0]?.[metricType] || 0;

  const entityCountDifference = comparisonMetric - scenarioMetric;

  if (entityCountDifference !== 0) {
    return {
      label: entityCountDifference > 0 ? 'Added' : 'Removed',
      value: entityCountDifference,
    };
  }

  return {
    label: 'Changed',
    value: entityCount,
  };
}
