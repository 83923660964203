import { ActionType, EntityType } from '@/shared/enums';

const calculateRoleChanges = (actions, changes) => {
  if (actions.has(ActionType.ADDED_ROLE)) {
    changes.added += 1;
    return changes;
  }

  if (actions.has(ActionType.REMOVED_ROLE)) {
    changes.removed += 1;
    return changes;
  }

  if (actions.has(ActionType.UPDATED_MANAGER)) {
    changes.moved += 1;
    return changes;
  }

  changes.changed += 1;

  return changes;
};

const calculateActivityChanges = (actions, changes) => {
  if (actions.has(ActionType.MOVED_ACTIVITY)) {
    changes.moved += 1;
    return changes;
  }

  if (actions.has(ActionType.ADDED_ACTIVITY)) {
    changes.added += 1;
    return changes;
  }

  if (actions.has(ActionType.REMOVED_ACTIVITY)) {
    changes.removed += 1;
    return changes;
  }

  changes.changed += 1;

  return changes;
};

export default function entityChanges(entityMap, entityType) {
  const changes = {
    added: 0,
    removed: 0,
    moved: 0,
    changed: 0,
  };

  [...entityMap.values()].forEach((entity) => {
    switch (entityType) {
      case EntityType.ROLE:
        calculateRoleChanges(entity.actions, changes);
        break;
      default:
        calculateActivityChanges(entity.actions, changes);
    }
  });

  return changes;
}
