import { Avatar } from '@/atoms/avatars';
import * as Colors from '@/atoms/colors';
import { Size } from '@/atoms/enums';
import { Heading } from '@/atoms/typography';
import { FlowList } from '@/organisms/flows';
import { RoleList } from '@/organisms/roles';
import { TeamList } from '@/organisms/teams';
import { ViewMode } from '@/shared/enums';
import { useUserRelatedEntities } from '@/shared/hooks';
import { avatarImage } from '@/shared/utils/profile';
import Grid from '@mui/material/Grid2';
import Stack from '@mui/material/Stack';
import * as Auth from '@pkg/auth';

const RestrictedDashboardLayout = () => {
  const { me } = Auth.useStore();
  const { groups } = useUserRelatedEntities({ liveDesignsOnly: true });

  return (
    <Stack
      sx={{
        backgroundColor: Colors.Base.background.secondary,
        color: Colors.Base.font.primary,
        height: 'calc(100vh)',
      }}
    >
      <div
        style={{
          overflowX: 'hidden',
          overflowY: 'auto',
          scrollBehavior: 'smooth',
        }}
      >
        <Grid container direction="row" py={8} px={4} spacing={6}>
          <Grid item size={{ xs: 12, md: 9, lg: 6 }} offset={{ md: 2, lg: 3 }}>
            <Stack mb={2}>
              <Avatar
                name={`${me.first_name} ${me.last_name}`}
                size={Size.X_LARGE}
                src={avatarImage(me)}
              />
            </Stack>
            <Heading variant="h2" overrideStyles={{ mb: 0.5 }}>
              Hello <span style={{ fontWeight: 700 }}>{me?.first_name},</span>
            </Heading>
            <Heading variant="h3">Welcome back to Beamible!</Heading>
          </Grid>
          <Grid item size={{ xs: 12, md: 9, lg: 6 }} offset={{ md: 2, lg: 3 }}>
            <FlowList />
          </Grid>
          <Grid item size={{ xs: 12, md: 9, lg: 6 }} offset={{ md: 2, lg: 3 }}>
            <RoleList
              allowLinks={!me?.is_role_only}
              viewMode={ViewMode.INSIGHTS}
            />
          </Grid>
          {groups?.length > 0 && (
            <Grid
              item
              size={{ xs: 12, md: 9, lg: 6 }}
              offset={{ md: 2, lg: 3 }}
            >
              <TeamList viewMode={ViewMode.INSIGHTS} />
            </Grid>
          )}
        </Grid>
      </div>
    </Stack>
  );
};

export default RestrictedDashboardLayout;
