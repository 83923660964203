import { useMutation } from '@pkg/graphql';

const CREATE_LIBRARY_ROLE = /* GraphQL */ `
  mutation createLibraryRole($input: CreateLibraryRoleInput!) {
    createLibraryRole(input: $input) {
      uuid
    }
  }
`;

/**
 * @return {Function}
 */
export default function useCreate() {
  const { mutateAsync } = useMutation({
    mutation: CREATE_LIBRARY_ROLE,
  });

  /**
   * @param {String} organisation_uuid
   * @param {Object} input
   * @param {Object} input.snapshot
   * @returns {Promise}
   */
  async function createLibraryRole(organisation_uuid, { snapshot }) {
    return mutateAsync({ organisation_uuid, snapshot });
  }

  return createLibraryRole;
}
