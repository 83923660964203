import { useEffect, useState } from 'react';
import { Orientation } from '@/atoms/enums';
import {
  DimensionSelect,
  MetricTypeSelect,
  RoleDimensions,
} from '@/molecules/chartElements';
import { RolesByTeamChartInformation } from '@/molecules/information';
import { ChartCard } from '@/organisms/cards';
import { BarChart } from '@/organisms/charts';
import { EmptyInsights, useAggregateChartData } from '@/organisms/insights';
import { EntityType } from '@/shared/enums';
import { useInsightsContext, useViewModeContext } from '@/shared/providers';
import Stack from '@mui/material/Stack';
import { EntityMetric } from '@/lib/enums';

const metricOptions = [
  EntityMetric.RELATIVE_PERCENTAGE,
  EntityMetric.PERCENTAGE,
  EntityMetric.FTE,
  EntityMetric.HOURS,
  EntityMetric.BUDGET,
];

const RolesByTeam = ({ levelType, showComparison }) => {
  const [metric, setMetric] = useState(metricOptions[0]);
  const [dimension, setDimension] = useState(RoleDimensions[levelType]?.[0]);
  const [sort, setSort] = useState({
    metric: metricOptions[0],
    type: 'DESC',
  });
  const { filter } = useInsightsContext();
  const { showBudget } = useViewModeContext();

  const { chartData, metricTotals } = useAggregateChartData({
    entityType: EntityType.ROLE,
    dimension,
    filter,
    sort,
    useComparison: showComparison,
  });

  const hasRoles = metricTotals?.roles > 0;

  const handleDimensionChange = (dimension) => {
    setDimension(dimension);
  };

  const handleMetricTypeChange = (metric) => {
    const isPercentageMetric =
      metric.id === EntityMetric.PERCENTAGE ||
      metric.id === EntityMetric.RELATIVE_PERCENTAGE;
    setMetric(metric.id);
    setSort({
      ...sort,
      metric: isPercentageMetric ? EntityMetric.HOURS : metric.id,
    });
  };

  // This effect resets the chart if it's showing metric data and budgets have
  // been switched off.
  useEffect(() => {
    if (!showBudget && metric === EntityMetric.BUDGET) {
      setMetric(EntityMetric.PERCENTAGE);
    }
  }, [showBudget]);

  return (
    <ChartCard
      title="How roles are distributed"
      InfoComponent={<RolesByTeamChartInformation />}
      ControlsComponent={
        hasRoles && (
          <Stack direction="row" alignItems="center" spacing={2}>
            <DimensionSelect
              dimensions={RoleDimensions[levelType]}
              label="Roles by"
              onChange={handleDimensionChange}
            />
            <MetricTypeSelect
              options={metricOptions}
              onChange={handleMetricTypeChange}
            />
          </Stack>
        )
      }
    >
      {hasRoles ? (
        <BarChart
          chartData={chartData}
          id="roles-by-team-chart"
          metric={metric}
          metricTotals={metricTotals}
          sort={sort}
          orientation={Orientation.VERTICAL}
        />
      ) : (
        <EmptyInsights message="No roles were found" />
      )}
    </ChartCard>
  );
};

export default RolesByTeam;
