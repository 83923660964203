import { memo } from 'react';
import { Button } from '@/atoms/buttons';
import { Chip, EntityChip } from '@/atoms/chips';
import { Entity as EntityColors } from '@/atoms/colors';
import { Size } from '@/atoms/enums';
import { Heading } from '@/atoms/typography';
import { ChangeMetric } from '@/molecules/metrics';
import { OutlineCard } from '@/organisms/cards';
import { EntityType } from '@/shared/enums';
import { useViewModeContext } from '@/shared/providers';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { core } from '@/lib/theme/tokens/palettes';
import { determineChartType, determineMetric } from './utils';

const PlanCard = ({
  title,
  entityType = EntityType.ROLE,
  actionType,
  isSelected,
  metrics,
  onEdit,
  onRemove,
}) => {
  const { showBudget } = useViewModeContext();
  const entityColor = EntityColors[entityType];
  const metric = determineMetric({ actionType, metrics, showBudget });
  const chartType = determineChartType(metric);

  return (
    <Stack direction="row" alignItems="center" width="100%">
      <Box width="136px" mr={1}>
        <ChangeMetric
          chartType={chartType}
          colors={{
            foreground: entityColor.primary,
            background: entityColor.secondary,
          }}
          entityType={entityType}
          metric={metric}
          metrics={metrics}
        />
      </Box>
      <Box flexGrow={1}>
        <OutlineCard padding={0} margin={0}>
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="space-between"
            p={1.5}
            spacing={1}
            sx={{
              boxShadow: isSelected
                ? `inset 0 0 0 1px ${entityColor.primary}, 0 0 0 1px ${entityColor.primary}`
                : 'none',
              borderRadius: '4px',
              cursor: 'pointer',
              transition: 'box-shadow 200ms',
              '&: hover': !isSelected && {
                boxShadow: `inset 0 0 0 1px ${entityColor.secondary}, 0 0 0 1px ${entityColor.secondary}`,
              },
            }}
          >
            <Box>
              {actionType && (
                <Chip
                  id={actionType}
                  name={actionType.toUpperCase()}
                  colorProps={{
                    normal: {
                      backgroundColor: entityColor.primary,
                      color: core.white,
                    },
                  }}
                  size={Size.XX_SMALL}
                  fontWeight={600}
                  sx={{ height: 20, px: 1 }}
                />
              )}
              {!actionType && (
                <EntityChip
                  size={Size.XX_SMALL}
                  sx={{ height: 20, px: 1 }}
                  type={entityType}
                />
              )}
              <Heading
                variant="h4"
                overrideStyles={{ fontSize: '1.025rem', mb: 0, mt: 0.5 }}
              >
                {title}
              </Heading>
            </Box>
            <Button
              label="View"
              color="light-blue"
              size={Size.SMALL}
              onClick={onEdit}
            />
          </Stack>
        </OutlineCard>
      </Box>
    </Stack>
  );
};

export default memo(PlanCard, (previous, next) => {
  return previous?.isSelected === next?.isSelected;
});
