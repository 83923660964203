import { DEFAULT_ACCESS } from '@pkg/access/designs/useAccess';

export const ADDITIONAL_DESIGN = {
  comparisonColor: null,
  design: null,
  entity: null,
  revision: null,
  snapshot: null,
  snapshotEntityMap: null,
};

export const INITIAL_DATA_STATE = Object.freeze({
  access: DEFAULT_ACCESS,
  activeComparisonIndex: null,
  additional: [],
  design: null,
  difference: null,
  error: null,
  isError: false,
  isHistorical: false,
  isLoading: true,
  isPublic: true,
  isReadOnly: true,
  isReady: false,
  isScenario: false,
  key: null,
  revision: null,
  snapshot: null,
});

export default function createDataSlice() {
  return INITIAL_DATA_STATE;
}
