import openai from '../client';
import { toolsConfig } from '../tools/config';

const YAML = require('json-to-pretty-yaml');

const navigatePrompt = async ({ userText, snapshot, lastMessages }) => {
  const { roles, groups, activities } = snapshot.entities;

  const prompt = {
    model: openai('gpt-4-turbo'),
    tools: {
      navigateTo: toolsConfig.navigateTo.tool,
    },
    prompt: `
      You are a URL navigation assistant for an organizational design application. Your role is to generate URLs that help users navigate to specific views and data in the application.

      Available URL Patterns:
      1. Design View URLs:
         - /d/main/latest - View the latest design
         - /d/main/latest?view=DIAGRAM - View the latest design in diagram view
         - /d/main/latest?view=TABLE - View the latest design in table view
         - /d/main/latest?view=INSIGHTS - View insights for the latest design
         - /d/main/latest?view=PLAN - View scenario plans

      2. Role-specific URLs:
         - /d/main/latest/r/:uuid - View a specific role
         - /d/main/latest/r/:uuid?view=DIAGRAM&scope=ACTIVITIES - View role activities in diagram
         - /d/main/latest/r/:uuid?view=TABLE&scope=ACTIVITIES - View role activities in table
         - /d/main/latest/r/:uuid?view=INSIGHTS&scope=SUMMARY - View role insights summary
         - /d/main/latest/r/:uuid?view=PLAN - View role scenario plans

      3. Group-specific URLs:
         - /d/main/latest/g/:uuid - View a specific group
         - /d/main/latest/g/:uuid?view=DIAGRAM&scope=ACTIVITIES - View group activities in diagram
         - /d/main/latest/g/:uuid?view=TABLE&scope=ACTIVITIES - View group activities in table
         - /d/main/latest/g/:uuid?view=INSIGHTS&scope=SUMMARY - View group insights summary
         - /d/main/latest/g/:uuid?view=PLAN - View group scenario plans

      Available Groups and Roles:
      ${YAML.stringify({
        groups: groups.map((group) => ({
          name: group.name,
          uuid: group.uuid,
          roles: roles
            .filter((role) => role.group_uuid === group.uuid)
            .map((role) => ({
              uuid: role.uuid,
              title: role.title,
            })),
        })),
      })}

               Last messages;
          ${lastMessages.map((msg) => `${msg.sender}: ${msg.message}`).join('\n')}

          The last messages are included to help you understand the context of the users request. BUT MAKE SURE TO IGNORE THEM IF THEY ARE NOT RELEVANT. Your response prioritizes the users request if previous mesasages aren't relevant.

      Based on the user's request, generate an appropriate URL using these patterns. Focus on providing the most relevant view for their needs.

      User request: ${userText}
    `,
  };

  return prompt;
};

export default navigatePrompt;
