import { MetricProperty } from '@/atoms/enums';
import { Size } from '@/atoms/enums';
import { Paragraph } from '@/atoms/typography';
import { ChartTooltip } from '@/molecules/chartElements';
import { VerticalBarLabel } from '@/organisms/charts';
import Stack from '@mui/material/Stack';
import { format } from '@pkg/utils/numbers';
import { EntityMetric } from '@/lib/enums';
import VerticalBar from '../VerticalBar';
import VerticalStackBar from '../VerticalStackBar';

const BAR_VALUE_HEIGHT = 22;

const VerticalRow = ({
  chartHeight,
  expanded,
  fullStackChart,
  isPercentageMetric,
  labelHeight,
  minBarSize = 8,
  maxBarValue,
  metric,
  metricTotals,
  onClick,
  padding = 8,
  row,
  width,
  xScale,
  yScale,
}) => {
  const scaleMetric =
    metric === EntityMetric.RELATIVE_PERCENTAGE
      ? EntityMetric.PERCENTAGE
      : metric;
  const value = isPercentageMetric
    ? row.data[metric] > 0
      ? row.data[metric] / 100
      : 0
    : row.data[scaleMetric];
  const fullHeightLabel = Boolean(fullStackChart && row.stackData);
  const multiplier =
    fullStackChart && row.isStackChild
      ? maxBarValue / row.parentData?.[scaleMetric]
      : 1;

  const formatOptions = MetricProperty[metric]?.formatOptions;
  const displayValue = formatOptions
    ? format(value, {
        ...formatOptions,
        notation: 'compact',
      })
    : row.data[metric];
  const barPadding = padding / 2;

  // Get the x position of the bar.
  const xPosition = xScale(row.id) + labelHeight / 6;
  // Get the y position of the bar.
  const yPosition = yScale(
    fullHeightLabel ? maxBarValue : row.data[scaleMetric] * multiplier
  );
  // Get the y comparison position of the bar.
  const comparisonValue = fullHeightLabel
    ? maxBarValue
    : (row.comparison?.data?.[scaleMetric] ?? 0) * multiplier;
  const yComparison = yScale(comparisonValue);
  // Set a minimum bar height.
  const height = yPosition > minBarSize ? yPosition : minBarSize;

  const handleClick = (event) => {
    onClick?.(row);
  };

  return (
    <g className="vertical-chart-row" x={xPosition} width={width}>
      <foreignObject
        x={xPosition}
        y={chartHeight - height - BAR_VALUE_HEIGHT}
        width={width - barPadding}
        height={18}
      >
        <Stack
          direction="row"
          justifyContent="center"
          width={width - barPadding}
        >
          <Paragraph
            size={Size.X_SMALL}
            overrideStyles={{
              mb: 0,
              fontWeight: 600,
            }}
          >
            {displayValue}
          </Paragraph>
        </Stack>
      </foreignObject>
      <rect
        x={xPosition}
        y={0}
        width={width - barPadding}
        height={chartHeight + 40}
        fill="transparent"
        onClick={handleClick}
        style={{
          cursor: 'pointer',
        }}
      />
      {row.stackData ? (
        <VerticalStackBar
          barWidth={width + barPadding}
          chartHeight={chartHeight}
          fullStackChart={fullStackChart}
          row={row}
          maxBarValue={maxBarValue}
          metric={metric}
          metricTotals={metricTotals}
          onClick={handleClick}
          xPosition={xPosition}
          yScale={yScale}
        />
      ) : (
        <>
          <VerticalBar
            barWidth={width + barPadding}
            chartHeight={chartHeight}
            fullStackChart={fullStackChart}
            maxBarValue={maxBarValue}
            onClick={handleClick}
            row={row}
            metric={scaleMetric}
            metricTotals={metricTotals}
            xPosition={xPosition}
            yPosition={yPosition}
            yComparison={yComparison}
          />
          <ChartTooltip
            item={row}
            maxWidth={500}
            metric={metric}
            metricTotals={metricTotals}
            minWidth={200}
          >
            <foreignObject
              x={xPosition}
              y={chartHeight - height - BAR_VALUE_HEIGHT}
              onClick={handleClick}
              width={width}
              height={chartHeight}
              style={{ cursor: 'pointer' }}
            />
          </ChartTooltip>
        </>
      )}
      <VerticalBarLabel
        row={row}
        width={width}
        expanded={expanded.get(row.id)}
        metric={metric}
        metricTotals={metricTotals}
        onClick={handleClick}
        labelHeight={labelHeight}
        height={labelHeight}
        xPosition={xPosition}
        yPosition={chartHeight}
      />
      <rect
        x={xPosition}
        y={chartHeight}
        width={width - barPadding}
        height={40}
        fill="transparent"
        onClick={handleClick}
        style={{
          cursor: 'pointer',
        }}
      />
    </g>
  );
};

export default VerticalRow;
