import activityUpdateReducer from '@pkg/entities/snapshots/reducers/activities/update';
import groupUpdateReducer from '@pkg/entities/snapshots/reducers/groups/update';
import roleAddReducer from '@pkg/entities/snapshots/reducers/roles/add';
import roleUpdateReducer from '@pkg/entities/snapshots/reducers/roles/update';
import createFilterTool from './filters/createFilter';
import navigateTo from './navigate/navigateTo';
import activityUpdateTool from './reducers/activities/update';
import groupUpdateTool from './reducers/groups/update';
import roleAddTool from './reducers/roles/add';
import roleUpdateTool from './reducers/roles/update';

export const toolsConfig = {
  activity_update: {
    title: 'Update Activities',
    description:
      'Modify existing activities including their hours, tags, and properties',
    category: 'Activities',
    tool: activityUpdateTool,
    reducer: activityUpdateReducer,
    prompt: `
      Rules for updating activities:
      1. Only update activities that match the specified UUID
      2. Hours should be updated based on realistic time allocations
      3. Tags should be valid UUIDs from the available tags
      4. Maintain consistency with existing activity descriptions
    `,
  },
  role_add: {
    title: 'Add Roles',
    description: 'Create new roles with associated activities',
    category: 'Roles',
    tool: roleAddTool,
    reducer: roleAddReducer,
    prompt: `
      Rules for adding roles:
      1. If adding multiple of the same role, use the total key
      2. Create 10-15 activities per role unless explicitly told otherwise
      3. Each activity description MUST be approximately 15 words long
      4. Activity descriptions should be detailed and specific to the role's responsibilities
      5. Break down high-level responsibilities into specific, actionable activities
      6. Default values: fte = 1.0, budget = 100000
      7. Keep group_uuid as null unless explicitly specified
    `,
  },
  role_update: {
    title: 'Update Roles',
    description:
      'Modify existing roles including their title, FTE, budget, and relationships',
    category: 'Roles',
    tool: roleUpdateTool,
    reducer: roleUpdateReducer,
    prompt: `
      Rules for updating roles:
      1. Only update roles that match the specified UUID
      2. Title changes should maintain professional terminology
      3. FTE updates should be between 0.0 and 2.0
      4. Budget updates should be realistic for the role level
      5. Parent ID must be a valid existing role UUID
    `,
  },
  group_update: {
    title: 'Update Groups',
    description:
      'Modify existing groups including their name, tags, and relationships',
    category: 'Groups',
    tool: groupUpdateTool,
    reducer: groupUpdateReducer,
    prompt: `
      Rules for updating groups:
      1. Only update groups that match the specified UUID
      2. Name changes should be clear and descriptive
      3. Parent UUID must be a valid existing group UUID
      4. Group UUID must be a valid existing group UUID
      5. Tags should be valid UUIDs from the available tags
    `,
  },
  create_filter: {
    title: 'Create Filters',
    description:
      'Create filter configurations to find specific data in the organization',
    category: 'Filters',
    tool: createFilterTool,
    reducer: null,
    prompt: `
      Rules for creating filters:
      1. When comparing titles use CONTAINS over EQUALS
      2. When comparing tags use HAS_ITEM_ANY
      3. When comparing hours use GREATER_THAN or LESS_THAN
      4. When comparing dates use AFTER or BEFORE
      5. For budget/hours/FTE on roles and teams, set metricProperty to '__total_metrics'
      6. For activity nature, use library_uuid with ENTITY_IS_ANY
      7. Time conversions: 30 minutes = 0.5, 1 day = 24 hours
      8. Return numbers as numbers, not strings
    `,
  },
  navigateTo: {
    title: 'Navigate To',
    category: 'Navigation',
    description: 'Navigate to specific urls in the application',
    prompt: `
      Rules for navigation:
      1. Navigate to any URL within the JavaScript application
      2. Common destinations include:
         - View types: TABLE, INSIGHTS, DESIGN, PLAN
         - Specific groups or roles (requires valid UUID)
         - Dashboard views and reports
         - Configuration pages
      3. Always provide a clear explanation of where the URL will navigate to
      4. URLs should be properly formatted and encoded if necessary
      5. Ensure the destination exists within the application
    `,
    tool: navigateTo,
    reducer: null,
  },
};

// Helper to get all tools
export const getTools = () => {
  const tools = {};
  Object.entries(toolsConfig).forEach(([key, config]) => {
    tools[key] = config.tool;
  });
  return tools;
};

// Helper to get all reducers
export const getReducers = () => {
  const reducers = {};
  Object.entries(toolsConfig).forEach(([key, config]) => {
    reducers[key] = config.reducer;
  });
  return reducers;
};

export default toolsConfig;
