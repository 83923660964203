import { cumulateMetrics, defaultMetrics } from '@/organisms/plans/utils';
import { EntityType } from '@/shared/enums';

export default function updateRoleType({
  action,
  role,
  roleMap,
  comparisonScenario,
}) {
  const roleType = roleMap.get(role.title) ?? {
    [EntityType.ROLE]: new Map(),
    metrics: defaultMetrics({ comparisonScenario }),
    props: {
      title: role.title,
    },
  };

  roleType.metrics[1] = { ...cumulateMetrics(action, roleType.metrics[1]) };

  return roleType;
}
