import { cumulateMetrics, defaultMetrics } from '@/organisms/plans/utils';
import { EntityType } from '@/shared/enums';

export default function updateActivityType({
  action,
  activity,
  activityMap,
  comparisonScenario,
}) {
  const activityType = activityMap.get(activity.__description) ?? {
    [EntityType.ACTIVITY]: new Map(),
    metrics: defaultMetrics({ comparisonScenario }),
    props: {
      title: activity.__description,
    },
  };

  activityType.metrics[1] = {
    ...cumulateMetrics(action, activityType.metrics[1]),
  };

  return activityType;
}
