export const PersonFragment = /* GraphQL */ `
  fragment PersonFragment on User {
    uuid
    first_name
    last_name
    email
    avatar
  }
`;

export const CollaboratorFragment = /* GraphQL */ `
  fragment CollaboratorFragment on User {
    uuid
    first_name
    last_name
    email
    avatar
    access {
      roles {
        design
      }
    }
  }
`;

export const RevisionFragment = /* GraphQL */ `
  fragment RevisionFragment on DesignRevision {
    uuid
    name
    snapshotString
    created_at
    design {
      uuid
    }
  }
`;

export const DesignFragment = /* GraphQL */ `
  fragment DesignFragment on Design {
    uuid
    name
    goal
    notes
    scope
    type
    is_scenario
    created_at
    updated_at
    collaborators {
      ...CollaboratorFragment
    }
    latest {
      ...RevisionFragment
    }
    owner {
      ... on Organisation {
        uuid
      }
      ... on User {
        ...PersonFragment
      }
    }
  }

  ${CollaboratorFragment}
  ${PersonFragment}
  ${RevisionFragment}
`;
