import { useEffect } from 'react';
import * as Auth from '@pkg/auth';
import useDisplaySettings from '@pkg/auth/useDisplaySettings';
import { Designs, Library, Revisions } from '@pkg/entities';
import { ClientError } from '@/lib/enums';
import { DesignType } from '@/lib/enums';
import { DesignLayout } from '@/components/Design/enums';
import useDesignStore from './useDesignStore';

/**
 * @param {String} designId
 * @returns {Object}
 */
export default function useDesignData(designId, level, revisionId) {
  const isAuthenticated = Auth.useStore((state) => state.isAuthenticated);
  const isLoadingLibrary = Library.useLibrary((state) => state.isLoading);
  useDisplaySettings();

  const set = useDesignStore((state) => state.set);
  const storedLayout = useDesignStore((state) => state.layout);
  const storedDesign = useDesignStore((state) => state.design);
  const storedSnapshot = useDesignStore((state) => state.snapshot);

  const {
    design,
    error: designError,
    isError: isDesignError,
    isLoading: isLoadingDesign,
  } = Designs.useDesign(designId);

  const latestId = design?.latest?.uuid;
  const fetchId = revisionId === 'latest' ? latestId : revisionId;
  const {
    revision,
    error: revisionError,
    isError: isRevisionError,
    isLoading: isLoadingRevision,
  } = Revisions.useRevision(fetchId, isDesignError);

  const error = designError ?? revisionError ?? null;
  let layout = storedLayout ?? DesignLayout.DIAGRAM;

  if (localStorage.getItem('_ROLE_LIST_LAYOUT_')) {
    localStorage.removeItem('_ROLE_LIST_LAYOUT_');
    layout = DesignLayout.LIST;
  }

  const isError = isDesignError || isRevisionError;
  const isHistorical = revisionId !== 'latest';
  const isLoading = isLoadingDesign || isLoadingRevision || isLoadingLibrary;
  const isScenario = design?.type !== DesignType.LIVE;

  useEffect(() => {
    const isAuthError =
      !isAuthenticated && error === ClientError.NOT_AUTHORIZED;

    set({
      error,
      isAuthError,
      isError,
      isHistorical,
      isScenario,
      layout,
      level,
    });
  }, [
    error,
    isAuthenticated,
    isError,
    isHistorical,
    isScenario,
    layout,
    level,
  ]);

  useEffect(() => {
    set({ isLoading });
  }, [isLoading]);

  useEffect(() => {
    set({ design });
  }, [design]);

  useEffect(() => {
    set({ key: designId });
  }, [designId]);

  useEffect(() => {
    set({ revision });
  }, [revision]);

  useEffect(() => {
    const hasDesign = Boolean(storedDesign);
    const hasSnapshot = Boolean(storedSnapshot);
    const isSameDesign = storedDesign?.uuid === designId;
    if (hasDesign && hasSnapshot && !isSameDesign) {
      set({ snapshot: null });
    }
  }, [designId, storedDesign, storedSnapshot]);
}
