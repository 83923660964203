import { useMemo } from 'react';
import { useQuery } from '@pkg/graphql';

const GET_LIBRARY_ROLES = /* GraphQL */ `
  query GetLibraryRoles {
    me {
      organisation {
        roles {
          uuid
          enabled
          snapshot {
            entities {
              roles {
                uuid
                title
                fte
                tags
              }
              activities {
                uuid
                library_uuid
                owner_uuid
                owner_type
                order
                hours
                tags
                updated_at
                created_at
              }
            }
          }
        }
      }
    }
  }
`;

const QUERY = Object.freeze([GET_LIBRARY_ROLES]);

export default function useLibraryRolesQuery() {
  const { data, dataUpdatedAt } = useQuery({ query: QUERY });
  return useMemo(() => data?.me.organisation.roles ?? [], [dataUpdatedAt]);
}
