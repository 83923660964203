import PropTypes from 'prop-types';
import { forwardRef, memo, useEffect, useState } from 'react';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { styled } from '@mui/material/styles';
import { color } from '@/lib/theme/tokens';
import { grey } from '@/lib/theme/tokens/palettes';

const Root = styled(FormControl)(({ fullWidth }) => ({
  width: fullWidth ? '100%' : 'auto',
}));

const Input = styled(TextareaAutosize)(({ readOnly }) => ({
  backgroundColor: color.core.white,
  border: `2px solid ${color.primary.grey[200]}`,
  borderRadius: 4,
  cursor: readOnly ? 'default' : 'inherit',
  color: color.primary.grey[500],
  fontFamily: 'Poppins, sans-serif',
  fontSize: 14,
  fontWeight: 500,
  lineHeight: '20px',
  outline: 0,
  padding: '6px 12px',
  resize: 'none',
  width: '100%',
  '&::placeholder': {
    color: grey.colors[300],
  },
  '&:hover': {
    borderColor: color.primary.grey[300],
  },
  '&:focus': {
    borderColor: color.primary.brand[800],
  },
}));

const Component = (
  {
    fullWidth = false,
    label,
    name,
    onBlur,
    onChange,
    resetsOnEmpty = false,
    selectOnFocus = false,
    readOnly,
    inputProps = {},
    ...props
  },
  ref
) => {
  const [value, setValue] = useState(props.value);

  const handleBlur = (event) => {
    if (resetsOnEmpty && event.target.value === '') {
      setValue(props.value);
      onBlur?.(event, props.value);
    } else {
      onBlur?.(event, event.target.value);
    }
  };

  const handleChange = (event) => {
    const changed = event.target.value.replace(/\n/, '');
    setValue(changed);
    onChange?.(event, changed);
  };

  const handleFocus = (event) => {
    if (selectOnFocus) {
      event.target.select();
    }
  };

  const handleKeyDown = (event) => {
    switch (event.key) {
      case 'Enter':
        event.target.blur();
        break;

      case 'Escape':
        event.target.value = props.value;
        event.target.blur();
        break;
    }
  };

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  return (
    <Root fullWidth={fullWidth}>
      <FormLabel htmlFor={`input-${name}`}>{label}</FormLabel>
      <Input
        minRows={2}
        maxRows={8}
        {...props}
        {...inputProps}
        id={`input-${name}`}
        name={name}
        onBlur={handleBlur}
        onChange={handleChange}
        onFocus={handleFocus}
        onKeyUp={handleKeyDown}
        readOnly={readOnly}
        ref={ref}
        value={value}
      />
    </Root>
  );
};

const TextareaInput = forwardRef(Component);

TextareaInput.propTypes = {
  fullWidth: PropTypes.bool,
  inputProps: PropTypes.object,
  label: PropTypes.string,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  resetsOnEmpty: PropTypes.bool,
  selectOnFocus: PropTypes.bool,
};

export default memo(TextareaInput);
