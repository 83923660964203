import env from '@beam-australia/react-env';
import { FronteggProviderNoSSR } from '@frontegg/nextjs/no-ssr';

const CONTEXT_OPTIONS = {
  baseUrl: env('FRONTEGG_BASE_URL'),
  clientId: env('FRONTEGG_CLIENT_ID'),
};

const localizations = {
  en: {
    loginBox: {
      login: {
        signUpMessage: '',
        signUpLink: '',
      },
    },
  },
};

const Provider = ({ children }) => {
  return (
    <FronteggProviderNoSSR
      customLoader={true}
      contextOptions={CONTEXT_OPTIONS}
      hostedLoginBox={false}
      authOptions={{
        disableSilentRefresh: false,
        keepSessionAlive: true,
      }}
      localizations={localizations}
    >
      {children}
    </FronteggProviderNoSSR>
  );
};

export default Provider;
