import { produce } from 'immer';
import { useCallback, useMemo } from 'react';
import { useLocalState } from '@pkg/hooks';
import { Collections } from '@pkg/utils';
import { get } from '@pkg/utils/objects';
import * as Reducers from './reducers';
import useMerged from './useMerged';

/**
 * @param {Object} roles
 * @param {Function} onMutate
 * @returns {Array}
 */
export default function useRoles(roles, onMutate = () => {}) {
  const keyed = useMemo(() => Collections.keyById(roles), [roles]);
  const [state, setState] = useLocalState(keyed);
  const merged = useMerged(state);

  const handler = async (action, input) => {
    const role = get(Reducers, action)(merged, input);
    if (role) {
      switch (action) {
        case 'roles.create':
        case 'roles.duplicate':
          setState(
            produce(state, (draft) => {
              draft[role.uuid] = role;
            })
          );
          await onMutate('create', role);
          break;
        case 'roles.remove':
          setState(
            produce(state, (draft) => {
              delete draft[role.uuid];
            })
          );
          await onMutate('remove', role);
          break;
        default:
          setState(
            produce(state, (draft) => {
              draft[role.uuid] = {
                ...draft[role.uuid],
                ...role,
              };
            })
          );
          await onMutate('update', role);
          break;
      }
    }
  };

  const dispatch = useCallback(handler, [merged, onMutate]);
  return [merged, dispatch];
}
