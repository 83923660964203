import { Size } from '@/atoms/enums';
import { Heading } from '@/atoms/typography';
import { Paragraph } from '@/atoms/typography';
import { PlanSummaryChange } from '@/organisms/plans';
import { usePlanContext } from '@/shared/providers';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import ActionSummaryOverview from './ActionSummaryOverview';
import EntitySummaryOverview from './EntitySummaryOverview';
import MovedEntity from './MovedEntity';

const PlanSummary = () => {
  const {
    comparisonMetrics,
    scenarioMetrics,
    selectedSummaryItem,
    metricList,
  } = usePlanContext();
  const showMetricChanges = comparisonMetrics && scenarioMetrics;
  const metrics = [comparisonMetrics, scenarioMetrics];

  return (
    <Box
      sx={{
        position: 'reative',
      }}
    >
      {showMetricChanges ? (
        <Grid container spacing={2} p={2}>
          {selectedSummaryItem?.actionType && (
            <Grid item xs={12}>
              <ActionSummaryOverview action={selectedSummaryItem} />
            </Grid>
          )}
          {selectedSummaryItem && !selectedSummaryItem.actionType && (
            <Grid item xs={12}>
              <EntitySummaryOverview entity={selectedSummaryItem} />
            </Grid>
          )}
          <Grid item xs={12}>
            <Heading
              variant="h6"
              overrideStyles={{ fontWeight: 600, textTransform: 'uppercase' }}
            >
              Scenario changes
            </Heading>
          </Grid>
          {selectedSummaryItem?.props?.moved && (
            <Grid item xs={12}>
              <MovedEntity moved={selectedSummaryItem.props.moved} />
            </Grid>
          )}
          {metricList?.map((metric) => (
            <Grid item xs={12} key={metric}>
              <PlanSummaryChange metric={metric} metrics={metrics} />
            </Grid>
          ))}
        </Grid>
      ) : (
        <Stack
          alignItems="center"
          justifyContent="center"
          height="calc(100vh - 200px)"
        >
          <Paragraph size={Size.MEDIUM}>
            No scenario changes have been made.
          </Paragraph>
        </Stack>
      )}
    </Box>
  );
};

export default PlanSummary;
