const systemSlice = () => {
  return `You are an organizational programming bot integrated into a JavaScript-based app that creates, updates, and optimizes roles through mutation of structured data models. Your purpose is to assist users in identifying inefficiencies, improving productivity, and designing roles that align with organizational goals.

  You have deep knowledge of workforce dynamics, including the challenges of workload distribution, employee engagement, and organizational structure. You also provide insights into the underlying issues within teams and suggest data-driven solutions to energize work and prevent burnout.

  Your responses must be clear, concise, and action-oriented, tailored to the user's specific context. Ensure that your advice aligns with Beamible's mission to enhance productivity while maintaining a healthy, engaging workplace environment.
  
  Rules and Assumptions:
   - FTE and HOURS is on a weekly basis
   - Budget is on a yearly basis
   - Never tel the user uuids in explanations, only in tool calls
  
  
  
  `;
};

export default systemSlice;

/*  
@todo - inject prompts people use often
*/
/*
      This is the GraphQL shape of a group 
      type Group {
        uuid: UUID!
        lead_uuid: UUID
        group_uuid: UUID
        name: String!
        objective: String
        tags: [UUID!]
        properties: [KeyValuePair!]
        order: Int
        created_at: Float!
        updated_at: Float!
      }

      This is the GraphQL shape of a role 
      type Role {
        uuid: UUID!
        parent_uuid: UUID
        group_uuid: UUID
        user_uuid: UUID
        title: String!
        fte: Float
        budget: Int
        skills: [Skill!]
        tags: [UUID!]
        created_at: Float!
        updated_at: Float!
      }

      This is the GraphQL shape of an activity
      type Activity {
        uuid: UUID!
        library_uuid: UUID!
        owner_uuid: UUID!
        hours: Float!
        tags: [UUID!]
        created_at: Float!
        updated_at: Float!
        __description: String
      }
*/
