import { EntityChip } from '@/atoms/chips';
import { Size } from '@/atoms/enums';
import { Heading } from '@/atoms/typography';
import { ActivityTitle } from '@/molecules/activities';
import { GroupTitle } from '@/molecules/groups';
import { RoleTitle } from '@/molecules/roles';
import { ActionType, EntityType, Status } from '@/shared/enums';
import Box from '@mui/material/Box';

const actionStatus = (actions, entityType) => {
  if (!actions) {
    return;
  }

  let isNew = false;
  let isRemoved = false;

  switch (entityType) {
    case EntityType.GROUP:
      isNew =
        actions.has(ActionType.ADDED_GROUP) ||
        actions.has(ActionType.ADD_GROUPS);
      isRemoved =
        actions.has(ActionType.REMOVED_GROUP) ||
        actions.has(ActionType.REMOVE_GROUPS);
      break;
    case EntityType.ROLE:
      isNew =
        actions.has(ActionType.ADDED_ROLE) || actions.has(ActionType.ADD_ROLES);
      isRemoved =
        actions.has(ActionType.REMOVED_ROLE) ||
        actions.has(ActionType.REMOVE_ROLES);
      break;
    case EntityType.ACTIVITY:
      isNew =
        actions.has(ActionType.ADDED_ACTIVITY) ||
        actions.has(ActionType.ADD_ACTIVITIES);
      isRemoved =
        actions.has(ActionType.REMOVED_ACTIVITY) ||
        actions.has(ActionType.REMOVE_ACTIVITIES);
      break;
    default:
  }

  if (isRemoved && isNew) {
    return;
  }

  if (isNew) {
    return Status.NEW;
  }
  if (isRemoved) {
    return Status.REMOVED;
  }
};

const EntitySummaryOverview = ({ entity }) => {
  const role = entity?.props?.role;
  const roleStatus = actionStatus(entity?.actions, EntityType.ROLE);
  const activity = entity?.props?.activity;
  const activityStatus = actionStatus(entity?.actions, EntityType.ACTIVITY);
  const group = entity?.props?.group;
  const groupStatus = actionStatus(entity?.actions, EntityType.GROUP);

  return (
    <>
      {group && (
        <Box pb={2}>
          <GroupTitle group={group} status={groupStatus} />
        </Box>
      )}
      {role && (
        <Box pb={2}>
          <RoleTitle role={role} status={roleStatus} />
        </Box>
      )}
      {activity && (
        <Box mb={role ? 1.5 : 0}>
          <ActivityTitle activity={activity} status={activityStatus} />
        </Box>
      )}
      {!role && !activity && !group && (
        <>
          <EntityChip
            size={Size.XX_SMALL}
            sx={{ height: 20, px: 1 }}
            type={entity.entityType}
          />
          <Heading variant="h4" overrideStyles={{ mt: 1 }}>
            {entity.props.title}
          </Heading>
        </>
      )}
    </>
  );
};

export default EntitySummaryOverview;
