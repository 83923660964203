import { useEffect, useRef, memo } from 'react';
import ReactMarkdown from 'react-markdown';
import { PlanActionCard } from '@/organisms/cards';
import { mapAggregateActions } from '@/organisms/plans';
import Avatar from '@mui/material/Avatar';
import CircularProgress from '@mui/material/CircularProgress';
import * as Auth from '@pkg/auth';
import { Library } from '@pkg/entities';
import deriveSnapshot from '@pkg/entities/snapshots/derive/snapshot';
import localMutate from '@pkg/entities/snapshots/mutate';
import activityUpdate from '@pkg/entities/snapshots/reducers/activities/update';
import roleAdd from '@pkg/entities/snapshots/reducers/roles/add';
import useDesignStore from '@/components/DesignContainer/hooks/useDesignStore';
import Action from './Action';

const toolReducerMap = {
  role_add: roleAdd,
  activity_update: activityUpdate,
};

const Actor = memo(function Actor({ children, type }) {
  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
      <Avatar
        sx={{
          width: 32,
          height: 32,
          bgcolor: type === 'User' ? '#1976d2' : '#2e7d32',
        }}
      >
        {type === 'User' ? 'U' : 'B'}
      </Avatar>
      <span style={{ fontWeight: 'bold' }}>{children}</span>
    </div>
  );
});

const Message = memo(function Message({ msg }) {
  const snapshot = useDesignStore((state) => state.snapshot);
  const library = Library.useLibrary();
  const messageRef = useRef(null);
  const { me, organisation } = Auth.useStore();

  // if msg.actions exist, we want to choose the first one check what type it is, if it is applyMutation, then we want to render another component

  let component = null;

  if (msg.actions) {
    const action = msg.actions[0];

    if (action?.type === 'applyMutation') {
      const showBudget = true;
      let actions = null;
      let derivedUpdatedSnapshot = null;

      if (snapshot) {
        // calculate the bulk mutation locally
        const mutations = [];
        const tools = action.parameters.toolResults;

        console.log({ action });
        tools.forEach((tool) => {
          const toolName = tool.toolName;
          const total = tool.result.total;
          const payload = tool.result.payload;
          const reducer = toolReducerMap[toolName];
          console.log('woot3', { snapshot, toolName, payload, total, reducer });
          try {
            const timesToApply = total || 1;
            for (let i = 0; i < timesToApply; i++) {
              const mutation = reducer(snapshot, payload);
              console.log({ mutation });
              if (mutation) {
                mutations.push(mutation);
              }
            }
          } catch (error) {
            console.log('woot4', error);
          }
        });

        let updatedSnapshot = structuredClone(snapshot);
        console.log({ mutations });

        mutations.forEach((mutation) => {
          updatedSnapshot = localMutate(updatedSnapshot, mutation);
        });

        derivedUpdatedSnapshot = deriveSnapshot(library, updatedSnapshot);

        // add snapshot entities to scenario maps
        const comparisonScenario = {
          relationships: new Map([
            [
              'ROLE',
              new Map(
                snapshot.entities.roles.map((role) => [role.uuid, role]) || []
              ),
            ],
            [
              'ACTIVITY',
              new Map(
                snapshot.entities.activities.map((activity) => [
                  activity.uuid,
                  activity,
                ]) || []
              ),
            ],
            [
              'GROUP',
              new Map(
                snapshot.entities.groups?.map((group) => [group.uuid, group]) ||
                  []
              ),
            ],
            [
              'PERSON',
              new Map(
                snapshot.entities.people?.map((person) => [
                  person.uuid,
                  person,
                ]) || []
              ),
            ],
            [
              'MANAGER',
              new Map(
                snapshot.entities.managers?.map((manager) => [
                  manager.uuid,
                  manager,
                ]) || []
              ),
            ],
          ]),
        };

        console.log({ snapshot });

        const scenario = {
          relationships: new Map([
            [
              'ROLE',
              new Map(
                derivedUpdatedSnapshot.entities.roles.map((role) => [
                  role.uuid,
                  role,
                ]) || []
              ),
            ],
            [
              'ACTIVITY',
              new Map(
                derivedUpdatedSnapshot.entities.activities?.map((activity) => [
                  activity.uuid,
                  activity,
                ]) || []
              ),
            ],
            [
              'GROUP',
              new Map(
                derivedUpdatedSnapshot.entities.groups?.map((group) => [
                  group.uuid,
                  group,
                ]) || []
              ),
            ],
            [
              'PERSON',
              new Map(
                derivedUpdatedSnapshot.entities.people?.map((person) => [
                  person.uuid,
                  person,
                ]) || []
              ),
            ],
            [
              'MANAGER',
              new Map(
                derivedUpdatedSnapshot.entities.managers?.map((manager) => [
                  manager.uuid,
                  manager,
                ]) || []
              ),
            ],
          ]),
        };

        actions = mapAggregateActions({
          comparisonScenario,
          scenario,
          showBudget,
        });

        console.log('woot', { actions, comparisonScenario, scenario });

        console.log({ actions });
      }

      component = (
        <div
          style={{
            transform: 'scale(0.8)',
          }}
        >
          {actions &&
            Array.from(actions).map(([_, planAction]) => {
              console.log('woot2', { planAction });
              return (
                <div key={planAction.type}>
                  <PlanActionCard
                    title={planAction.title}
                    entityType={planAction.entity}
                    actionType={planAction.type}
                    metrics={planAction.metrics}
                  />
                  <br />
                </div>
              );
            })}
        </div>
      );
    }
  }

  console.log({ msg });
  return (
    <div className={`message ${msg.sender}`} style={{ marginBottom: '0.5rem' }}>
      <div
        style={{
          display: 'flex',
          gap: '8px',
          alignItems: 'flex-start',
          marginBottom: '4px',
        }}
      >
        <Actor type={msg.sender}>
          {msg.sender === 'User' ? 'You: ' : 'Beamible: '}
        </Actor>
      </div>
      <div
        ref={messageRef}
        style={{
          backgroundColor: '#ffffff',
          padding: '0 12px',
          borderRadius: '4px',
          marginLeft: '40px',
          '& pre': {
            background: '#f6f8fa',
            padding: '0.75rem',
            borderRadius: '4px',
          },
          '& code': {
            background: '#f6f8fa',
            padding: '0.2rem 0.4rem',
            borderRadius: '3px',
          },
          '& p': { margin: '0.25rem 0' },
        }}
      >
        <ReactMarkdown>{msg.message}</ReactMarkdown>
        {msg.actions &&
          msg.actions.length > 0 &&
          msg.actions[0].type === 'applyMutation' &&
          msg.actions[0].parameters.toolResults && (
            <ul
              style={{
                marginTop: '8px',
                marginBottom: '8px',
                fontSize: '0.9em',
                opacity: 0.8,
              }}
            >
              {msg.actions[0].parameters.toolResults.map(
                (toolResult, index) => (
                  <li key={index}>
                    <ReactMarkdown>
                      {toolResult.result.explanation}
                    </ReactMarkdown>
                  </li>
                )
              )}
            </ul>
          )}
      </div>

      {component}

      {msg.actions && msg.actions.length > 0 && (
        <div
          style={{
            marginLeft: '46px',
            marginTop: '4px',
            marginBottom: '30px',
          }}
        >
          {msg.actions.map((action, index) => (
            <span key={index}>
              <Action action={action} />
            </span>
          ))}
        </div>
      )}
    </div>
  );
});

const ChatMessages = memo(function ChatMessages({ messages, thinking }) {
  const messagesEndRef = useRef(null);
  const lastMessageRef = useRef(null);
  const lastMessageLengthRef = useRef(0);
  const lastMessageCountRef = useRef(messages.length);

  useEffect(() => {
    const shouldScrollToBottom =
      messages.length !== lastMessageCountRef.current || // New message added
      (messages.length > 0 &&
        messages[messages.length - 1].message?.length !==
          lastMessageLengthRef.current); // Last message content changed

    if (shouldScrollToBottom) {
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
      if (messages.length > 0) {
        lastMessageLengthRef.current =
          messages[messages.length - 1].message?.length || 0;
      }
      lastMessageCountRef.current = messages.length;
    }
  }, [messages]);

  return (
    <div
      className="chat-messages"
      style={{
        height: '100%',
        width: '100%',
        paddingBottom: '40px',
        marginBottom: '40px',
      }}
    >
      {messages.map((msg, index) => (
        <Message
          key={index}
          msg={msg}
          ref={index === messages.length - 1 ? lastMessageRef : null}
        />
      ))}
      {thinking && (
        <div className="message bot" style={{ marginBottom: '0.5rem' }}>
          <div
            style={{ display: 'flex', gap: '8px', alignItems: 'flex-start' }}
          >
            <Actor type="bot">Beamible: </Actor>
          </div>
          <div
            style={{
              marginLeft: '40px',
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
            }}
          >
            <CircularProgress size={20} />
            <span>Thinking...</span>
          </div>
        </div>
      )}
      <div ref={messagesEndRef} />
    </div>
  );
});

ChatMessages.displayName = 'ChatMessages';
Message.displayName = 'Message';
Actor.displayName = 'Actor';

export default ChatMessages;
