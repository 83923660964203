import { Avatar } from '@/atoms/avatars';
import { EntityChip, StatusChip } from '@/atoms/chips';
import { Size } from '@/atoms/enums';
import { Heading } from '@/atoms/typography';
import { EntityType } from '@/shared/enums';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

const RoleTitle = ({ role, status }) => {
  const personName = role?.__person ? role.__person.__name : 'Unassigned';

  return (
    <Stack alignItems="center" direction="row" spacing={1}>
      <Avatar
        src={role.__person?.avatar}
        name={personName}
        size={Size.MEDIUM}
      />
      <Box>
        <Stack alignItems="center" direction="row" spacing={0.5} mt={0.25}>
          <EntityChip
            size={Size.XX_SMALL}
            sx={{ height: 18, px: 1 }}
            type={role.is_manager ? EntityType.MANAGER : EntityType.ROLE}
          />
          <Heading variant="h4">
            {role.title}
            {status && (
              <Box
                sx={{
                  display: 'inline',
                  ml: 0.75,
                  verticalAlign: 'text-bottom',
                }}
              >
                <StatusChip status={status} size={Size.XX_SMALL} />
              </Box>
            )}
          </Heading>
        </Stack>
        <Heading variant="h6">{personName}</Heading>
      </Box>
    </Stack>
  );
};

export default RoleTitle;
