import { Plan } from '@/organisms/plans';
import { StoreType } from '@/shared/enums';
import {
  ActiveDesignProvider,
  PlanProvider,
  ScenarioProvider,
  TagProvider,
  useCanSwitchViewModes,
} from '@/shared/providers';
import { useAccess } from '@pkg/access/organisations';
import * as Auth from '@pkg/auth';

const PlanLayout = ({ designId, level, revisionId }) => {
  const access = useAccess();
  const organisation = Auth.useStore((state) => state.organisation);
  const isRestricted = !organisation.is_restricted && !access.model.manage;
  const canSwitchViewModes = useCanSwitchViewModes();

  return (
    <ActiveDesignProvider
      designId={designId}
      level={level}
      revisionId={revisionId}
      isRestricted={!canSwitchViewModes}
    >
      <ScenarioProvider
        designId={designId}
        level={level}
        storeType={StoreType.DESIGN}
      >
        <TagProvider>
          <PlanProvider>
            <Plan />
          </PlanProvider>
        </TagProvider>
      </ScenarioProvider>
    </ActiveDesignProvider>
  );
};

export default PlanLayout;
