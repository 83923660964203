const Status = Object.freeze({
  NEW: 'NEW',
  CANCELED: 'CANCELED',
  COMPLETE: 'COMPLETE',
  CLOSED: 'CLOSED',
  EXPIRED: 'EXPIRED',
  IN_PROGRESS: 'IN PROGRESS',
  INCOMPLETE: 'INCOMPLETE',
  OPEN: 'OPEN',
  REMOVED: 'REMOVED',
});

export default Status;
