import { Status } from '@/shared/enums';
import { brand } from '@/lib/theme/tokens/palettes';

const StatusColor = Object.freeze({
  [Status.OPEN]: {
    color: brand.green.main,
    borderColor: brand.green.main,
  },
  [Status.NEW]: {
    color: brand.green.main,
    borderColor: brand.green.main,
  },
  [Status.CLOSED]: {
    color: brand.orange.tints[6],
    borderColor: brand.orange.tints[4],
  },
  [Status.INCOMPLETE]: {
    borderColor: brand.coral.tints[6],
    color: brand.coral.main,
  },
  [Status.CANCELED]: {
    borderColor: brand.coral.tints[6],
    color: brand.coral.main,
  },
  [Status.REMOVED]: {
    borderColor: brand.coral.tints[6],
    color: brand.coral.main,
  },
  [Status.IN_PROGRESS]: {
    color: brand.orange.tints[6],
    borderColor: brand.orange.tints[4],
  },
  [Status.EXPIRED]: {
    borderColor: brand.grey.tints[5],
    color: brand.grey.tints[7],
  },
});

export default StatusColor;
