import { useRouter } from 'next/router';
import { useEffect } from 'react';
import * as Auth from '@pkg/auth';
import Sdk from '@pkg/sdk';
import { Routes } from '@pkg/utils';
import { DesignLevel } from '@/lib/enums';
import useAccess from './useAccess';
import useActions from './useActions';
import useDesignData from './useDesignData';
import useDesignStore from './useDesignStore';
import useSnapshot from './useSnapshot';

export default function useDesignSetup(designId, level, revisionId) {
  const logout = Auth.useLogout();
  const router = useRouter();

  const isAuthError = useDesignStore((state) => state.isAuthError);
  const reset = useDesignStore((state) => state.reset);
  const set = useDesignStore((state) => state.set);

  useDesignData(designId, level, revisionId);
  useAccess();
  useActions();
  useSnapshot();

  // define home context
  useEffect(() => {
    const initial = {
      ...level,
      type: level.type === DesignLevel.MANAGER ? DesignLevel.ROLE : level.type,
    };
    set({ initial });
  }, [JSON.stringify(level)]);

  // harder reset state if route changing
  useEffect(() => {
    const handleReset = (next) => {
      const nextParts = Routes.getDesignParts(next);
      if (!nextParts) {
        return reset();
      }

      const current = router.asPath;
      const currentParts = Routes.getDesignParts(current);

      const isSameDesignId = currentParts?.designId === nextParts.designId;
      const isSameRevisionId =
        currentParts?.revisionId === nextParts.revisionId;
      const isSameLevelId = currentParts?.level?.type === nextParts.level?.type;
      const isSameLevelType =
        currentParts?.level?.uuid === nextParts.level?.uuid;

      const isSame =
        isSameDesignId && isSameRevisionId && isSameLevelId && isSameLevelType;
      const isVersioned =
        isSameDesignId && !isSameRevisionId && isSameLevelId && isSameLevelType;

      if (isVersioned) {
        return;
      }

      if (!isSame) {
        return reset();
      }
    };

    router.events.on('routeChangeStart', handleReset);
    return () => router.events.off('routeChangeStart', handleReset);
  }, [router]);

  // force logout if not authorized to see this
  useEffect(() => {
    if (isAuthError) {
      localStorage.setItem('_REDIRECT_AFTER_LOGIN_', Sdk.fullPath());
      logout();
    }
  }, [isAuthError]);
}
