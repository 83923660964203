import { useEffect, useState } from 'react';
import { EntitySummary, PersonRoleSummary } from '@/molecules/summaries';
import { useScenarioContext } from '@/shared/providers/ScenarioProvider';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { Routes } from '@pkg/utils';
import { DesignEntity } from '@/lib/enums';
import { color } from '@/lib/theme/tokens';
import Box from '@/components/Box';
import { LinkButton } from '@/components/DashboardContainer/atoms/buttons';

const buttonColor = Object.freeze({
  font: color.core.white,
  border: 'transparent',
  background: 'inherit',
  highlight: {
    font: color.core.white,
    border: 'transparent',
    background: '#27527b',
  },
});

const TeamMemberList = () => {
  const { scenario } = useScenarioContext();
  const [teamPath, setTeamPath] = useState('');

  const group =
    scenario?.entity?.__type === DesignEntity.GROUP
      ? scenario?.entity
      : scenario?.relationships
          .get(DesignEntity.GROUP)
          ?.get(scenario?.entity?.group_uuid);

  const roles = scenario?.relationships?.get(DesignEntity.ROLE);

  useEffect(() => {
    if (!scenario?.entity || !group) {
      return;
    }

    setTeamPath(
      Routes.build.designParts({
        designId: scenario.details?.designId,
        level: {
          type: DesignEntity.GROUP,
          uuid: group.uuid,
        },
      })
    );
  }, [scenario?.entity?.uuid, scenario?.entity?.__uuid]);

  return (
    Boolean(scenario?.relationships) && (
      <Stack justifyContent="flex-start" alignItems="flex-start" sx={{ my: 1 }}>
        {Boolean(group) && (
          <Box
            sx={{
              width: '100%',
            }}
          >
            <Stack
              direction="row"
              width="100%"
              justifyContent="space-between"
              sx={{ color: '#FFF' }}
            >
              <EntitySummary entity={group} />
              <LinkButton
                path={teamPath}
                title="VIEW TEAM"
                colors={buttonColor}
                endIcon={<NavigateNextIcon sx={{ color: 'rgb(13, 46, 77)' }} />}
              />
            </Stack>
            <Divider
              sx={{
                mb: 3,
                mt: 2,
                backgroundColor: '#fff',
                borderColor: '#fff',
              }}
            />
          </Box>
        )}
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={1}
        >
          {Array.from(roles.values()).map((role) => {
            const isSameGroup = role.group_uuid === (group?.uuid ?? null);
            return (
              isSameGroup && (
                <Grid item xs={4} key={role.uuid}>
                  <PersonRoleSummary entity={role} inverse={true} />
                </Grid>
              )
            );
          })}
        </Grid>
      </Stack>
    )
  );
};

export default TeamMemberList;
