import ManageComparison from '@/organisms/comparison/ManageComparison';
import { EntityType } from '@/shared/enums';
import { useScenarioContext, useViewModeContext } from '@/shared/providers';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import InsightsTitle from '../InsightsTitle/InsightsTitle';
import ActivitiesChart from './ActivitiesChart';

const ActivityInsights = () => {
  const { scenario, snapshotEntityMap, comparisonScenario } =
    useScenarioContext();
  const entityType = scenario?.entity?.is_manager
    ? EntityType.MANAGER
    : scenario?.entity?.__type;
  const { id, scope, scopeEntity } = useViewModeContext();
  const showSubgroupsSwitch =
    entityType &&
    entityType !== EntityType.ROLE &&
    scopeEntity !== EntityType.ROLE;

  const isActiveComparison = !!comparisonScenario;

  return (
    <Grid container spacing={2} pb={4}>
      <Grid item xs={12}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <InsightsTitle
            entityMap={snapshotEntityMap}
            id={id}
            scope={scope}
            scopeEntity={scopeEntity}
          />
          <ManageComparison />
        </Stack>
      </Grid>
      {scenario && (
        <Grid item xs={12}>
          <ActivitiesChart
            levelType={entityType}
            showComparison={isActiveComparison}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default ActivityInsights;
