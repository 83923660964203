import { Status as StatusColor } from '@/atoms/colors';
import { FlowStatus, Status } from '@/shared/enums';
import { CampaignStatus } from '@/lib/enums';
import { core } from '@/lib/theme/tokens/palettes';

const open = StatusColor[CampaignStatus.OPEN];
const closed = StatusColor[CampaignStatus.CLOSED];
const incomplete = StatusColor[CampaignStatus.INCOMPLETE];
const canceled = StatusColor[CampaignStatus.CANCELED];
const expired = StatusColor[CampaignStatus.EXPIRED];
const inProgress = StatusColor[FlowStatus.IN_PROGRESS];

const StatusChipColor = Object.freeze({
  [Status.COMPLETE]: {
    normal: {
      ...open,
      backgroundColor: 'transparent',
      boxShadow: `0 0 0 1px ${open.borderColor}`,
    },
    focus: {
      ...open,
      backgroundColor: 'transparent',
      boxShadow: `0 0 0 1px ${open.borderColor}`,
    },
    hover: {
      ...open,
      backgroundColor: 'transparent',
      boxShadow: `0 0 0 1px ${open.borderColor}`,
    },
  },
  [Status.NEW]: {
    normal: {
      backgroundColor: StatusColor[Status.NEW].color,
      color: core.white,
    },
    focus: {
      backgroundColor: StatusColor[Status.NEW].color,
      color: core.white,
    },
    hover: {
      backgroundColor: StatusColor[Status.NEW].color,
      color: core.white,
    },
  },
  [Status.OPEN]: {
    normal: {
      ...open,
      backgroundColor: 'transparent',
      boxShadow: `0 0 0 1px ${open.borderColor}`,
    },
    focus: {
      ...open,
      backgroundColor: 'transparent',
      boxShadow: `0 0 0 1px ${open.borderColor}`,
    },
    hover: {
      ...open,
      backgroundColor: 'transparent',
      boxShadow: `0 0 0 1px ${open.borderColor}`,
    },
  },
  [Status.CLOSED]: {
    normal: {
      ...closed,
      backgroundColor: 'transparent',
      boxShadow: `0 0 0 1px ${closed.borderColor}`,
    },
    focus: {
      ...closed,
      backgroundColor: 'transparent',
      boxShadow: `0 0 0 1px ${closed.borderColor}`,
    },
    hover: {
      ...closed,
      backgroundColor: 'transparent',
      boxShadow: `0 0 0 1px ${closed.borderColor}`,
    },
  },
  [Status.REMOVED]: {
    normal: {
      backgroundColor: StatusColor[Status.REMOVED].color,
      color: core.white,
    },
    focus: {
      backgroundColor: StatusColor[Status.REMOVED].color,
      color: core.white,
    },
    hover: {
      backgroundColor: StatusColor[Status.REMOVED].color,
      color: core.white,
    },
  },
  [Status.INCOMPLETE]: {
    normal: {
      ...incomplete,
      backgroundColor: 'transparent',
      boxShadow: `0 0 0 1px ${incomplete.borderColor}`,
    },
    focus: {
      ...incomplete,
      backgroundColor: 'transparent',
      boxShadow: `0 0 0 1px ${incomplete.borderColor}`,
    },
    hover: {
      ...incomplete,
      backgroundColor: 'transparent',
      boxShadow: `0 0 0 1px ${incomplete.borderColor}`,
    },
  },
  [Status.CANCELED]: {
    normal: {
      ...canceled,
      backgroundColor: 'transparent',
      boxShadow: `0 0 0 1px ${canceled.borderColor}`,
    },
    hover: {
      ...canceled,
      backgroundColor: 'transparent',
      boxShadow: `0 0 0 1px ${canceled.borderColor}`,
    },
    focus: {
      ...canceled,
      backgroundColor: 'transparent',
      boxShadow: `0 0 0 1px ${canceled.borderColor}`,
    },
  },
  [Status.IN_PROGRESS]: {
    normal: {
      ...inProgress,
      backgroundColor: 'transparent',
      boxShadow: `0 0 0 1px ${inProgress.borderColor}`,
    },
    focus: {
      ...inProgress,
      backgroundColor: 'transparent',
      boxShadow: `0 0 0 1px ${inProgress.borderColor}`,
    },
    hover: {
      ...inProgress,
      backgroundColor: 'transparent',
      boxShadow: `0 0 0 1px ${inProgress.borderColor}`,
    },
  },
  [Status.EXPIRED]: {
    normal: {
      ...expired,
      backgroundColor: 'transparent',
      boxShadow: `0 0 0 1px ${expired.borderColor}`,
    },
    hover: {
      ...expired,
      backgroundColor: 'transparent',
      boxShadow: `0 0 0 1px ${expired.borderColor}`,
    },
    focus: {
      ...expired,
      backgroundColor: 'transparent',
      boxShadow: `0 0 0 1px ${expired.borderColor}`,
    },
  },
});

export default StatusChipColor;
