import { ActionType, EntityType } from '@/shared/enums';
import addedActivitiesAction from './addedActivitiesAction';
import addedGroupsAction from './addedGroupsAction';
import addedRolesAction from './addedRolesAction';
import movedActivities from './movedActivities';
import movedRolesAction from './movedRolesAction';
import removedActivitiesAction from './removedActivitiesAction';
import removedGroupsAction from './removedGroupsAction';
import removedRolesAction from './removedRolesAction';
import updatedBudgetAction from './updatedBudgetAction';
import updatedFTEAction from './updatedFTEAction';
import updatedHoursAction from './updatedHoursAction';

const actionExists = ({ actionMap, actionType, entityType, id }) => {
  return actionMap.get(actionType)?.[entityType]?.get(id);
};

export default function addActionByActionType({
  activity,
  actionMap,
  comparisonScenario,
  group,
  originalActivity,
  originalGroup,
  originalRole,
  role,
  scenario,
  showBudget,
}) {
  const actions = [];
  const isNewRole = role && !originalRole;

  // New activity actions.
  const hasNewActivity =
    activity &&
    actionExists({
      actionMap,
      actionType: ActionType.ADD_ACTIVITIES,
      entityType: EntityType.ACTIVITY,
      id: activity.uuid,
    });
  if (activity && !hasNewActivity && !originalActivity) {
    actions.push(
      addedActivitiesAction({
        activity,
        actionMap,
        comparisonScenario,
        scenario,
      })
    );
  }

  // Activity hours change actions.
  const hasHoursChange =
    activity &&
    actionExists({
      actionMap,
      actionType: ActionType.UPDATE_HOURS,
      entityType: EntityType.ACTIVITY,
      id: activity.uuid,
    });
  if (
    !hasHoursChange &&
    originalActivity &&
    activity &&
    activity.hours !== originalActivity.hours
  ) {
    actions.push(
      updatedHoursAction({
        actionMap,
        activity,
        comparisonScenario,
        scenario,
      })
    );
  }

  // New role added.
  const hasNewRole =
    role &&
    actionExists({
      actionMap,
      actionType: ActionType.ADD_ROLES,
      entityType: EntityType.ROLE,
      id: role.uuid,
    });
  if (!hasNewRole && isNewRole) {
    actions.push(
      addedRolesAction({
        actionMap,
        comparisonScenario,
        role,
        scenario,
      })
    );
  }

  if (role && originalRole) {
    // FTE Changes.
    const hasFTEChange = actionExists({
      actionMap,
      actionType: ActionType.UPDATE_FTE,
      entityType: EntityType.ROLE,
      id: role.uuid,
    });
    if (
      !hasFTEChange &&
      role.__metrics.self.visible.fte !==
        originalRole.__metrics.self.visible.fte
    ) {
      actions.push(
        updatedFTEAction({
          actionMap,
          comparisonScenario,
          role,
        })
      );
    }

    // Budget Changes.
    const hasBudgetChange = actionExists({
      actionMap,
      actionType: ActionType.UPDATE_BUDGETS,
      entityType: EntityType.ROLE,
      id: role.uuid,
    });
    if (
      showBudget &&
      !hasBudgetChange &&
      role &&
      originalRole &&
      role.__metrics.self.visible.budget !==
        originalRole.__metrics.self.visible.budget
    ) {
      actions.push(
        updatedBudgetAction({
          actionMap,
          comparisonScenario,
          role,
          scenario,
        })
      );
    }
  }

  // Adding a new group.
  const isNewGroup =
    group &&
    !comparisonScenario?.relationships?.get(EntityType.GROUP)?.has(group.uuid);
  const hasGroupChange =
    isNewGroup &&
    actionExists({
      actionMap,
      actionType: ActionType.ADD_GROUPS,
      entityType: EntityType.GROUP,
      id: group.uuid,
    });
  if (!hasGroupChange && isNewGroup) {
    actions.push(
      addedGroupsAction({
        actionMap,
        comparisonScenario,
        group,
        scenario,
      })
    );
  }

  const hasRemovedActivity =
    originalActivity &&
    actionExists({
      actionMap,
      actionType: ActionType.REMOVE_ACTIVITIES,
      entityType: EntityType.ACTIVITY,
      id: originalActivity.uuid,
    });
  if (!hasRemovedActivity && !activity && originalActivity) {
    actions.push(
      removedActivitiesAction({
        actionMap,
        activity: originalActivity,
        comparisonScenario,
      })
    );
  }

  const hasRemovedRole =
    originalRole &&
    actionExists({
      actionMap,
      actionType: ActionType.REMOVE_ROLES,
      entityType: EntityType.ROLE,
      id: originalRole.uuid,
    });
  if (!hasRemovedRole && !role && originalRole) {
    actions.push(
      removedRolesAction({
        actionMap,
        comparisonScenario,
        group: originalGroup,
        role: originalRole,
      })
    );
  }

  const hasRemovedGroup =
    originalGroup &&
    actionExists({
      actionMap,
      actionType: ActionType.REMOVE_GROUPS,
      entityType: EntityType.GROUP,
      id: originalGroup.uuid,
    });
  if (!hasRemovedGroup && !group && originalGroup) {
    actions.push(
      removedGroupsAction({
        actionMap,
        comparisonScenario,
        group: originalGroup,
      })
    );
  }

  // Moving activities between roles and unallocated.
  const changedOwners =
    activity &&
    originalActivity &&
    activity.owner_uuid !== originalActivity.owner_uuid;
  const hasChangedOwners =
    changedOwners &&
    actionExists({
      actionMap,
      actionType: ActionType.MOVE_ACTIVITIES,
      entityType: EntityType.ACTIVITY,
      comparisonScenario,
      originalActivity,
      scenario,
    });
  if (!hasChangedOwners && changedOwners) {
    actions.push(
      movedActivities({
        actionMap,
        activity,
        comparisonScenario,
        originalActivity,
        scenario,
      })
    );
  }

  // Moving roles between groups and managers.
  const comparisonRole = comparisonScenario?.relationships
    ?.get(EntityType.ROLE)
    .get(role?.uuid);
  const changedManagers =
    role && comparisonRole && role?.__manager !== comparisonRole?.__manager;
  const changedGroups =
    role && comparisonRole && role.group_uuid !== comparisonRole.group_uuid;
  const hasMovedRole =
    (changedManagers || changedGroups) &&
    actionExists({
      actionMap,
      actionType: ActionType.MOVE_ROLES,
      entityType: EntityType.ROLE,
      id: role.uuid,
    });
  if (!hasMovedRole && (changedManagers || changedGroups)) {
    actions.push(
      movedRolesAction({
        actionMap,
        comparisonScenario,
        role,
        originalRole: comparisonRole,
        scenario,
      })
    );
  }

  return actions;
}
