const YAML = require('json-to-pretty-yaml');

const orgData = ({ snapshot, tags, intentSnapshot }) => {
  if (intentSnapshot) {
    console.log('zzzzz using intent', { intentSnapshot });
    return ` Here is the current organizational structure:

The following data outlines the current structure, roles, and activities within the organization. Use this data to analyze, answer questions, or generate insights tailored to the organization's context. When providing recommendations or insights, consider:
- The hierarchy and relationships between groups, roles, and activities.
- The metrics provided, ensuring data-driven responses.
- The organizational visibility constraints, ensuring contextually appropriate suggestions.

Rules:
- The activity.owner_uuid matches to a role.uuid

  Here is a list of tags the organisation uses;

  ${YAML.stringify(
    tags.tags.list.slice(0, 30).map((tag) => ({
      uuid: tag.uuid,
      name: tag.name,
      description: tag.description,
    }))
  )}

  The organisational structure: 

  ${YAML.stringify(intentSnapshot)}
`;
  }

  const { roles, groups, activities } = snapshot.entities;

  const scenarioData = {
    name: snapshot.name,
    objective: snapshot.objective,
    metrics: snapshot.__metrics,
    visibility: snapshot.__visibility,
  };

  const groupData = groups.map((group) => ({
    uuid: group.uuid,
    name: group.name,
    objective: group.objective,
    tags: group.tags,
    metrics: group.__metrics.self,
    visibility: snapshot.__visibility,
  }));

  const rolesData = roles
    .map((role) => {
      const group = groups.find((group) => group.uuid === role.group_uuid);
      return {
        title: role.title,
        uuid: role.uuid,
        group_uuid: role.group_uuid || '',
        group_name: group ? group.name : '',
        fte: role.fte,
        budget: role.budget,
        is_manager: role.is_manager,
        tags: role.tags,
        metrics: role.__metrics.self,
        visibility: snapshot.__visibility,
      };
    })
    .slice(0, 100);

  const activitiesData = activities
    .map((activity) => ({
      uuid: activity.uuid,
      owner_uuid: activity.owner_uuid,
      description: activity.__description,
      hours: activity.hours,
      tags: activity.tags || [],
      visibility: activity.__visibility,
    }))
    .slice(0, 200);

  const prompt = ` Here is the current organizational structure:

The following data outlines the current structure, roles, and activities within the organization. Use this data to analyze, answer questions, or generate insights tailored to the organization's context. When providing recommendations or insights, consider:
- The hierarchy and relationships between groups, roles, and activities.
- The metrics provided, ensuring data-driven responses.
- The organizational visibility constraints, ensuring contextually appropriate suggestions.

Rules:
- The activity.owner_uuid matches to a role.uuid

          Here is a list of tags the organisation uses;

          ${YAML.stringify(
            tags.tags.list.slice(0, 30).map((tag) => ({
              uuid: tag.uuid,
              name: tag.name,
              description: tag.description,
            }))
          )}

          The organisational details: 

          ${YAML.stringify(scenarioData)}

          The groups:

          ${YAML.stringify(groupData)}

          The roles:

          ${YAML.stringify(rolesData)}

          The activities:

          ${YAML.stringify(activitiesData)}`;

  return prompt;
};

export default orgData;
