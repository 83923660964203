import * as Colors from '@/atoms/colors';
import { Tabs } from '@/molecules/menus';
import { PlanSummary } from '@/organisms/plans';
import Box from '@mui/material/Box';
import { core } from '@/lib/theme/tokens/palettes';
import { brand } from '@/lib/theme/tokens/palettes';
import InsightTabOptions from './InsightTabOptions';

const PlanInsights = () => {
  return (
    <>
      <Box width="100%">
        <Tabs
          options={InsightTabOptions}
          initialSelected={InsightTabOptions[0]}
        />
      </Box>
      <Box
        backgroundColor={core.white}
        height="calc(100vh - 139px)"
        pt={1}
        sx={{
          position: 'relative',
          overflow: 'hidden',
          boxShadow: `inset 1px 0 0 ${Colors.Base.border.primary}`,
        }}
      >
        <Box
          height="calc(100vh - 148px)"
          sx={{
            overflowY: 'auto',
            boxShadow: `0 2px 0 0 ${brand.lightBlue.tints[2]}`,
          }}
        >
          <PlanSummary />
        </Box>
      </Box>
    </>
  );
};

export default PlanInsights;
