import { Collections } from '@pkg/utils';

/**
 * @param {Object} snapshot
 * @param {Object} input
 * @param {String} input.uuid
 * @param {String} [input.title]
 * @param {Number} [input.fte]
 * @param {String[]} [input.tags]
 * @param {Boolean} [input.enabled]
 * @returns {Array}
 */
export default function update(snapshot, { uuid, title, fte, tags, enabled }) {
  const entities = snapshot.entities;

  const role = Collections.findById(entities.roles, uuid);
  if (!role?.__library_uuid) {
    return null;
  }

  let isDifferent = false;
  const updatedRole = { ...role };

  if (title !== undefined) {
    isDifferent = true;
    updatedRole.title = title;
  }

  if (Number.isFinite(fte)) {
    isDifferent = true;
    updatedRole.fte = fte;
  }

  if (tags !== undefined) {
    isDifferent = true;
    updatedRole.tags = tags;
  }

  if (enabled !== undefined) {
    isDifferent = true;
    updatedRole.__enabled = enabled;
  }

  if (!isDifferent) {
    return [{ ...snapshot }, null];
  }

  updatedRole.updated_at = Date.now();

  const roleActivities = Collections.where(
    entities.activities,
    'owner_uuid',
    uuid
  );

  const updatedSnapshot = {
    entities: {
      activities: roleActivities,
      roles: [updatedRole],
    },
  };

  return {
    uuid: role.__library_uuid,
    snapshot: updatedSnapshot,
    enabled: updatedRole.__enabled ?? role.__enabled,
  };
}
