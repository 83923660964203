import { defaultMetrics } from '@/organisms/plans/utils';
import { ActionType, EntityType } from '@/shared/enums';

export default function updatedBudget({
  comparisonScenario,
  originalRole,
  role,
}) {
  const action = {
    type: ActionType.UPDATED_BUDGET,
    [EntityType.ROLE]: new Map([
      [
        role.uuid,
        {
          metrics: {
            budget: role.budget,
          },
        },
      ],
    ]),
    metrics: defaultMetrics({ comparisonScenario }),
  };

  const budget = role.budget - originalRole.budget;

  action.metrics[1].budget += budget;

  return action;
}
