import { useEffect, useState } from 'react';
import { PlanEntityTypeCard } from '@/organisms/cards';
import {
  EmptyPlanList,
  mapAggregateRoles,
  PlanListSkeleton,
} from '@/organisms/plans';
import { EntityType } from '@/shared/enums';
import {
  usePlanContext,
  useScenarioContext,
  useViewModeContext,
} from '@/shared/providers';
import Grid from '@mui/material/Grid';
import { Sort } from '@pkg/utils';

const PlanRoleTypeList = ({ onSelect }) => {
  const { comparisonScenario, scenario } = useScenarioContext();
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(true);
  const { selectedSummaryItem, selectSummaryItem } = usePlanContext();
  const { showBudget } = useViewModeContext();

  const handleSummarySelect = (event, role) => {
    event.preventDefault();
    event.stopPropagation();

    selectSummaryItem({
      ...role,
      entityType: EntityType.ROLE,
    });
  };

  const handleRoleSelect = (event, role) => {
    event.preventDefault();
    event.stopPropagation();

    if (selectedSummaryItem?.id !== role.id) {
      selectSummaryItem({
        ...role,
        entityType: EntityType.ROLE,
      });
    }

    onSelect(role);
  };

  useEffect(() => {
    const roles = mapAggregateRoles({
      comparisonScenario,
      scenario,
      showBudget,
    });

    if (!roles) {
      return;
    }

    setRoles(
      [...roles.entries()]
        .map(([id, role]) => {
          return {
            ...role,
            id,
          };
        })
        .sort(Sort.alpha('props.title'))
    );
    setLoading(false);
  }, [scenario?.details?.hash, showBudget, comparisonScenario?.details?.hash]);

  return loading ? (
    <PlanListSkeleton />
  ) : (
    <>
      {roles.length > 0 ? (
        <Grid container spacing={2} p={2}>
          {roles.map((role) => (
            <Grid
              item
              xs={12}
              key={role.props.title}
              onClick={(event) => handleSummarySelect(event, role)}
            >
              <PlanEntityTypeCard
                entityType={EntityType.ROLE}
                metrics={role.metrics}
                entityCount={role[EntityType.ROLE]?.size || 0}
                entityMap={role[EntityType.ROLE]}
                isSelected={role.id === selectedSummaryItem?.id}
                onEdit={(event) => handleRoleSelect(event, role)}
                title={role.props.title}
              />
            </Grid>
          ))}
        </Grid>
      ) : (
        <EmptyPlanList
          message={`No role changes have been found in this scenario.`}
        />
      )}
    </>
  );
};

export default PlanRoleTypeList;
