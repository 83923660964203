import { RowColors, stackColor } from '@/organisms/charts';
import { EntityType } from '@/shared/enums';
import { percent } from '@pkg/utils/numbers';
import { EntityMetric } from '@/lib/enums';

const COMPARISON_BAR_WIDTH = 14;
const COMPARISON_BAR_PADDING = 4;
const COMPARISON_BAR_X_OFFSET = 38;

const getColors = ({ metric, row }) => {
  if (!row?.isStackChild) {
    return RowColors[row.type]?.bar;
  }

  const value = row?.data?.[metric];
  const total = row?.parentData?.[metric];
  const colorType =
    row?.parentType === EntityType.PROPERTY ? undefined : row?.parentType;

  const percentage = percent(value, total);

  return stackColor(percentage, colorType);
};

const VerticalBar = ({
  barWidth,
  chartHeight,
  metric,
  minBarSize = 8,
  padding = 8,
  row,
  stackData,
  xPosition,
  yPosition,
  yComparison,
}) => {
  const height = yPosition > minBarSize ? yPosition : minBarSize;
  const colors = getColors({
    metric: metric === EntityMetric.PERCENTAGE ? EntityMetric.HOURS : metric,
    row,
  });

  const hasComparison = yComparison > 0;
  const comparisonColor = row.comparison?.color;

  const adjustedBarWidth = hasComparison
    ? barWidth - COMPARISON_BAR_WIDTH - COMPARISON_BAR_PADDING
    : barWidth;

  return (
    <g className="vertical-bar">
      <rect
        width={adjustedBarWidth - padding}
        height={6}
        rx={3}
        ry={3}
        x={xPosition}
        y={chartHeight - 4}
        fill={colors.borderColor}
      />
      <rect
        width={adjustedBarWidth - padding}
        height={height}
        rx={3}
        ry={3}
        x={xPosition}
        y={chartHeight - height}
        fill={colors.backgroundColor}
      />
      {hasComparison && (
        <rect
          width={COMPARISON_BAR_WIDTH}
          height={yComparison + 2}
          rx={3}
          ry={3}
          x={xPosition + COMPARISON_BAR_X_OFFSET}
          y={chartHeight - yComparison}
          fill={comparisonColor?.fill}
        />
      )}
    </g>
  );
};

export default VerticalBar;
