import { useEffect, useState } from 'react';
import useScenarioActivities from '@/shared/hooks/useScenarioActivities';
import { useScenarioContext } from '@/shared/providers/ScenarioProvider';
import { setGroupedActivitiesColor } from '@/shared/utils';
import { DesignEntity } from '@/lib/enums';
import formatChartData from './formatChartData';

const useAggregateActivitiesChartData = (initialActivityCount) => {
  const { scenario } = useScenarioContext();
  const { activities, metrics, unallocated } = useScenarioActivities();
  const entityType = scenario?.entity?.is_manager
    ? DesignEntity.MANAGER
    : scenario?.entity?.__type;
  const groupedActivitiesColor = setGroupedActivitiesColor(entityType);
  const [chartData, setChartData] = useState();

  useEffect(() => {
    if (!activities?.length) {
      return;
    }

    setChartData(
      formatChartData(
        [...activities, ...unallocated],
        metrics,
        groupedActivitiesColor.foreground,
        initialActivityCount
      )
    );
  }, [activities]);

  return chartData;
};

export default useAggregateActivitiesChartData;
