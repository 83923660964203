import { Subtitle } from '@/atoms/typography';
import { StackChartBar } from '@/molecules/chartElements';
import { RelatedActivityEntities } from '@/organisms/activities';
import { useScenarioContext } from '@/shared/providers/ScenarioProvider';
import { setGroupedActivitiesColor } from '@/shared/utils';
import Stack from '@mui/material/Stack';
import { DesignEntity } from '@/lib/enums';
import Box from '@/components/Box';

const AggregateActivityListChart = ({
  activities,
  initialActivityCount,
  metrics,
  showAllActivities = true,
  unallocated,
}) => {
  const { scenario } = useScenarioContext();
  const entityType = scenario?.entity?.is_manager
    ? DesignEntity.MANAGER
    : DesignEntity.ROLE;
  const groupedActivitiesColor = setGroupedActivitiesColor(entityType);
  const interactive = scenario?.entity?.__type === DesignEntity.GROUP;

  return (
    Boolean(activities?.length > 0) && (
      <Stack spacing={2}>
        <Box>
          {activities.map(
            (activity, index) =>
              (showAllActivities || index < initialActivityCount) && (
                <Box
                  key={activity.uuid}
                  sx={{
                    mt: index === 0 ? 0 : 1.5,
                  }}
                >
                  <RelatedActivityEntities
                    entity={activity}
                    entityType={entityType}
                    interactive={interactive}
                  >
                    <StackChartBar
                      colors={activity.__color}
                      interactive={interactive}
                      title={activity.description ?? activity.__description}
                      numerator={activity.__aggregateHours}
                      denominator={metrics?.totalHours}
                      showNumerator
                      numeratorLabel="Hours"
                    />
                  </RelatedActivityEntities>
                </Box>
              )
          )}
        </Box>
        {Boolean(unallocated.length > 0) && showAllActivities && (
          <Box>
            <Subtitle>Unallocated activities</Subtitle>
            {unallocated.map((activity, index) => (
              <Box key={activity.uuid} sx={{ mt: index === 0 ? 0 : 1.5 }}>
                <StackChartBar
                  colors={groupedActivitiesColor}
                  title={activity.description ?? activity.__description}
                  numerator={activity.__aggregateHours}
                  denominator={metrics?.totalHours}
                />
              </Box>
            ))}
          </Box>
        )}
      </Stack>
    )
  );
};

export default AggregateActivityListChart;
