import { Collections } from '@pkg/utils';

/**
 * @param {Object} input
 * @param {Object} snapshot
 * @returns {Array}
 */
export default function remove(snapshot, { uuid }) {
  const entities = snapshot.entities;
  const role = Collections.findById(entities.roles, uuid);

  if (!role?.__library_uuid) {
    return null;
  }

  return {
    uuid: role.__library_uuid,
  };
}
