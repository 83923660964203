import getScenarioEntity from '@pkg/entities/designs/getScenarioEntity';
import { DesignScope } from '@/lib/enums';

export default function prepareDesignOptions(entityMap, current) {
  const options = [];
  entityMap.forEach((design) => {
    // skip currently active design
    if (current?.uuid === design.uuid) {
      return;
    }

    const scenarioEntity = getScenarioEntity(design);
    const entityId =
      design.scope === DesignScope.ORGANISATION
        ? design.uuid
        : (scenarioEntity?.uuid ?? null);

    if (!entityId) {
      return;
    }

    const entity = {
      uuid: entityId,
      type: design.scope,
    };

    options.push({
      id: design.uuid ?? design.__uuid,
      label: design.name ?? design.latest?.name,
      scenarioType: design.scope,
      isScenario: design.is_scenario,
      entity,
    });
  });
  return options;
}
