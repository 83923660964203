import { ActionType, EntityType } from '@/shared/enums';
import SnapshotMetricGroup from '@pkg/entities/snapshots/enums/SnapshotMetricGroup';

export default function defaultMetrics({
  activity,
  action,
  comparisonScenario,
  role,
}) {
  let metricGroup = {
    ...SnapshotMetricGroup,
  };

  if (!activity && !role) {
    const metrics = comparisonScenario?.entity?.__total_metrics;
    if (metrics) {
      metricGroup = {
        ...metrics,
      };
    }
  }
  if (activity) {
    const comparisonActivity = comparisonScenario.relationships
      ?.get(EntityType.ACTIVITY)
      ?.get(activity?.uuid);

    metricGroup.hours = comparisonActivity?.hours || 0;

    if (
      action?.type === ActionType.REMOVED_ACTIVITY ||
      action?.type === ActionType.REMOVE_ACTIVITIES
    ) {
      metricGroup.activities = 1;
    }
  }

  if (!activity && role) {
    const metrics = comparisonScenario.relationships
      ?.get(EntityType.ROLE)
      ?.get(role?.uuid)?.__metrics?.self?.visible;

    metricGroup = {
      ...metrics,
    };
  }

  return [
    {
      ...SnapshotMetricGroup,
      moved: 0,
      affectedRoles: 0,
      ...metricGroup,
    },
    {
      ...SnapshotMetricGroup,
      moved: 0,
      affectedRoles: 0,
      ...metricGroup,
    },
  ];
}
