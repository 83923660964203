import { EntityType } from '@/shared/enums';
import addedActivityAction from './addedActivityAction';
import movedActivityAction from './movedActivityAction';
import removedActivityAction from './removedActivityAction';
import updatedHoursAction from './updatedHoursAction';

export default function addActionByActivity({
  activity,
  originalActivity,
  scenario,
  comparisonScenario,
  ignoreMoved,
}) {
  const role =
    activity?.owner_type === EntityType.ROLE
      ? scenario.relationships?.get(EntityType.ROLE)?.get(activity.owner_uuid)
      : null;

  const comparisonRole =
    originalActivity?.owner_type === EntityType.ROLE
      ? comparisonScenario.relationships
          ?.get(EntityType.ROLE)
          ?.get(originalActivity?.owner_uuid)
      : null;

  const actions = [];

  // If it's a new activity.
  if (activity && !originalActivity) {
    actions.push(addedActivityAction({ activity, comparisonScenario }));
    return actions;
  }

  // If it's an activity that has been removed.
  if (!activity && originalActivity) {
    return [
      removedActivityAction({
        activity: originalActivity,
        comparisonScenario,
      }),
    ];
  }

  if (
    ignoreMoved &&
    activity &&
    activity?.owner_uuid !== originalActivity?.owner_uuid
  ) {
    if (role?.uuid === activity?.owner_uuid) {
      actions.push(addedActivityAction({ activity, comparisonScenario }));
    }
    if (role?.uuid === originalActivity?.owner_uuid) {
      actions.push(
        removedActivityAction({
          activity: originalActivity,
          comparisonScenario,
        })
      );
    }
  }

  // If it's a moved activity.
  if (
    !ignoreMoved &&
    activity &&
    activity?.owner_uuid !== originalActivity?.owner_uuid
  ) {
    actions.push(
      movedActivityAction({
        activity,
        comparisonScenario,
        originalActivity,
      })
    );
  }

  // If the activity hours were changed.
  if (
    activity &&
    originalActivity &&
    activity.hours !== originalActivity.hours
  ) {
    actions.push(
      updatedHoursAction({ activity, originalActivity, comparisonScenario })
    );
  }

  return actions;
}
