import { useState, useEffect } from 'react';
import { matrixChart } from '@/organisms/charts';
import { useAggregateEntities } from '@/shared/hooks';
import { useTagContext } from '@/shared/providers';

const useMatrixChartData = ({
  dimension,
  entityType,
  filter,
  matrix,
  metric,
  sort,
  useComparison,
}) => {
  const [chartData, setChartData] = useState();
  const { tagMap } = useTagContext();
  const [tags, setTags] = useState();
  const [metricMax, setMetricMax] = useState(0);
  const [metricTotals, setMetricTotals] = useState();

  const { entities: aggregateEntities, comparisonColor } = useAggregateEntities(
    {
      filter,
      matrix,
      order: dimension.order,
      sort,
      useComparison,
    }
  );

  const entities = useComparison
    ? aggregateEntities?.[1]
    : aggregateEntities?.[0];

  useEffect(() => {
    if (!entities?.metrics || !tagMap?.size) {
      setChartData();
      return;
    }

    const chartData = matrixChart({
      entities,
      entityType,
      excludeSeparators: true,
      metric,
      sort,
      titles: matrix.titles,
      subtitles: matrix.subtitles,
    });

    const tags = new Map(
      [...entities.entitySet].map((tagId) => {
        const tag = tagMap.get(tagId);

        return [tag.uuid, tag];
      })
    );

    let metricMax = 0;

    const matrixMap = Array.from(chartData.matrixMap.values());

    matrixMap.forEach((item) => {
      const first = item.data.find(({ type }) => type === entityType);

      if (!first?.data?.[metric]) {
        return;
      }

      metricMax =
        first.data[metric] > metricMax ? first.data[metric] : metricMax;
    });

    setTags(tags);
    setChartData(chartData);
    setMetricMax(metricMax);
    setMetricTotals(entities.metrics);
  }, [entities?.metrics, JSON.stringify(sort), metric, tagMap]);

  return {
    chartData,
    metricMax,
    metricTotals,
    tags,
    comparisonColor,
  };
};

export default useMatrixChartData;
