import { EntityChip } from '@/atoms/chips';
import { Entity as EntityColors } from '@/atoms/colors';
import { Size } from '@/atoms/enums';
import { Heading } from '@/atoms/typography';
import { ChangeMetric } from '@/molecules/metrics';
import { OutlineCard } from '@/organisms/cards';
import { EntityType } from '@/shared/enums';
import { useScenarioContext, useViewModeContext } from '@/shared/providers';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import {
  determineChartType,
  determineEntityChange,
  determineMetric,
} from './utils';

const retrieveGroup = ({ actions, comparisonScenario, id, scenario }) => {
  const group = scenario?.relationships?.get(EntityType.GROUP)?.get(id);

  if (group) {
    return group;
  }

  return comparisonScenario?.relationships?.get(EntityType.GROUP)?.get(id);
};

const PlanGroupCard = ({ actions, id, isSelected, onSelect, metrics }) => {
  const { comparisonScenario, scenario } = useScenarioContext();
  const group = retrieveGroup({
    comparisonScenario,
    id,
    scenario,
  });
  const { showBudget } = useViewModeContext();
  const entityColor = EntityColors[EntityType.ROLE];
  const metric = determineMetric({ metrics, showBudget });
  const chartType = determineChartType(metric);
  const entityChange = determineEntityChange({
    metrics,
    entityCount: 1,
    entityType: EntityType.ROLE,
  });

  const handleSelect = (event) => {
    event.stopPropagation();

    onSelect({
      id,
      props: {
        title: group?.name,
        group,
      },
      metrics,
      actions,
    });
  };

  return (
    <Stack direction="row" alignItems="center" width="100%">
      <Box width="136px" mr={1} flexShrink={0}>
        <ChangeMetric
          chartType={chartType}
          colors={{
            foreground: entityColor.primary,
            background: entityColor.secondary,
          }}
          entityType={EntityType.GROUP}
          metric={metric}
          metrics={metrics}
        />
      </Box>
      <Box flexGrow={1} onClick={handleSelect}>
        <OutlineCard padding={0} margin={0}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            p={1.5}
            sx={{
              boxShadow: isSelected
                ? `inset 0 0 0 1px ${entityColor.primary}, 0 0 0 1px ${entityColor.primary}`
                : 'none',
              borderRadius: '4px',
              cursor: 'pointer',
              transition: 'box-shadow 200ms',
              '&: hover': !isSelected && {
                boxShadow: `inset 0 0 0 1px ${entityColor.secondary}, 0 0 0 1px ${entityColor.secondary}`,
              },
            }}
          >
            <Box>
              <EntityChip
                size={Size.XX_SMALL}
                sx={{ height: 20, px: 1 }}
                type={EntityType.GROUP}
              />
              <Box>
                <Heading
                  variant="h4"
                  overrideStyles={{
                    fontSize: '1.025rem',
                    mb: 0,
                    mt: 0.5,
                  }}
                >
                  {group?.name}
                </Heading>
              </Box>
            </Box>
          </Stack>
        </OutlineCard>
      </Box>
    </Stack>
  );
};

export default PlanGroupCard;
