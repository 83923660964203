import { useMemo } from 'react';
import { Sort } from '@pkg/utils';
import config from '@/lib/config';

export default function useMerged(roles) {
  return useMemo(() => {
    if (!roles) {
      return null;
    }

    console.time('Roles.useMerged');
    const merged = structuredClone(config.DEFAULT_SNAPSHOT);
    const roleIds = Object.keys(roles).sort(Sort.uuid());

    roleIds.forEach((uuid) => {
      const role = roles[uuid];
      const entities = structuredClone(role.snapshot.entities);

      merged.entities.roles.push({
        ...entities.roles[0],
        __enabled: role.enabled ?? true,
        __library_uuid: uuid,
      });

      entities.activities.forEach((activity) => {
        merged.entities.activities.push(activity);
      });
    });

    console.timeEnd('Roles.useMerged');
    return merged;
  }, [roles]);
}
