import { Chip } from '@/atoms/chips';
import { status as StatusColors } from '@/atoms/chips/colors';

const StatusChip = ({
  status,
  expanded = true,
  fontWeight = 700,
  ...props
}) => {
  const label = status;
  const colorProps = StatusColors[status];

  return (
    <Chip
      colorProps={colorProps}
      expanded={expanded}
      fontWeight={fontWeight}
      name={label}
      {...props}
    />
  );
};

export default StatusChip;
