import { defaultMetrics } from '@/organisms/plans/utils';
import { ActionType, EntityType } from '@/shared/enums';

export default function removedActivity({
  activity,
  comparisonScenario,
  role,
}) {
  const action = {
    type: ActionType.REMOVED_ACTIVITY,
    [EntityType.ACTIVITY]: new Map(),
    metrics: defaultMetrics({ comparisonScenario }),
  };

  const actionActivity = {
    metrics: {
      hours: activity.hours,
    },
  };
  if (role) {
    actionActivity.props = {
      moved: {
        role: {
          to: role,
        },
      },
    };
  }

  action[EntityType.ACTIVITY].set(activity.uuid, actionActivity);

  action.metrics[1].activities -= 1;
  action.metrics[1].hours -= activity.hours;

  return action;
}
