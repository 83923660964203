import { useEffect, useState } from 'react';
import { PlanEntityTypeCard } from '@/organisms/cards';
import {
  EmptyPlanList,
  mapAggregateActivities,
  PlanListSkeleton,
} from '@/organisms/plans';
import { EntityType } from '@/shared/enums';
import { usePlanContext, useScenarioContext } from '@/shared/providers';
import Grid from '@mui/material/Grid';
import { Sort } from '@pkg/utils';

const PlanActivityTypeList = ({ onSelect }) => {
  const { comparisonScenario, scenario } = useScenarioContext();
  const [activities, setActivities] = useState([]);
  const [loading, setLoading] = useState(true);
  const { selectedSummaryItem, selectSummaryItem } = usePlanContext();

  const handleSummarySelect = (event, activity) => {
    event.preventDefault();
    event.stopPropagation();

    selectSummaryItem({
      ...activity,
      entityType: EntityType.ACTIVITY,
    });
  };

  const handleActivitySelect = (event, activity) => {
    event.preventDefault();
    event.stopPropagation();

    if (selectedSummaryItem?.id !== activity?.id) {
      selectSummaryItem({
        ...activity,
        entityType: EntityType.ACTIVITY,
      });
    }

    onSelect(activity);
  };

  useEffect(() => {
    const activities = mapAggregateActivities({ scenario, comparisonScenario });

    if (!activities) {
      return;
    }

    setActivities(
      [...activities.entries()]
        .map(([id, activity]) => {
          return {
            ...activity,
            id,
          };
        })
        .sort(Sort.alpha('props.title'))
    );
    setLoading(false);
  }, [scenario?.details?.hash, comparisonScenario?.details?.hash]);

  return loading ? (
    <PlanListSkeleton />
  ) : (
    <>
      {activities.length > 0 ? (
        <Grid container spacing={2} p={2}>
          {activities.map((activity) => (
            <Grid
              item
              xs={12}
              key={activity.props.title}
              onClick={(event) => handleSummarySelect(event, activity)}
            >
              <PlanEntityTypeCard
                entityType={EntityType.ACTIVITY}
                metrics={activity.metrics}
                entityCount={activity[EntityType.ACTIVITY]?.size || 0}
                entityMap={activity[EntityType.ACTIVITY]}
                isSelected={activity.id === selectedSummaryItem?.id}
                onEdit={(event) => handleActivitySelect(event, activity)}
                title={activity.props.title}
              />
            </Grid>
          ))}
        </Grid>
      ) : (
        <EmptyPlanList
          message={`No activity changes have been found in this scenario.`}
        />
      )}
    </>
  );
};

export default PlanActivityTypeList;
