import { useEffect, useState } from 'react';
import { SelectFilter } from '@/organisms/filters';
import { getFilterEntityType } from '@/organisms/filters/utils';
import { usePropertyContext } from '@/shared/providers';

const PropertyIdSelect = ({ condition, onChange, triggerOnOpen, value }) => {
  const { propertyMap } = usePropertyContext();
  const entity = getFilterEntityType(
    condition?.get('entity')?.key,
    condition?.get('property')?.key
  );
  const entityName = entity?.toLowerCase();
  const [options, setOptions] = useState();

  useEffect(() => {
    if (!entity || !propertyMap?.size) {
      return;
    }

    const filteredOptions = [...propertyMap.values()]
      .filter(({ scope }) => {
        return scope === entity;
      })
      .map(({ uuid, name }) => ({ uuid, name }));

    setOptions(filteredOptions);
  }, [entity, propertyMap?.size]);

  return (
    options && (
      <>
        <SelectFilter
          options={options}
          optionLabel="name"
          onChange={onChange}
          value={value}
          id="propertyId"
          label={`Select ${entityName} property`}
          emptyText={`No ${entityName} properties were found`}
          triggerOnOpen={triggerOnOpen}
        />
      </>
    )
  );
};

export default PropertyIdSelect;
