import { useMemo } from 'react';
import useLibrary from '../useLibrary';
import deriveProps from './deriveProps';

/**
 * @param {Object[]} snapshot
 * @return {Object}
 */
export default function useDerivedProps(snapshot) {
  const library = useLibrary();
  return useMemo(() => deriveProps(library, snapshot), [snapshot]);
}
