import { useEffect, useState } from 'react';
import { Button } from '@/atoms/buttons';
import { Base as BaseColors } from '@/atoms/colors';
import { Switch } from '@/atoms/inputs';
import { TextMetric } from '@/atoms/metrics';
import { Subtitle } from '@/atoms/typography';
import { DoughnutChart } from '@/molecules/chartElements';
import useScenarioActivities from '@/shared/hooks/useScenarioActivities';
import { useScenarioContext } from '@/shared/providers/ScenarioProvider';
import { setGroupedActivitiesColor } from '@/shared/utils';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Stack from '@mui/material/Stack';
import { tag as tagColor } from '@pkg/utils/colors';
import { percent } from '@pkg/utils/numbers';
import { DesignEntity } from '@/lib/enums';
import Box from '@/components/Box';
import TagCategory from '@/components/DashboardContainer/enums/TagCategory';
import TagActivityList from './TagActivityList';

const listTitle = ({ entityType, name, inverse, isTagged }) => {
  if (entityType === DesignEntity.ORGANISATION) {
    return '% of tagged activity hours by team';
  }

  if (isTagged) {
    return `Activities ${inverse ? 'not tagged' : 'tagged'} as ${name}`;
  }

  return `Activities that have not been tagged`;
};

const TagOverview = ({ onClose, tag, totalHours }) => {
  const { scenario } = useScenarioContext();
  const [entities, setEntities] = useState();
  const [roleCount, setRoleCount] = useState();
  const [showInverse, setShowInverse] = useState(false);
  const { activities: inverseActivities } = useScenarioActivities({
    tagList: [tag?.uuid],
    inverse: true,
  });

  const isTagged = tag?.uuid !== TagCategory.NOT_TAGGED;
  const entityType = scenario?.entity?.__type;
  const showInverseToggle =
    isTagged && entityType !== DesignEntity.ORGANISATION;
  const relatedEntityType =
    entityType === DesignEntity.ORGANISATION
      ? DesignEntity.GROUP
      : DesignEntity.ACTIVITY;
  const groupedActivitiesColor = setGroupedActivitiesColor(entityType);

  const title = listTitle({
    entityType,
    inverse: showInverse,
    name: tag?.name,
    isTagged,
  });

  const handleInverseClick = () => {
    setShowInverse(!showInverse);
  };

  useEffect(() => {
    if (!tag) {
      return;
    }

    let roles = new Set();

    const entities = Array.from(tag[relatedEntityType].entries())
      .sort(
        ([, entityA], [, entityB]) =>
          entityB.__aggregateHours - entityA.__aggregateHours
      )
      .map(([key, entity]) => {
        roles = new Set([...roles, ...entity.__roles.keys()]);
        return {
          ...entity,
          uuid: key,
        };
      });

    setRoleCount(roles?.size ?? 0);
    setEntities(entities);
  }, [JSON.stringify(tag)]);

  return (
    tag && (
      <Box key={tag.uuid}>
        <Stack direction="row" justifyContent="space-between">
          <DoughnutChart
            color={tagColor({ color: tag.color, property: 'fill' })}
            direction="row"
            label={tag.name}
            size={80}
            value={percent(tag.__aggregateHours, totalHours, 2)}
            options={{
              cutout: '80%',
              rotation: 270,
              plugins: {
                legend: {
                  display: false,
                },
                tooltip: {
                  enabled: false,
                },
              },
              options: {
                interaction: {
                  intersect: false,
                },
              },
            }}
          />
          {onClose && (
            <Button
              variant="text"
              label="Back"
              startIcon={<ArrowBackIcon />}
              onClick={onClose}
            />
          )}
        </Stack>
        <Stack
          direction="row"
          spacing={2}
          sx={{
            mt: 2,
            mb: showInverseToggle ? 2 : 4,
            color: BaseColors.font.primary,
          }}
        >
          <TextMetric
            label={
              tag[DesignEntity.ACTIVITY]?.size > 1 ? 'Activities' : 'Activity'
            }
            value={tag[DesignEntity.ACTIVITY]?.size}
          />
          <TextMetric
            label={roleCount > 1 ? 'Roles' : 'Role'}
            value={roleCount}
          />
        </Stack>
        {showInverseToggle && (
          <Box sx={{ mt: 4, mb: 2 }}>
            <Switch
              onClick={handleInverseClick}
              checked={showInverse}
              label="Inverse activities"
              labelPlacement="end"
            />
          </Box>
        )}
        <Subtitle>{title}</Subtitle>
        {entities?.length > 0 && (
          <TagActivityList
            key={`${tag.uuid}:${showInverse ? '1' : '0'}`}
            entities={showInverse ? inverseActivities : entities}
            entityType={
              entityType === DesignEntity.ORGANISATION
                ? DesignEntity.GROUP
                : DesignEntity.ROLE
            }
            color={groupedActivitiesColor}
            interactive={entityType === DesignEntity.GROUP}
            tagName={tag.name}
            totalHours={totalHours}
            inverse={showInverse}
            isTagged={isTagged}
          />
        )}
      </Box>
    )
  );
};

export default TagOverview;
