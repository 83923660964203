import { SkeletonItem } from '@/atoms/skeletons';
import Grid from '@mui/material/Grid';

const PlanListSkeleton = () => {
  return (
    <Grid container spacing={2} p={2}>
      <Grid item xs={12}>
        <SkeletonItem color="primary" height={76} />
      </Grid>
      <Grid item xs={12}>
        <SkeletonItem color="primary" height={76} />
      </Grid>
      <Grid item xs={12}>
        <SkeletonItem color="primary" height={76} />
      </Grid>
      <Grid item xs={12}>
        <SkeletonItem color="primary" height={76} />
      </Grid>
      <Grid item xs={12}>
        <SkeletonItem color="primary" height={76} />
      </Grid>
    </Grid>
  );
};

export default PlanListSkeleton;
