import { TagMatrixChart } from '@/organisms/charts';
import ManageComparison from '@/organisms/comparison/ManageComparison';
import { EntityType } from '@/shared/enums';
import {
  useViewModeContext,
  useScenarioContext,
  useTagContext,
} from '@/shared/providers';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import InsightsTitle from '../InsightsTitle/InsightsTitle';
import TaggedActivitiesBarChart from './TaggedActivitiesBarChart';

const TagInsights = () => {
  const { id, scope, scopeEntity, showBudget } = useViewModeContext();
  const { tagMap } = useTagContext();
  const { scenario, snapshotEntityMap, comparisonScenario } =
    useScenarioContext();
  const entityType = scenario?.entity?.is_manager
    ? EntityType.MANAGER
    : scenario?.entity?.__type;

  const isActiveComparison = !!comparisonScenario;

  // We use the selected tag to filters the tag insights with ALL showing every
  // tag.
  const tag = id && id !== 'ALL' ? tagMap?.get(id) : null;

  return (
    tagMap.size && (
      <Grid container spacing={2} pb={4}>
        <Grid item xs={12}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <InsightsTitle
              entityMap={snapshotEntityMap}
              id={id}
              scope={scope}
              scopeEntity={scopeEntity}
            />
            <ManageComparison />
          </Stack>
        </Grid>
        {scenario && (
          <>
            <Grid item xs={12}>
              <TaggedActivitiesBarChart
                tag={tag}
                levelType={entityType}
                showComparison={isActiveComparison}
              />
            </Grid>
            <Grid item xs={12}>
              <TagMatrixChart
                levelType={entityType}
                showTagSelector
                tag={tag}
                showBudget={showBudget}
              />
            </Grid>
            {isActiveComparison && (
              <Grid item xs={12}>
                <TagMatrixChart
                  levelType={entityType}
                  showTagSelector
                  tag={tag}
                  showBudget={showBudget}
                  showComparison={isActiveComparison}
                />
              </Grid>
            )}
          </>
        )}
      </Grid>
    )
  );
};

export default TagInsights;
