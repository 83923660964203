import { Snapshots } from '@pkg/entities';
import { Collections } from '@pkg/utils';
import { ActivityOwner } from '@/lib/enums';

/**
 * @param {Object} snapshot
 * @param {Object} input
 * @param {String} input.uuid
 * @param {String} input.owner_uuid
 * @param {Number} input.order
 * @returns {Array}
 */
export default function move(snapshot, { uuid, owner_uuid, order }) {
  const now = Date.now();

  const activityMutation = Snapshots.reducers.activities.move(snapshot, {
    ids: [uuid],
    owner_uuid,
    owner_type: ActivityOwner.ROLE,
    order,
  });

  const entities = snapshot.entities;
  const activity = Collections.findById(entities.activities, uuid);
  const role = Collections.findById(entities.roles, activity.owner_uuid);

  const roleActivities = Collections.where(
    entities.activities,
    'owner_uuid',
    role.uuid
  );

  const updatedActivities = Collections.updateById(
    roleActivities,
    activityMutation.entities.activities.update
  );

  const updatedSnapshot = {
    entities: {
      activities: updatedActivities,
      roles: [{ ...role, updated_at: now }],
    },
  };

  return {
    uuid: role.__library_uuid,
    snapshot: updatedSnapshot,
  };
}
