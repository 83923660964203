import { useRef } from 'react';
import {
  PlanActivityCard,
  PlanGroupCard,
  PlanRoleCard,
} from '@/organisms/cards';
import { EntityType } from '@/shared/enums';
import { useOutsideClick } from '@/shared/hooks';
import { usePlanContext } from '@/shared/providers';
import Grid from '@mui/material/Grid';

const PlanActionType = ({ onSelect, action }) => {
  const entityType = action.entity;
  const { selectedSummaryItem, selectSummaryItem } = usePlanContext();
  const actions = new Map([[action.type, {}]]);
  const ref = useRef();

  /**
   * Handles the default selection of the action type screen.
   */
  const handleSelectDefault = (event) => {
    event?.preventDefault();
    event?.stopPropagation();

    // Don't trigger an update if we're already on the default view.
    if (selectedSummaryItem?.id === action.id) {
      return;
    }

    selectSummaryItem({
      ...action,
      actions,
    });
  };

  const handleSelect = (action) => {
    if (!action || action.id === selectedSummaryItem?.id) {
      handleSelectDefault();
      return;
    }

    selectSummaryItem({
      ...action,
      actions,
    });
  };

  // Handles the click events triggered outside of the action type cards which
  // should default the view back to the action type screen.
  useOutsideClick({
    ref,
    onClick: handleSelectDefault,
    updateProperty: selectedSummaryItem?.id,
  });

  return (
    <Grid container spacing={2} p={2} onClick={handleSelectDefault} ref={ref}>
      {[...action[entityType].entries()].map(([id, entity]) => (
        <Grid item xs={12} key={id}>
          {entityType === EntityType.GROUP && (
            <PlanGroupCard
              groupProperties={entity.props}
              id={id}
              metrics={entity.metrics}
              onSelect={handleSelect}
              isSelected={id === selectedSummaryItem?.id}
            />
          )}
          {entityType === EntityType.ROLE && (
            <PlanRoleCard
              id={id}
              metrics={entity.metrics}
              onSelect={handleSelect}
              roleProperties={entity.props}
              isSelected={id === selectedSummaryItem?.id}
            />
          )}
          {entityType === EntityType.ACTIVITY && (
            <PlanActivityCard
              activityProperties={entity.props}
              id={id}
              metrics={entity.metrics}
              onSelect={handleSelect}
              isSelected={id === selectedSummaryItem?.id}
            />
          )}
        </Grid>
      ))}
    </Grid>
  );
};

export default PlanActionType;
