import { useEffect, useState } from 'react';
import { useDesignsContext, useScenarioContext } from '@/shared/providers';
import { Routes } from '@pkg/utils';
import { DesignEntity, DesignType } from '@/lib/enums';

/**
 * Creates a list of available designs based on the entity provided.
 *
 * @param {Object} entity
 * @param {Array} designs
 *
 * @return {Array}
 */
const designsByEntity = (scenario, designs) => {
  const options = [];
  const { entity, details } = scenario;

  if (!entity || !designs?.size) {
    return options;
  }

  const entityId = entity.uuid ?? entity.__uuid;

  const designGroups = {
    [DesignEntity.ORGANISATION]: [],
    [DesignEntity.MANAGER]: [],
  };

  designs.forEach(({ design, snapshotEntityMap }) => {
    if (!design || !snapshotEntityMap?.size) {
      return;
    }

    const isScenario = design.type !== DesignType.LIVE;
    const designId = isScenario ? design.uuid : 'main';

    const hasEntity = snapshotEntityMap.get(entity.__type)?.has?.(entityId);
    const isOrganisationEntity =
      entity.__type === DesignEntity.ORGANISATION &&
      design.scope === DesignEntity.ORGANISATION;

    const showLiveEntityDesign = details.isScenario && !isScenario && hasEntity;

    options.push({
      id: designId,
      created_at: new Date(design?.created_at),
      updated_at: new Date(design?.updated_at),
      path: design.__pathname,
      isScenario,
      label: design.name ?? design.latest?.name,
      level: {
        type: design.scope,
        uuid: entityId,
      },
      scenarioType: design?.scope,
    });

    // Adds an option for the entity in the live design as a navigational
    // convenience.
    if (showLiveEntityDesign) {
      const entityType = entity.is_manager
        ? DesignEntity.MANAGER
        : entity.__type;

      const path = Routes.build.designParts({
        designId,
        level: {
          type: entityType,
          uuid: entityId,
        },
      });

      options.push({
        id: `${designId}-${entityId}`,
        created_at: new Date(design?.created_at),
        path,
        isScenario,
        label: entity.name ?? entity.title,
        level: {
          type: entityType,
          uuid: entityId,
        },
        scenarioType: entityType,
      });
    }
  });

  // Sorts the options by their updated date.
  const sortedOptions = options.sort((previous, next) => {
    if (!next.isScenario) {
      return 2;
    }
    return next.updated_at - previous.updated_at;
  });

  return sortedOptions;
};

/**
 * Generates a list of available designs relative to the scenario entity.
 */
const useDesignOptions = () => {
  const { scenario } = useScenarioContext();
  const designs = useDesignsContext();
  const [options, setOptions] = useState();
  const entityId = scenario?.entity?.uuid ?? scenario?.entity?.__uuid;

  // Updates the design options based on the scenario entity and availanble
  // designs.
  useEffect(() => {
    if (!entityId || !designs?.designs?.size) {
      return;
    }

    const options = designsByEntity(scenario, designs?.designs);

    setOptions(options);
  }, [entityId, designs?.designs?.size]);

  return {
    options,
  };
};

export default useDesignOptions;
