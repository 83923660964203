import { useEffect, useRef } from 'react';
import { Button } from '@/atoms/buttons';
import { Size } from '@/atoms/enums';
import { TextOptions } from '@/atoms/inputs';
import { Paragraph } from '@/atoms/typography';
import { Popover } from '@/molecules/modals';
import { FilterOptions } from '@/organisms/filters';
import Box from '@mui/material/Box';
import { Position } from '@/lib/enums';

const SelectFilter = ({
  emptyText,
  options,
  id,
  value,
  label,
  optionLabel,
  optionId,
  onChange,
  triggerOnOpen,
}) => {
  const handleChange = (event, value) => {
    onChange?.(id, value);
  };
  const popoverRef = useRef();

  useEffect(() => {
    if (!popoverRef?.current) {
      return;
    }

    if (triggerOnOpen && !value) {
      popoverRef?.current?.open();
    }
  }, [popoverRef?.current, value]);

  return (
    <Popover
      Component={
        <Button
          color={value ? 'light-blue' : 'secondary'}
          size={Size.MEDIUM}
          label={value?.[optionLabel] ?? label}
        />
      }
      position={Position.BOTTOM_LEFT}
      maxHeight="240px"
      ref={popoverRef}
    >
      <FilterOptions closeOnClick showClose={false}>
        {options?.length > 0 ? (
          <TextOptions
            options={options}
            optionLabel={optionLabel}
            optionId={optionId}
            onClick={handleChange}
            initialSelected={value}
          />
        ) : (
          <Box p={2}>
            <Paragraph
              size={Size.SMALL}
              overrideStyles={{ textAlign: 'center' }}
            >
              {emptyText ?? 'No results were found'}
            </Paragraph>
          </Box>
        )}
      </FilterOptions>
    </Popover>
  );
};

export default SelectFilter;
