import { useEffect, useState } from 'react';
import { LinkButton } from '@/atoms/buttons';
import { Card as CardColors } from '@/atoms/colors';
import { Base as BaseColors } from '@/atoms/colors';
import { Size } from '@/atoms/enums';
import { Switch } from '@/atoms/inputs';
import { EntitySummary } from '@/molecules/summaries';
import { ScenarioDetails } from '@/organisms/scenarios';
import { EntityType } from '@/shared/enums';
import { ViewMode } from '@/shared/enums';
import { useDesignsContext } from '@/shared/providers/DesignsProvider';
import { useScenarioContext } from '@/shared/providers/ScenarioProvider';
import buildComparisonPath from '@/shared/utils/buildComparisonPath';
import AccountTreeIcon from '@mui/icons-material/AccountTreeSharp';
import PersonIcon from '@mui/icons-material/Person';
import Grow from '@mui/material/Grow';
import Stack from '@mui/material/Stack';
import { useAccess } from '@pkg/access/organisations';
import { Colors, Routes } from '@pkg/utils';
import { DesignEntity } from '@/lib/enums';
import { Feature } from '@/lib/enums';
import Box from '@/components/Box';
import DateTime from '@/components/DateTime';
import Typography from '@/components/Typography';

const setColor = (entityType) => {
  const colors = Colors.entity(entityType);

  return {
    background: colors.main,
    font: '#FFF',
    action: {
      background: colors.main,
      font: '#FFF',
      highlight: {
        background: colors.shades[4],
        font: '#FFF',
      },
    },
    scenario: {
      background: colors.shades[3],
    },
  };
};

const ScenarioCard = ({
  allowLink = false,
  linkToDesign,
  size = Size.LARGE,
  showScenarioDetails = true,
  showLastUpdated = true,
  showNestedSwitch = false,
  showPerson,
  showChip = true,
  children,
}) => {
  const { scenario, includeNested, setIncludeNested } = useScenarioContext();
  const designs = useDesignsContext();
  const [entityLink, setEntityLink] = useState('');
  const entityType = scenario?.entity?.is_manager
    ? EntityType.MANAGER
    : scenario?.entity?.__type;
  const colors = setColor(entityType);
  const access = useAccess();

  const isScenario = scenario?.details?.isScenario;
  const isOrganisation = entityType === DesignEntity.ORGANISATION;
  const isManager = entityType === DesignEntity.MANAGER;
  const isGroup = entityType === DesignEntity.GROUP;
  const isRole = entityType === DesignEntity.ROLE;
  const isRoleScenario = (isRole || isManager) && isScenario;

  const canDesign = access.features[Feature.ORGANISATION_DESIGN];
  const showLink =
    allowLink && (!isOrganisation || (isOrganisation && canDesign));

  useEffect(() => {
    if (
      !scenario?.details?.designId ||
      (!scenario?.entity?.uuid && !scenario?.entity?.__uuid)
    ) {
      return;
    }
    const { details } = scenario;

    const entityId = scenario?.entity?.uuid ?? scenario?.entity?.__uuid;

    // Sets the entity link as the comparison path if this is a role scenario.
    if (isRoleScenario && designs && !designs?.isLoading) {
      const { design, snapshotEntityMap } = Array.from(
        designs?.designs?.values()
      ).find(({ design }) => !design.is_scenario);

      // If the role doesn't exist in the snapshot entity map of the main
      // design, we should not link to the comparison path.
      if (snapshotEntityMap.get(EntityType.ROLE)?.get(entityId)) {
        const path = buildComparisonPath(
          entityId,
          details?.designId,
          design.uuid
        );

        setEntityLink(path);

        return;
      }
    }

    if (!canDesign) {
      return;
    }

    const routeParts = {
      designId: details?.isScenario ? details?.designId : 'main',
      level: {
        type:
          isManager && !isScenario
            ? DesignEntity.MANAGER
            : scenario.entity.__type,
        uuid: scenario.entity.uuid ?? scenario.entity.__uuid,
      },
    };

    if (isRole && !linkToDesign) {
      routeParts.hash = {
        layout: 'list',
      };
    }

    if (linkToDesign) {
      routeParts.params = {
        view: ViewMode.DIAGRAM,
      };
    }

    setEntityLink(Routes.build.designParts(routeParts));
  }, [
    scenario?.details?.designId,
    scenario?.entity?.uuid,
    scenario?.entity?.__uuid,
    designs?.isLoading,
  ]);

  return (
    <Grow in={true} timeout={500}>
      <Box
        p={0.5}
        backgroundColor={CardColors.outline.backgroundColor}
        borderRadius={1}
        boxShadow={`inset 0 0 0 1px ${CardColors.outline.borderColor}, 0 1px 3px 1px ${CardColors.outline.borderColor}44`}
        color={colors.font}
        overflow="hidden"
      >
        <Box p={1} backgroundColor={colors.background} borderRadius={0.5}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
          >
            <EntitySummary
              entity={scenario?.entity}
              showPerson={showPerson && (isManager || isRole)}
              showChip={showChip}
              isLarge={size === Size.LARGE}
            />
            {showLink && (
              <LinkButton
                dataTestId="view-scenario-entity"
                path={entityLink}
                startIcon={
                  isRoleScenario ? <PersonIcon /> : <AccountTreeIcon />
                }
                iconSize={Size.MEDIUM}
                label={isRoleScenario ? 'VIEW ROLE' : 'DIAGRAM'}
                color="white"
              />
            )}
          </Stack>
        </Box>
        <Box px={1} pt={2} py={showLastUpdated ? 2 : 0} color="#333333">
          {isScenario && showScenarioDetails && (
            <Box mb={2}>
              <ScenarioDetails />
            </Box>
          )}
          <Stack direction="row" justifyContent="space-between">
            {showLastUpdated && Boolean(scenario?.entity?.updated_at) && (
              <Typography
                color={BaseColors.font.primary}
                fontSize="0.825rem"
                variant="body2"
              >
                Last updated{' '}
                {Boolean(scenario?.entity?.updated_at) && (
                  <DateTime.Relative date={scenario?.entity?.updated_at} />
                )}
              </Typography>
            )}
            {isGroup && showNestedSwitch && (
              <Box mt={-1.25} mb={-1.75}>
                <Switch
                  onClick={() => setIncludeNested(!includeNested)}
                  checked={includeNested}
                  label="Include sub-team roles"
                  labelPlacement="start"
                />
              </Box>
            )}
          </Stack>
          {children}
        </Box>
      </Box>
    </Grow>
  );
};

export default ScenarioCard;
