import { defaultMetrics } from '@/organisms/plans';
import { ActionType, EntityType } from '@/shared/enums';
import { entityLabel } from '@/shared/utils';

export default function movedActivities({
  actionMap,
  activity,
  comparisonScenario,
  originalActivity,
  scenario,
}) {
  const metrics = {
    hours: activity.hours,
  };

  const existingAction = actionMap?.get(ActionType.MOVE_ACTIVITIES);

  const action = existingAction ?? {
    entity: EntityType.ACTIVITY,
    metrics: defaultMetrics({ comparisonScenario }),
    type: ActionType.MOVE_ACTIVITIES,
    [EntityType.ACTIVITY]: new Map(),
    [EntityType.ROLE]: new Set(),
  };

  const activityProperties = {};

  if (activity.owner_uuid !== originalActivity.owner_uuid) {
    if (activity.owner_uuid && activity.owner_type === EntityType.ROLE) {
      action[EntityType.ROLE].add(activity.owner_uuid);
    }
    if (
      originalActivity.owner_uuid &&
      originalActivity.owner_type === EntityType.ROLE
    ) {
      action[EntityType.ROLE].add(originalActivity.owner_uuid);
    }

    activityProperties.moved = {
      role: {
        from: originalActivity?.owner_uuid,
        to: activity?.owner_uuid,
      },
    };
  }

  action.metrics[1].moved += 1;

  // Aggregate the individual activity action metrics.
  const actionActivity = action[EntityType.ACTIVITY].get(activity.uuid) ?? {
    metrics: defaultMetrics({ action, activity, comparisonScenario }),
  };
  actionActivity.metrics[1].moved += 1;
  actionActivity.props = { ...activityProperties };
  action[EntityType.ACTIVITY].set(activity.uuid, actionActivity);

  const activityCount = action[EntityType.ACTIVITY].size;
  const roleCount = action[EntityType.ROLE].size;

  const activityLabel = entityLabel(EntityType.ACTIVITY, activityCount !== 1);
  const roleLabel = entityLabel(EntityType.ROLE, roleCount !== 1);
  const verb = activityCount === 1 ? 'has' : 'have';

  action.title = `${activityCount} ${activityLabel} ${verb} been moved impacting ${roleCount} ${roleLabel}`;

  return action;
}
