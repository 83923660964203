import { EntityMetric } from '@/lib/enums';

const METRIC_LIST = [
  EntityMetric.BUDGET,
  EntityMetric.FTE,
  EntityMetric.HOURS,
  EntityMetric.ACTIVITIES,
  EntityMetric.ROLES,
  EntityMetric.GROUPS,
];

export default function getSummaryMetricList({
  comparisonMetrics,
  scenarioMetrics,
  showBudget,
}) {
  const metricList = [];
  if (!scenarioMetrics || !comparisonMetrics) {
    return metricList;
  }

  METRIC_LIST.forEach((metric) => {
    if (!showBudget && metric === EntityMetric.BUDGET) {
      return;
    }
    if (metric === EntityMetric.BUDGET) {
      metricList.push(metric);
      return;
    }
    if (comparisonMetrics[metric] !== scenarioMetrics[metric]) {
      metricList.push(metric);
    }
  });

  return metricList;
}
