import { useState } from 'react';
import { Button } from '@/atoms/buttons';
import { Base as BaseColors } from '@/atoms/colors';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import Messages from './components/Messages';
import { useMessages, MessagesProvider } from './context/MessagesContext';
import useAIResponse from './hooks/useAIResponse';

const COPILOT_EXPANDED_KEY = 'copilot_expanded';

const Copilot = ({ opened }) => {
  const { addMessage, addStream, messages } = useMessages();
  const [thinking, setThinking] = useState(false);

  const [isExpanded, setIsExpanded] = useState(() => {
    if (typeof window !== 'undefined') {
      const saved = localStorage.getItem(COPILOT_EXPANDED_KEY);
      return saved ? JSON.parse(saved) : false;
    }
    return false;
  });

  const { sendToAI } = useAIResponse();
  const [input, setInput] = useState('');

  const [streamingMessage] = useState(null);

  const handleSendMessage = async () => {
    if (input.trim()) {
      addMessage({ text: input, sender: 'User' });

      setThinking(true);
      const { message, stream } = await sendToAI(input);
      console.log({ message, stream });

      if (stream) {
        addStream({ stream });
      } else {
        addMessage(message);
      }

      setThinking(false);

      setInput('');
    }
  };

  const toggleExpand = () => {
    const newExpandedState = !isExpanded;
    setIsExpanded(newExpandedState);
    if (typeof window !== 'undefined') {
      localStorage.setItem(
        COPILOT_EXPANDED_KEY,
        JSON.stringify(newExpandedState)
      );
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        position: 'fixed',
        height: '80vh',
        bottom: '2rem',
        right: '2rem',
        width: isExpanded ? '800px' : '400px',
        background: '#fff',
        visibility: opened ? 'visible' : 'hidden',
        color: BaseColors.font.tertiary,
        zIndex: 1000,
        border: '1px solid #ddd',
        borderRadius: '8px',
        flexDirection: 'column',
        overflow: 'hidden',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
        transition: 'width 0.3s ease-in-out',
      }}
    >
      {/* Header with expand button */}
      <div
        style={{
          padding: '8px 16px',
          borderBottom: '1px solid #ddd',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div>Copilot</div>
        <Button
          onClick={toggleExpand}
          style={{
            minWidth: 'unset',
            padding: '4px',
          }}
          color="white"
          size="small"
          startIcon={isExpanded ? <ChevronRight /> : <ChevronLeft />}
        />
      </div>

      <div
        style={{
          flex: 1,
          overflowY: 'auto',
          display: 'flex',
          flexDirection: 'column',
          padding: '16px',
          borderBottom: '1px solid #ddd',
          height: 'calc(100% - 120px)', // Account for header and input box
        }}
      >
        <Messages
          thinking={thinking}
          messages={
            streamingMessage ? [...messages, streamingMessage] : messages
          }
        />
        <div style={{ height: '40px' }}></div>
      </div>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          padding: '8px',
          borderTop: '1px solid #ddd',
        }}
      >
        <input
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault(); // Prevent form submission if inside a form element
              handleSendMessage();
            }
          }}
          style={{
            flex: 1,
            padding: '8px',
            border: '1px solid #ccc',
            borderRadius: '4px',
            marginRight: '8px',
          }}
          placeholder="Type a message..."
        />
        <Button
          color="secondary"
          label="Send"
          onClick={handleSendMessage}
          size="large"
          disabled={thinking}
        />
      </div>
    </div>
  );
};

const CopilotWithProviders = ({ opened, onClose }) => {
  return (
    <MessagesProvider>
      <Copilot opened={opened} onClose={onClose} />
    </MessagesProvider>
  );
};

export default CopilotWithProviders;
