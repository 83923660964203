import { useEffect, useState } from 'react';
import { useScenarioContext, useTagContext } from '@/shared/providers';
import { mapAggregateActivities } from '@/shared/utils';

/**
 * @param {Array} tagList
 * @param {Boolean} inverse
 * @return {Object}
 */
const useScenarioActivities = (
  { filter, sortKey, sortType, tagList, inverse, useComparison } = {
    sortKey: '__aggregateHours',
    sortType: 'DESC',
    useComparison: false,
  }
) => {
  const {
    includeNested,
    scenario: primary,
    snapshotEntityMap: primaryEntityMap,
    comparisonScenario,
    comparisonScenarioEntityMap,
  } = useScenarioContext();
  const scenario = useComparison ? comparisonScenario : primary;
  const snapshotEntityMap = useComparison
    ? comparisonScenarioEntityMap
    : primaryEntityMap;
  const { tagMap } = useTagContext();

  const [activities, setActivities] = useState();
  const [groups, setGroups] = useState();
  const [unallocated, setUnallocated] = useState();
  const [metrics, setMetrics] = useState();
  const [tags, setTags] = useState();

  useEffect(() => {
    if (!scenario?.entity || !tagMap?.size) {
      return;
    }

    if (tagList) {
      const tags = new Map();

      tagList.forEach((id) => {
        const tag = tagMap.get(id);
        if (tag) {
          tags.set(id, { ...tag });
        }
      });

      setTags(tags);
    }

    const aggregatedActivities = mapAggregateActivities({
      design: snapshotEntityMap,
      filter,
      inverse,
      scenario,
      tagList,
    });

    const { activities, groups, unallocated, ...metricData } =
      aggregatedActivities;

    const sortedActivities = Array.from(activities.values()).sort((a, b) => {
      return b[sortKey] - a[sortKey];
    });

    if (sortType === 'ASC') {
      sortedActivities.reverse();
    }

    setActivities(sortedActivities);

    if (groups?.size) {
      setGroups(
        Array.from(groups?.values()).sort((a, b) => {
          return (
            b.__aggregateHours / b.__total_metrics.hours -
            a.__aggregateHours / a.__total_metrics.hours
          );
        })
      );
    }
    setUnallocated(
      Array.from(unallocated.values()).sort((a, b) => {
        return b.__aggregateHours - a.__aggregateHours;
      })
    );
    setMetrics(metricData);
  }, [
    includeNested,
    scenario?.entity?.uuid,
    scenario?.entity?.__uuid,
    scenario?.entity?.updated_at,
    JSON.stringify(scenario?.entity?.__visible_metrics),
    JSON.stringify(tagList),
    JSON.stringify(filter),
    tagMap,
  ]);

  return {
    tags,
    activities,
    groups,
    unallocated,
    metrics,
  };
};

export default useScenarioActivities;
