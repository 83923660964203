import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { EntityType } from '@/shared/enums';
import { useScenarioContext, useViewModeContext } from '@/shared/providers';
import Stack from '@mui/material/Stack';
import { DesignLevel } from '@/lib/enums';
import Box from '@/components/Box';
import Typography from '@/components/Typography';
import ActiveComparisonCard from './ActiveComparisonCard';
import SelectComparison from './SelectComparison';

export default function ManageComparison() {
  const { scenario, comparisonScenario, loadComparison, cancelComparison } =
    useScenarioContext();
  const { selectId } = useViewModeContext();
  const router = useRouter();

  const { query } = router;
  const comparePath = query.compare;
  const hasActiveComparison = !!comparisonScenario;
  const entityId = scenario?.entity?.uuid ?? scenario?.entity?.__uuid;

  const handleCompare = ({ designId, revisionId }) => {
    // add designId and revisionId as query params
    const compare = `${designId}:${revisionId}`;
    router.push({
      query: {
        ...router.query,
        compare,
      },
    });
  };

  const handleCancel = () => {
    const { pathname, query } = router;
    const updatedQuery = { ...query };
    delete updatedQuery.compare;

    // Replace the URL without reloading the page
    router.replace(
      {
        pathname,
        query: updatedQuery,
      },
      undefined,
      { shallow: true }
    );
  };

  useEffect(() => {
    // load comparison if compare query param exists
    if (comparePath && !hasActiveComparison && scenario) {
      const [designId, revisionId] = comparePath.split(':');

      // use primary scenario entity
      const entityType = scenario.entity.is_manager
        ? EntityType.MANAGER
        : scenario?.entity.__type;
      const entityDetails = {
        designId: scenario.details.designId,
        uuid: scenario.entity.uuid,
        type: entityType,
      };

      loadComparison(designId, revisionId, entityDetails);
    }

    // cancel comparison if compare query param is removed but comparison is active
    if (!comparePath && hasActiveComparison && scenario) {
      cancelComparison();
    }
  }, [comparePath, hasActiveComparison, entityId]);

  // if a group or role scenario then default the navigation to the selected entity
  useEffect(() => {
    if (
      comparisonScenario?.entity &&
      comparisonScenario.entity.__type !== DesignLevel.ORGANISATION
    ) {
      selectId(
        comparisonScenario.entity.__type,
        comparisonScenario.entity.uuid
      );
    }
  }, [comparisonScenario?.entity?.uuid]);

  return (
    <Box>
      {hasActiveComparison && (
        <Stack direction="row" spacing={1}>
          <Typography variant="body2" sx={{ pt: 1 }}>
            Comparing to...
          </Typography>
          <ActiveComparisonCard
            scenario={comparisonScenario}
            onCancelComparison={handleCancel}
          />
        </Stack>
      )}
      {!hasActiveComparison && (
        <SelectComparison current={scenario} onSelect={handleCompare} />
      )}
    </Box>
  );
}
