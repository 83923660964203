import { ActionType } from '@/shared/enums';
import { EntityMetric } from '@/lib/enums';

const METRIC_ORDER = [
  EntityMetric.BUDGET,
  EntityMetric.FTE,
  EntityMetric.HOURS,
  EntityMetric.ACTIVITIES,
  EntityMetric.MOVED,
  EntityMetric.GROUPS,
];

const fallbackMetric = (actionType) => {
  switch (actionType) {
    case ActionType.UPDATE_FTE:
    case ActionType.UPDATED_FTE:
      return EntityMetric.FTE;
    default:
      return EntityMetric.HOURS;
  }
};

export default function determineMetric({
  metrics,
  order = METRIC_ORDER,
  actionType,
  showBudget,
}) {
  for (let i = 0; i < order.length; i++) {
    const metric = order[i];

    if (!showBudget && metric === EntityMetric.BUDGET) {
      continue;
    }

    const metricA = metrics[0]?.[metric] || 0;
    const metricB = metrics[1]?.[metric] || 0;

    if (metricA !== metricB) {
      return metric;
    }
  }

  return fallbackMetric(actionType);
}
