import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import ListSubheader from '@mui/material/ListSubheader';
import OutlinedInput from '@mui/material/OutlinedInput';
import makeStyles from '@mui/styles/makeStyles';
import { Roles } from '@pkg/entities/library';
import { useDebounce } from '@pkg/hooks';
import { color } from '@/lib/theme/tokens';

const useStyles = makeStyles({
  root: {
    width: ({ fullWidth }) => (fullWidth ? '100%' : 'auto'),
  },
  person: {
    cursor: 'pointer',
    display: 'flex',
    padding: 6,
    '&:hover': {
      backgroundColor: color.primary.grey[100],
    },
  },
  avatar: {
    height: 24,
    marginLeft: 2,
    width: 24,
  },
  name: {
    color: color.primary.brand[800],
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '24px',
    margin: 0,
    marginLeft: 8,
  },
  placeholder: {
    color: color.primary.grey[400],
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '24px',
    margin: 0,
    marginLeft: 8,
  },
  selected: {
    backgroundColor: color.primary.grey[100],
    border: `2px solid ${color.primary.brand[200]}`,
    borderRadius: 4,
    cursor: 'pointer',
    display: 'flex',
    padding: 4,
    position: 'relative',
    '&:hover': {
      borderColor: color.system.interactive[300],
    },
  },
  clear: {
    position: 'absolute',
    right: 0,
    top: -2,
  },
  groupHeader: {
    backgroundColor: color.primary.grey[100],
    fontSize: 13,
    lineHeight: '2.5',
    margin: '12px 0 8px',
    '&:first-of-type': {
      marginTop: 0,
    },
  },
});

const RolesInput = ({
  className,
  disableCreation = false,
  fullWidth = false,
  label,
  name,
  onChange,
  value,
}) => {
  const classes = useStyles({ fullWidth });
  const [inputValue, setInputValue] = useState('');
  const [search, setSearch] = useState('');
  const { results } = Roles.useSearch(search);
  const debounceSearch = useDebounce(setSearch, 350);

  const options = useMemo(() => {
    const mapped = [];

    if (!disableCreation && Boolean(inputValue?.length)) {
      mapped.push({
        uuid: 'new',
        label: 'Create new role',
        title: inputValue,
      });
    }

    results.forEach((result) => {
      const title = result.snapshot.entities.roles[0].title;
      const option = {
        uuid: result.uuid,
        label: title,
        snapshot: result.snapshot,
        title,
      };

      if (!disableCreation) {
        option.__group = 'Library roles';
      }

      mapped.push(option);
    });

    return mapped;
  }, [results]);

  const handleBlur = () => {
    setSearch('');
    if (disableCreation) {
      setInputValue(value ?? '');
    }
  };

  const handleChange = (event, value) => {
    onChange?.(event, value);
  };

  const handleSearch = (_, value) => {
    setInputValue(value);
  };

  useEffect(() => {
    if (inputValue !== search) {
      debounceSearch(inputValue);
    }
  }, [inputValue]);

  return (
    <FormControl className={clsx(classes.root, className)}>
      <Autocomplete
        autoComplete
        autoHighlight
        blurOnSelect
        selectOnFocus
        freeSolo
        fullWidth
        filterOptions={(options) => options}
        getOptionLabel={(option) => option?.title ?? option}
        groupBy={({ __group }) => __group}
        handleHomeEndKeys
        inputValue={inputValue}
        isOptionEqualToValue={(a, b) => a?.uuid === b?.uuid}
        loadingText="Searching&hellip;"
        noOptionsText="No results"
        onBlur={handleBlur}
        onChange={handleChange}
        onInputChange={handleSearch}
        options={options}
        readOnly={false}
        renderGroup={({ children, group, key }) => (
          <div key={key}>
            <ListSubheader className={classes.groupHeader}>
              {group}
            </ListSubheader>
            {children}
          </div>
        )}
        renderOption={(props, option) => (
          <li
            data-testid="role-library-option"
            {...props}
            className={classes.person}
          >
            <p className={classes.name}>{option.label}</p>
          </li>
        )}
        renderInput={({ InputLabelProps, InputProps, ...params }) => {
          delete InputProps.className;
          delete params.inputProps.className;

          return (
            <>
              <FormLabel {...InputLabelProps}>{label}</FormLabel>
              <OutlinedInput
                {...InputProps}
                {...params}
                autoFocus
                name={name}
                value={inputValue}
              />
            </>
          );
        }}
        value={value}
      />
    </FormControl>
  );
};

RolesInput.propTypes = {
  className: PropTypes.string,
  disableCreation: PropTypes.bool,
  enabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.any,
};

export default RolesInput;
