import { useState, useEffect } from 'react';
import { entityChart } from '@/organisms/charts';
import { ChartRowType } from '@/shared/enums';
import { useAggregateEntities } from '@/shared/hooks';

const useAggregateChartData = ({
  entityType,
  excludeSeparators,
  excludeUnmanaged,
  includeEmptySpans,
  includeStack,
  dimension,
  filter,
  sort,
  useComparison,
  onlyComparison = false,
}) => {
  const [chartData, setChartData] = useState();
  const [metricTotals, setMetricTotals] = useState();

  const { entities: aggregateEntities, comparisonColor } = useAggregateEntities(
    {
      entityType,
      excludeUnmanaged,
      includeEmptySpans,
      order: dimension.order,
      filter,
      sort,
      useComparison,
    }
  );

  const index = onlyComparison ? 1 : 0;
  const entities = aggregateEntities?.[index];

  useEffect(() => {
    if (!entities?.metrics) {
      setChartData([]);
      return;
    }

    const chartData = entityChart({
      excludeSeparators,
      entities,
      includeStack,
      sort,
    });

    const totals = entities.metrics;

    if (useComparison && !onlyComparison) {
      const comparisonEntities = aggregateEntities[1];

      const comparisonChartData = entityChart({
        excludeSeparators,
        entities: comparisonEntities,
        includeStack,
        sort,
        returnMap: true,
      });

      // @todo move this correlation of primary to comparison data earlier
      // useAggregateEntities / mapEntities could return objects with comparison datasets included
      chartData.forEach((row) => {
        if (row.type === ChartRowType.TITLE) {
          return;
        }

        // find matching row in comparison data
        const comparisonRow = comparisonChartData.get(row.id);
        row.comparison = comparisonRow
          ? {
              ...comparisonRow,
              color: comparisonColor,
            }
          : {};
      });

      totals.comparison = comparisonEntities.metrics;
    }

    setChartData(chartData);
    setMetricTotals(totals);
  }, [entities?.metrics, JSON.stringify(sort)]);

  return {
    chartData,
    metricTotals,
  };
};

export default useAggregateChartData;
