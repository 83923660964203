import { DesignEntity } from '@/lib/enums';
import getFlattened from './getFlattened';
import getKeyed from './getKeyed';

/**
 * Default properties for stepping through the hierarchy tree.
 *
 * @return {Object}
 */
function defaultTreePathStep() {
  return {
    uuid: null,
    type: null,
    order: Infinity,
  };
}

/**
 * Default properties for entity maps.
 *
 * @return {Object}
 */
function defaultEntityMap() {
  return {
    [DesignEntity.GROUP]: new Set(),
    [DesignEntity.MANAGER]: new Set(),
    [DesignEntity.ROLE]: new Set(),
    [DesignEntity.ACTIVITY]: new Set(),
  };
}

/**
 * Extends an entity with additional hierarchy properties.
 *
 * @param {Object} entity
 *
 * @return {Object}
 */
function extendEntityProperties(entity) {
  /** The activities that belong to this entity */
  entity.__activities = new Set();
  /** The direct children of this node in the tree hierarchy */
  entity.__children = new Map();
  /** The closest manager above this node in the tree hierarchy */
  entity.__manager = null;
  /** The direct parent of this node in the tree hierarchy */
  entity.__parent = null;
  /** The path of tree steps to this node from its root */
  entity.__path = new Map();
  /** Splits out the path by types */
  entity.__above = defaultEntityMap();
  /** Groups: All entities within this group, inclusive of nested */
  /** Roles: All entities that branch from this role, inclusive of sub-managers */
  entity.__managed = defaultEntityMap();
  /** Groups: Splits out children by type */
  /** Roles: Splits out children by type, including nested groups lead and roles parented */
  entity.__direct = defaultEntityMap();
  /** Groups: All entities within this group, exclusive of nested */
  /** Roles: All entities in the branches between this and inclusive of the next manager */
  entity.__span = defaultEntityMap();

  return entity;
}

/**
 * Extends an activity with additional hierarchy properties.
 *
 * @param {Object} activity
 *
 * @return {Object}
 */
function extendActivityPropeerties(activity) {
  /** The group of the owner of this activity */
  activity.__group = null;
  /** The manager of the owner of this activity */
  activity.__manager = null;
  /** The path of tree steps to this node from its root */
  activity.__path = null;

  return activity;
}

/**
 * Iterate through the hierarchy to set manager and path
 * @param {Object} keyed
 * @param {String} id
 * @param {DesignEntity} type
 * @param {String} [managerId]
 * @param {Map} [path]
 * @returns {void}
 */
function iterateHierarchy(keyed, id, type, managerId = null, path = new Map()) {
  const plural = DesignEntity.toPlural(type);
  const entity = keyed.entities[plural][id];

  // set entity hierarchy
  entity.__manager = managerId;
  entity.__path = new Map(path);

  // prepare next
  const nextManagerId = entity.is_manager ? id : managerId;
  const nextPath = new Map(path);
  const nextStep = defaultTreePathStep();
  nextStep.uuid = id;
  nextStep.type = type;
  nextStep.order = nextPath.size;

  nextPath.set(id, nextStep);

  // iterate children
  const entityChildren = Array.from(entity.__children.entries());

  entityChildren.forEach(([id, type]) => {
    iterateHierarchy(keyed, id, type, nextManagerId, nextPath);
  });
}

/**
 * Map hierarchy to a snapshot, optionally flattening it
 * @param {Object} snapshot
 * @param {Boolean} [flatten]
 * @returns {?Object}
 */
export default function getHierarchy(snapshot, flatten) {
  if (!snapshot) {
    return null;
  }

  console.time('Snapshots.utils.getHierarchy');
  const keyed = structuredClone(getKeyed(snapshot));

  // setup hierarchy objects
  keyed.__roots = new Map();

  // Set the default entity hierarchy properties on the group.
  keyed.__keys.groups.forEach((id) => {
    keyed.entities.groups[id] = extendEntityProperties(
      keyed.entities.groups[id]
    );
  });

  // Set the default entity hierarchy properties on the role.
  keyed.__keys.roles.forEach((id) => {
    keyed.entities.roles[id] = extendEntityProperties(keyed.entities.roles[id]);
  });

  // Set the default hierarchy properties on the activity.
  keyed.__keys.activities.forEach((id) => {
    keyed.entities.activities[id] = extendActivityPropeerties(
      keyed.entities.activities[id]
    );
  });

  // loop through groups for parents and children
  keyed.__keys.groups.forEach((id) => {
    const group = keyed.entities.groups[id];
    const outer = keyed.entities.groups[group.group_uuid];
    const lead = keyed.entities.roles[group.lead_uuid];

    const leadId = group.lead_uuid ?? null;
    const leadGroupId = lead?.group_uuid ?? null;
    const outerId = group.group_uuid ?? null;

    const hasLead = Boolean(lead);
    const hasOuter = Boolean(outer);
    const isLeadInGroup = hasLead && leadGroupId === outerId;

    // set hierarchy
    switch (true) {
      case isLeadInGroup:
        group.__parent = { uuid: leadId, type: DesignEntity.ROLE };
        lead.__children.set(id, DesignEntity.GROUP);
        return;

      case hasOuter:
        group.__parent = { uuid: outerId, type: DesignEntity.GROUP };
        outer.__children.set(id, DesignEntity.GROUP);
        return;

      default:
        return keyed.__roots.set(id, DesignEntity.GROUP);
    }
  });

  // loop through roles for parents and children
  keyed.__keys.roles.forEach((id) => {
    const role = keyed.entities.roles[id];
    const group = keyed.entities.groups[role.group_uuid];
    const parent = keyed.entities.roles[role.parent_uuid];

    const groupId = role.group_uuid ?? null;
    const parentId = role.parent_uuid ?? null;
    const parentGroupId = parent?.group_uuid ?? null;

    const hasGroup = Boolean(group);
    const hasParent = Boolean(parent);
    const isParentInGroup = hasParent && parentGroupId === groupId;

    // set hierarchy
    switch (true) {
      case isParentInGroup:
        role.__parent = { uuid: parentId, type: DesignEntity.ROLE };
        parent.__children.set(id, DesignEntity.ROLE);
        return;

      case hasGroup:
        role.__parent = { uuid: groupId, type: DesignEntity.GROUP };
        group.__children.set(id, DesignEntity.ROLE);
        return;

      default:
        return keyed.__roots.set(id, DesignEntity.ROLE);
    }
  });

  // iterate through hierarchy for path and manager
  try {
    const roots = Array.from(keyed.__roots.entries());
    roots.forEach(([id, type]) => {
      iterateHierarchy(keyed, id, type);
    });
  } catch (e) {
    console.debug('Error iterating __roots map in getHierarchy ', keyed);
    console.error(e);
  }

  // loop through groups for above, managed, direct, and spans
  keyed.__keys.groups.forEach((id) => {
    const group = keyed.entities.groups[id];
    const lead = keyed.entities.roles[group.lead_uuid] ?? null;
    const outer = keyed.entities.groups[group.group_uuid] ?? null;
    const manager = keyed.entities.roles[group.__manager] ?? null;

    const managerId = group.__manager ?? null;
    const isManagerInGroup = manager?.group_uuid === group.group_uuid;

    lead?.__direct[DesignEntity.GROUP].add(group.uuid);
    outer?.__span[DesignEntity.GROUP].add(group.uuid);
    manager?.__span[DesignEntity.GROUP].add(group.uuid);

    const childGroups = Array.from(group.__children.entries());
    childGroups.forEach(([childId, childType]) => {
      group.__direct[childType].add(childId);
    });

    const groupPath = Array.from(group.__path.values());
    groupPath.forEach(({ uuid, type }) => {
      const plural = DesignEntity.toPlural(type);
      const entity = keyed.entities[plural][uuid];
      entity.__managed[DesignEntity.GROUP].add(group.uuid);
      group.__above[type].add(uuid);
    });
  });

  // loop through roles for above, managed, direct, and spans
  keyed.__keys.roles.forEach((id) => {
    const role = keyed.entities.roles[id];
    const parent = keyed.entities.roles[role.parent_uuid] ?? null;
    const group = keyed.entities.groups[role.group_uuid] ?? null;
    const manager = keyed.entities.roles[role.__manager] ?? null;

    const groupId = role.group_uuid ?? null;
    const managerId = role.__manager ?? null;
    const managerGroupId = manager?.group_uuid ?? null;
    const hasManager = Boolean(manager);
    const isManagerInGroup = hasManager && managerGroupId === groupId;

    parent?.__direct[DesignEntity.ROLE].add(role.uuid);
    group?.__span[DesignEntity.ROLE].add(role.uuid);
    manager?.__span[DesignEntity.ROLE].add(role.uuid);

    if (role.is_manager) {
      group?.__span[DesignEntity.MANAGER].add(role.uuid);
      manager?.__span[DesignEntity.MANAGER].add(role.uuid);
    }

    const roleChildren = Array.from(role.__children.entries());

    roleChildren.forEach(([childId, childType]) => {
      role.__direct[childType].add(childId);

      const plural = DesignEntity.toPlural(childType);
      const child = keyed.entities[plural][childId];
      if (child.is_manager) {
        role.__direct[DesignEntity.MANAGER].add(childId);
      }
    });

    const rolePath = Array.from(role.__path.values());

    rolePath.forEach(({ uuid, type }) => {
      const plural = DesignEntity.toPlural(type);
      const above = keyed.entities[plural][uuid];

      above.__managed[DesignEntity.ROLE].add(role.uuid);
      if (above.is_manager) {
        role.__above[DesignEntity.MANAGER].add(uuid);
      }

      role.__above[type].add(uuid);
      if (role.is_manager) {
        above.__managed[DesignEntity.MANAGER].add(role.uuid);
      }
    });
  });

  // loop through activities to set paths
  const activityPaths = new Map();
  keyed.__keys.activities.forEach((id) => {
    const activity = keyed.entities.activities[id];

    const ownerPlural = DesignEntity.toPlural(activity.owner_type);
    const owner = keyed.entities[ownerPlural][activity.owner_uuid];
    owner?.__activities.add(id);

    const manager = keyed.entities.roles[owner?.__manager] ?? null;
    manager?.__span[DesignEntity.ACTIVITY].add(id);

    const parent = keyed.entities.roles[owner?.__parent?.uuid] ?? null;
    parent?.__direct[DesignEntity.ACTIVITY].add(id);

    activity.__group = owner?.group_uuid ?? null;
    activity.__manager = owner?.__manager ?? null;

    // set path
    switch (true) {
      case !owner:
        activity.__path = new Map();
        break;

      case activityPaths.has(owner.uuid):
        activity.__path = new Map(activityPaths.get(owner.uuid));
        break;

      default:
        const ownerStep = defaultTreePathStep();
        ownerStep.uuid = activity.owner_uuid;
        ownerStep.type = activity.owner_type;
        ownerStep.order = owner.__path.size;

        activity.__path = new Map(owner.__path);
        activity.__path.set(owner.uuid, ownerStep);
        activityPaths.set(owner.uuid, new Map(activity.__path));
    }

    // iterate path
    const activityPath = Array.from(activity.__path.values());

    activityPath.forEach(({ uuid, type }) => {
      const plural = DesignEntity.toPlural(type);
      const entity = keyed.entities[plural][uuid];
      entity.__managed[DesignEntity.ACTIVITY].add(id);
    });
  });

  // optionally flatten, then freeze and return
  const frozen = Object.freeze(flatten ? getFlattened(keyed) : keyed);

  console.timeEnd('Snapshots.utils.getHierarchy');
  return frozen;
}
