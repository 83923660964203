import { Snapshots } from '@pkg/entities';
import { Collections } from '@pkg/utils';

/**
 * @param {Object} snapshot
 * @param {Object} input
 * @param {String} input.uuid
 * @param {String} input.new_uuid
 * @returns {Array}
 */
export default function duplicate(snapshot, { uuid, new_uuid }) {
  const now = Date.now();
  const activityMutation = Snapshots.reducers.activities.duplicate(snapshot, {
    uuid,
    new_uuid,
  });

  const entities = snapshot.entities;
  const activity = Collections.findById(entities.activities, uuid);
  const role = Collections.findById(entities.roles, activity.owner_uuid);
  const roleActivities = Collections.where(
    entities.activities,
    'owner_uuid',
    role.uuid
  ).concat(activityMutation.entities.activities.create[0]);

  const updatedSnapshot = {
    entities: {
      activities: roleActivities,
      roles: [{ ...role, updated_at: now }],
    },
  };

  return {
    uuid: role.__library_uuid,
    snapshot: updatedSnapshot,
  };
}
