import { defaultMetrics } from '@/organisms/plans';
import { ActionType, EntityType } from '@/shared/enums';
import { entityLabel } from '@/shared/utils';

export default function removedRolesAction({
  actionMap,
  comparisonScenario,
  role,
  scenario,
}) {
  const group = role
    ? comparisonScenario?.relationships
        .get(EntityType.GROUP)
        ?.get(role.group_uuid)
    : null;

  const metrics = {
    ...role.__metrics.self.visible,
    groups: group ? 1 : 0,
  };

  const existingAction = actionMap?.get(ActionType.REMOVE_ROLES);

  const action = existingAction ?? {
    entity: EntityType.ROLE,
    metrics: defaultMetrics({ comparisonScenario }),
    type: ActionType.REMOVE_ROLES,
    [EntityType.ROLE]: new Map(),
    [EntityType.GROUP]: new Set(),
  };

  if (group) {
    action[EntityType.GROUP].add(group.uuid);
  }

  action.metrics[1].activities -= metrics.activities;
  action.metrics[1].budget -= metrics.budget;
  action.metrics[1].fte -= metrics.fte;
  action.metrics[1].hours -= metrics.hours;
  action.metrics[1].roles -= 1;

  // Aggregate the individual role action metrics.
  const actionRole = action[EntityType.ROLE].get(role.uuid) ?? {
    metrics: defaultMetrics({ comparisonScenario, role }),
  };
  actionRole.metrics[1].activities -= metrics.activities;
  actionRole.metrics[1].budget -= metrics.budget;
  actionRole.metrics[1].fte -= metrics.fte;
  actionRole.metrics[1].hours -= metrics.hours;
  actionRole.metrics[1].roles -= 1;
  action[EntityType.ROLE].set(role.uuid, actionRole);

  const roleCount = action[EntityType.ROLE].size;
  const groupCount = action[EntityType.GROUP].size;

  const roleLabel = entityLabel(EntityType.ROLE, roleCount !== 1);
  const groupLabel = entityLabel(EntityType.GROUP, groupCount !== 1);
  const verb = roleCount === 1 ? 'was' : 'were';
  const adverb = groupCount === 1 ? ', impacting' : ' across';

  action.title = `${roleCount} ${roleLabel} ${verb} removed`;

  if (groupCount > 0) {
    action.title = `${action.title}${adverb} ${groupCount} ${groupLabel}`;
  }

  return action;
}
