import PropTypes from 'prop-types';
import { memo, useState } from 'react';
import LoadingButton from '@pkg/forms/LoadingButton';
import Box from '@/components/Box';
import Button from '@/components/Button';
import Fade from '@/components/Fade';
import ConfirmBar from './ConfirmBar';
import ConfirmContent from './ConfirmContent';

const ConfirmedButton = ({
  cancel = 'Cancel',
  children,
  color,
  confirm = 'Confirm',
  onConfirm = () => {},
  subtext,
  title = 'Are you sure?',
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleConfirm = async () => {
    if (loading) {
      return;
    }

    setLoading(true);
    await onConfirm();
    setLoading(false);
    setOpen(false);
  };

  return (
    <>
      <Button {...props} onClick={() => setOpen(true)} color={color}>
        {children}
      </Button>
      <Fade in={open}>
        <ConfirmBar>
          <ConfirmContent>
            {title && <h3>{title}</h3>}
            {subtext && <p>{subtext}</p>}
            <Box align="center">
              <Button
                disabled={loading}
                onClick={() => setOpen(false)}
                variant="secondary"
              >
                {cancel}
              </Button>
              <LoadingButton
                color={color}
                loading={loading}
                onClick={handleConfirm}
                sx={{ ml: 1 }}
                variant="primary"
              >
                {confirm}
              </LoadingButton>
            </Box>
          </ConfirmContent>
        </ConfirmBar>
      </Fade>
    </>
  );
};

ConfirmedButton.propTypes = {
  onConfirm: PropTypes.func,
  title: PropTypes.string,
  subtext: PropTypes.string,
  confirm: PropTypes.string,
  cancel: PropTypes.string,
};

export default memo(ConfirmedButton);
