/**
 * Recursive calculation of nodes with consideration for groups.
 *
 * @param {Object} node
 * @param {Object} column
 * @param {Number} total
 *
 * @return {Number}
 */
const sumColumnValues = ({ node, column, idColumn, idSet, total }) => {
  const id = node.getValueFromValueService(idColumn);
  const isIncluded = idSet.has(id);

  if (node.group) {
    node.childrenAfterFilter.forEach((childNode) => {
      total = sumColumnValues({
        node: childNode,
        column,
        idColumn,
        idSet,
        total,
      });
    });
  }

  if (isIncluded) {
    return total;
  }

  if (!id) {
    return total;
  }

  idSet.add(id);
  const value = node.getValueFromValueService(column);

  if (value === null || typeof value === 'undefined') {
    return total;
  }

  if (typeof value === 'number') {
    return total + value;
  }

  return total + (value?.raw ?? 0);
};

export default sumColumnValues;
