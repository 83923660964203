import { RowColors } from '@/organisms/charts';

const COMPARISON_BAR_WIDTH = 8;
const COMPARISON_BAR_PADDING = 4;
const COMPARISON_BAR_X_OFFSET = 32;

const VerticalBar = ({
  barWidth,
  chartHeight,
  metric,
  minBarSize = 8,
  padding = 8,
  row,
  stackData,
  xPosition,
  yPosition,
  yComparison,
}) => {
  const height = yPosition > minBarSize ? yPosition : minBarSize;
  const rowType = row.type;
  const colors = RowColors[rowType]?.bar;

  const hasComparison = yComparison > 0;
  const comparisonColor = row.comparison?.color;

  const adjustedBarWidth = hasComparison
    ? barWidth - COMPARISON_BAR_WIDTH - COMPARISON_BAR_PADDING
    : barWidth;

  return (
    <g className="vertical-bar">
      <rect
        width={adjustedBarWidth - padding}
        height={height - 2}
        rx={4}
        ry={4}
        x={xPosition}
        y={chartHeight - height + 4}
        fill={colors.borderColor}
      />
      <rect
        width={adjustedBarWidth - padding}
        height={height}
        rx={3}
        ry={3}
        x={xPosition}
        y={chartHeight - height}
        fill={colors.backgroundColor}
      />
      {hasComparison && (
        <rect
          width={COMPARISON_BAR_WIDTH}
          height={yComparison - 3}
          rx={3}
          ry={3}
          x={xPosition + COMPARISON_BAR_X_OFFSET}
          y={chartHeight - yComparison + 4}
          fill={comparisonColor?.fill}
        />
      )}
    </g>
  );
};

export default VerticalBar;
