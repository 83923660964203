import { useEffect, useState } from 'react';
import {
  ActivityDimensions,
  DimensionSelect,
  MetricTypeSelect,
} from '@/molecules/chartElements';
import { ActivityBarChartInformation } from '@/molecules/information';
import { ChartCard } from '@/organisms/cards';
import { isPercentageMetric, BarChart } from '@/organisms/charts';
import { EmptyInsights } from '@/organisms/insights';
import { useAggregateChartData } from '@/organisms/insights';
import { EntityType } from '@/shared/enums';
import { useEntityNavigation } from '@/shared/hooks';
import { useInsightsContext, useViewModeContext } from '@/shared/providers';
import Stack from '@mui/material/Stack';
import { EntityMetric } from '@/lib/enums';

const ActivitiesChart = ({ levelType, showComparison }) => {
  const [metric, setMetric] = useState(EntityMetric.ENTITY_PERCENTAGE);
  const [dimension, setDimension] = useState(
    ActivityDimensions[levelType]?.[0]
  );
  const [sort, setSort] = useState({
    metric: 'hours',
    type: 'DESC',
  });
  const { filter } = useInsightsContext();
  const { showBudget } = useViewModeContext();
  const { navigateToEntity } = useEntityNavigation();

  const { chartData, metricTotals } = useAggregateChartData({
    dimension,
    filter,
    sort,
    useComparison: showComparison,
  });

  const hasActivities = metricTotals?.activities > 0;

  const handleDimensionChange = (dimension) => {
    setDimension(dimension);
  };

  const handleMetricTypeChange = (metric) => {
    setMetric(metric.id);
    setSort({
      ...sort,
      metric: isPercentageMetric(metric.id) ? EntityMetric.HOURS : metric.id,
    });
  };

  // Handles double click navigation for person rows.
  const handleNavigate = (row) => {
    if (!row) {
      return;
    }

    navigateToEntity({
      type: EntityType.ROLE,
      uuid: row.props?.role_uuid,
    });
  };

  // This effect resets the chart if it's showing metric data and budgets have
  // been switched off.
  useEffect(() => {
    if (!showBudget && metric === EntityMetric.BUDGET) {
      setMetric(EntityMetric.ENTITY_PERCENTAGE);
    }
  }, [showBudget]);

  return (
    <ChartCard
      title="Where time is spent"
      ControlsComponent={
        hasActivities && (
          <Stack direction="row" alignItems="center" spacing={2}>
            <DimensionSelect
              dimensions={ActivityDimensions[levelType]}
              label="Activity by"
              onChange={handleDimensionChange}
            />
            <MetricTypeSelect onChange={handleMetricTypeChange} />
          </Stack>
        )
      }
      InfoComponent={<ActivityBarChartInformation />}
    >
      {hasActivities ? (
        <BarChart
          id="activity-insights"
          chartData={chartData}
          metric={metric}
          metricTotals={metricTotals}
          onNavigate={handleNavigate}
          sort={sort}
        />
      ) : (
        <EmptyInsights message="No activities were found." />
      )}
    </ChartCard>
  );
};

export default ActivitiesChart;
