import { Entity as EntityColors } from '@/atoms/colors';
import { EntityType } from '@/shared/enums';
import { EntityMetric } from '@/lib/enums';

export default function metricColor(metric, entityType) {
  switch (metric) {
    case EntityMetric.BUDGET:
    case EntityMetric.FTE:
      return EntityColors[entityType];
    case EntityMetric.ROLES:
    case EntityType.ROLE:
      return EntityColors[EntityType.ROLE];
    case EntityMetric.GROUPS:
    case EntityType.GROUP:
      return EntityColors[EntityType.GROUP];
    default:
      return EntityColors[EntityType.ACTIVITY];
  }
}
