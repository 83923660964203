import { tool } from 'ai';
import { z } from 'zod';

const navigateTo = tool({
  description:
    'Generates a URL to navigate to specific views in the application',
  parameters: z.object({
    url: z.string().describe('The URL to navigate to'),
    explanation: z
      .string()
      .describe('Explanation of where this URL will navigate to'),
  }),
  execute: async ({ url, explanation }) => ({
    url,
    explanation,
  }),
});

export default navigateTo;
