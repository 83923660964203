import { defaultMetrics } from '@/organisms/plans';
import { ActionType, EntityType } from '@/shared/enums';
import { entityLabel } from '@/shared/utils';

export default function removedActivitiesAction({
  actionMap,
  activity,
  comparisonScenario,
}) {
  const role =
    activity.owner_type === EntityType.ROLE
      ? comparisonScenario.relationships
          .get(EntityType.ROLE)
          ?.get(activity.owner_uuid)
      : null;
  const group = role
    ? comparisonScenario?.relationships
        .get(EntityType.GROUP)
        ?.get(role.group_uuid)
    : comparisonScenario?.relationships
        .get(EntityType.GROUP)
        ?.get(activity.owner_uuid);

  const metrics = {
    hours: activity.hours,
  };

  const existingAction = actionMap?.get(ActionType.REMOVE_ACTIVITIES);

  const action = existingAction ?? {
    entity: EntityType.ACTIVITY,
    type: ActionType.REMOVE_ACTIVITIES,
    metrics: defaultMetrics({ comparisonScenario }),
    [EntityType.ACTIVITY]: new Map(),
    [EntityType.ROLE]: new Set(),
    [EntityType.MANAGER]: new Set(),
    [EntityType.GROUP]: new Set(),
  };

  if (role) {
    action[EntityType.ROLE].add(role.uuid);
  }
  if (group) {
    action[EntityType.GROUP].add(group.uuid);
  }

  action.metrics[1].activities -= 1;
  action.metrics[1].hours -= activity.hours;

  // Aggregate the individual activity action metrics.
  const actionActivity = action[EntityType.ACTIVITY].get(activity.uuid) ?? {
    metrics: defaultMetrics({ action, activity, comparisonScenario }),
  };
  actionActivity.metrics[1].activities -= 1;
  actionActivity.metrics[1].hours -= activity.hours;
  action[EntityType.ACTIVITY].set(activity.uuid, actionActivity);

  const activityCount = action[EntityType.ACTIVITY].size;
  const roleCount = action[EntityType.ROLE].size;

  const roleLabel = entityLabel(EntityType.ROLE, roleCount !== 1);
  const activityLabel = entityLabel(EntityType.ACTIVITY, activityCount !== 1);
  const verb = activityCount === 1 ? 'was' : 'were';
  const adverb = roleCount === 1 ? 'from' : 'across';

  action.title = `${activityCount} ${activityLabel} ${verb} removed across ${roleCount} ${roleLabel}`;

  return action;
}
