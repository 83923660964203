import { Variant } from '@/atoms/enums';
import metricColor from '@/shared/utils/colors/metricColor';
import { styled } from '@mui/styles';
import { percent } from '@pkg/utils/numbers';
import { DesignEntity } from '@/lib/enums';
import excludeProps from '@/lib/theme/excludeProps';

const BarBackground = styled('div', {
  shouldForwardProp: excludeProps(['color', 'height', 'variant']),
})(({ color, height, variant }) => {
  return {
    width: '100%',
    height: height,
    backgroundColor: variant === Variant.OUTLINE ? 'transparent' : color,
    borderRadius: '3px',
    boxShadow: variant === Variant.OUTLINE ? `0 0 0 1px ${color}` : 'none',
    position: 'relative',
    overflow: 'hidden',
  };
});

const BarForeground = styled('div', {
  shouldForwardProp: excludeProps(['color', 'percentage']),
})(({ color, percentage }) => ({
  display: 'block',
  width: percentage > 100 ? '100%' : `${percentage}%`,
  height: '100%',
  backgroundColor: color,
  borderTopLeftRadius: '3px',
  borderBottomLeftRadius: '3px',
  position: 'absolute',
  transition: 'width 300ms',
  zIndex: 1,
}));

const PercentageBar = ({
  colors = metricColor(DesignEntity.ROLE),
  numerator,
  denominator,
  height = 2,
  variant = Variant.CONTAINED,
  width = '100%',
}) => {
  const percentage = denominator > 0 ? percent(numerator, denominator) : 0;
  return (
    <BarBackground
      color={colors.secondary}
      height={height}
      width={width}
      variant={variant}
    >
      <BarForeground color={colors.primary} percentage={percentage} />
    </BarBackground>
  );
};

export default PercentageBar;
