import { useMutation } from '@pkg/graphql';

const DELETE_LIBRARY_ROLE = /* GraphQL */ `
  mutation DeleteLibraryRole($input: DeleteLibraryRoleInput!) {
    deleteLibraryRole(input: $input) {
      uuid
    }
  }
`;

/**
 * @return {Function}
 */
export default function useDelete() {
  const { mutateAsync } = useMutation({
    mutation: DELETE_LIBRARY_ROLE,
  });

  /**
   * @param {String} organisation_uuid
   * @param {Object} input
   * @param {String} input.uuid
   * @returns {Promise}
   */
  async function deleteLibraryRole(organisation_uuid, { uuid }) {
    return mutateAsync({ organisation_uuid, uuid });
  }

  return deleteLibraryRole;
}
