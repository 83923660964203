import { FillCard } from '@/organisms/cards';
import { useScenarioContext } from '@/shared/providers/ScenarioProvider';
import { DesignEntity } from '@/lib/enums';
import TeamMemberList from './TeamMemberList';

const TeamMemberListCard = ({ allowLink = false }) => {
  const { scenario } = useScenarioContext();

  const hasGroup =
    scenario?.entity?.__type === DesignEntity.GROUP ||
    scenario?.relationships
      ?.get(DesignEntity.GROUP)
      ?.has(scenario?.entity?.group_uuid);

  return (
    hasGroup && (
      <FillCard>
        <TeamMemberList allowLink={allowLink} />
      </FillCard>
    )
  );
};

export default TeamMemberListCard;
