import { useMemo } from 'react';
import { useQuery } from '@pkg/graphql';

const SEARCH_LIBRARY_ROLES = /* GraphQL */ `
  query LibraryRolesSearch($search: String) {
    libraryRoles(query: $search) {
      data {
        uuid
        enabled
        snapshot {
          entities {
            roles {
              uuid
              title
              fte
              tags
            }
            activities {
              uuid
              library_uuid
              owner_uuid
              owner_type
              order
              hours
              tags
            }
          }
        }
      }
    }
  }
`;

/**
 * @param {String} search
 * @param {Boolean} enabled
 */
export default function useSearch(search, enabled = true) {
  const query = [SEARCH_LIBRARY_ROLES, { search }];
  const { data, dataUpdatedAt, isLoading } = useQuery({ query, enabled });

  return useMemo(() => {
    return {
      results: data?.libraryRoles?.data ?? [],
      isLoading,
    };
  }, [dataUpdatedAt]);
}
