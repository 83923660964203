import openai from '../client';
import { toolsConfig, getTools } from '../tools/config';
import lastMessagesSlice from './slices/lastMessagesSlice';
import orgSlice from './slices/orgSlice';
import systemSlice from './slices/systemSlice';

const mutationsPrompt = async ({
  userText,
  snapshot,
  tags,
  lastMessages,
  intentSnapshot,
}) => {
  const mutationPrompt = `
    ${systemSlice()}

    ${orgSlice({ snapshot, tags, intentSnapshot })}

    You MUST use one of the following tools to make changes. DO NOT just confirm or acknowledge the request.

    Available Tools and Their Rules:

    ${Object.entries(toolsConfig)
      .filter(([_, config]) => config.reducer !== null)
      .reduce((acc, [key, config]) => {
        return (
          acc +
          `
    ${config.title} (${config.category}):
    ${config.description}
    ${config.prompt}
    `
        );
      }, '')}

    General Rules:
    1. ALWAYS use one of the available tools to make changes
    2. NEVER just confirm or acknowledge the request
    3. If the request cannot be handled by the available tools, explain why and what tools would be needed
    4. Use industry standard values for unspecified fields:
       - Default fte: 1.0
       - Default budget: 100000
       - Default hours: 0 unless specified
    5. Keep group_uuid as null unless explicitly specified

    ${lastMessagesSlice({ lastMessages })}

    User request: ${userText}

    Respond by using the appropriate tool to implement the requested changes. When creating activities, ensure each one is a specific, actionable task described in approximately 15 words.
  `;
  console.log({ mutationPrompt });
  const prompt = {
    model: openai('gpt-4-turbo'),
    tools: getTools(),
    prompt: mutationPrompt,
  };

  return prompt;
};

export default mutationsPrompt;
