import { defaultMetrics } from '@/organisms/plans/utils';
import { ActionType, EntityType } from '@/shared/enums';

export default function updatedHours({
  activity,
  comparisonScenario,
  originalActivity,
  role,
}) {
  const action = {
    type: ActionType.UPDATED_HOURS,
    [EntityType.ACTIVITY]: new Map([
      [
        activity.uuid,
        {
          metrics: {
            hours: activity.hours,
          },
        },
      ],
    ]),
    metrics: defaultMetrics({ comparisonScenario }),
  };

  const hours = activity.hours - originalActivity.hours;

  action.metrics[1].hours += hours;

  return action;
}
