import { Snapshots } from '@pkg/entities';
import { Collections } from '@pkg/utils';
import { DesignEntity } from '@/lib/enums';

/**
 * @param {Object} snapshot
 * @param {Object} input
 * @param {String} input.uuid
 * @param {String} input.groupId
 * @param {Number} input.leadId
 * @returns {Object}
 */
export default function move(snapshot, { ids, groupId, leadId, ...input }) {
  const now = Date.now();
  const mutation = {
    created_at: now,
    entities: {
      groups: {
        update: [],
      },
      roles: {
        update: [],
      },
    },
  };

  const keyed = Snapshots.utils.getHierarchy(snapshot);
  const groupRoles = Collections.groupBy(snapshot.entities.roles, 'group_uuid');
  const groupIds = new Set();

  const nextGroup = keyed.entities.groups[groupId] ?? null;
  const nextGroupId = groupId && groupId !== 'null' ? groupId : null;

  const nextLead = keyed.entities.roles[leadId] ?? null;
  const nextLeadId = nextLead?.uuid ?? nextGroup?.lead_uuid ?? null;

  // only real ids
  ids.forEach((id) => {
    if (Object.hasOwn(keyed.entities.groups, id)) {
      groupIds.add(id);
    }
  });

  // iterate group moves
  const iterated = new Set();
  const iterateInnerGroups = (id) => {
    const group = keyed.entities.groups[id];
    const update = { uuid: id, updated_at: now };
    let isDifferent = false;

    if (iterated.has(id)) {
      return;
    } else {
      iterated.add(id);
    }

    if (groupIds.has(id) && group.group_uuid !== nextGroupId) {
      update.group_uuid = nextGroupId;
      isDifferent = true;
    }

    if (group.lead_uuid !== nextLeadId) {
      update.lead_uuid = nextLeadId;
      isDifferent = true;
    }

    Object.keys(input).forEach((key) => {
      if (Object.hasOwn(group, key) && group[key] !== input[key]) {
        update[key] = input[key];
        isDifferent = true;
      }
    });

    if (isDifferent) {
      mutation.entities.groups.update.push(update);
    }

    // iterate roots to update lead id
    const roots = (groupRoles[id] ?? []).filter((role) => {
      return role.parent_uuid === group.lead_uuid;
    });

    if (roots.length) {
      return roots.forEach((role) => {
        if (role.parent_uuid !== nextLeadId) {
          mutation.entities.roles.update.push({
            uuid: role.uuid,
            parent_uuid: nextLeadId,
            updated_at: now,
          });
        }
      });
    }

    // iterate inner groups if no root roles found
    group.__span[DesignEntity.GROUP].forEach((innerId) => {
      if (!groupIds.has(innerId)) {
        iterateInnerGroups(innerId);
      }
    });
  };

  groupIds.forEach((id) => iterateInnerGroups(id));

  // remove unused mutations
  let isDifferent = false;
  Object.keys(mutation.entities).forEach((type) => {
    Object.keys(mutation.entities[type]).forEach((action) => {
      if (mutation.entities[type][action].length > 0) {
        isDifferent = true;
      } else {
        delete mutation.entities[type][action];
      }
    });
  });

  return isDifferent ? mutation : null;
}
