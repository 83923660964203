import { useEffect, useState } from 'react';
import { PersonRoleSummary } from '@/molecules/summaries';
import { useScenarioContext } from '@/shared/providers/ScenarioProvider';
import { ucfirst } from '@pkg/utils/strings';
import { DesignEntity } from '@/lib/enums';
import Box from '@/components/Box';
import Typography from '@/components/Typography';

const DEFAULT_COLORS = Object.freeze({
  font: '#4d4d68',
  border: '#deecf7',
});

const RelatedEntityListChart = ({
  entity,
  colors = DEFAULT_COLORS,
  entityType = DesignEntity.ROLE,
}) => {
  const { snapshotEntityMap } = useScenarioContext();
  const [roles, setRoles] = useState();
  const entityRelationship = ucfirst(DesignEntity.toPlural(entityType));

  useEffect(() => {
    if (!entity) {
      return;
    }

    const roles = [];

    Array.from(entity.__roles.values()).forEach(({ uuid }) => {
      const role = snapshotEntityMap.get(DesignEntity.ROLE).get(uuid);

      if (role?.user_uuid && !role.__person) {
        role.__person = snapshotEntityMap
          .get(DesignEntity.PERSON)
          .get(role.user_uuid);
      }

      roles.push(role);
    });

    setRoles(roles);
  }, [entity]);

  return (
    entityType && (
      <Box
        sx={{
          mx: 1,
          color: `${colors.font}`,
        }}
      >
        {roles?.length > 0 ? (
          <>
            <Typography
              variant="body2"
              sx={{
                mb: 1,
                fontWeight: 500,
              }}
            >
              {entityRelationship} performing this activity:
            </Typography>
            {roles.map((role) => (
              <PersonRoleSummary
                key={role?.uuid}
                entity={role}
                colors={colors}
              />
            ))}
          </>
        ) : (
          <Typography
            variant="body2"
            sx={{
              fontWeight: 500,
            }}
          >
            This activity is performed by a role that hasn't been assigned
          </Typography>
        )}
      </Box>
    )
  );
};

export default RelatedEntityListChart;
