import { EntityChip, StatusChip } from '@/atoms/chips';
import { Size } from '@/atoms/enums';
import { Heading } from '@/atoms/typography';
import { EntityType } from '@/shared/enums';
import Box from '@mui/material/Box';

const GroupTitle = ({ group, status }) => {
  return (
    group && (
      <Box>
        <EntityChip
          size={Size.XX_SMALL}
          sx={{ height: 18, px: 1 }}
          type={EntityType.GROUP}
        />
        <Heading variant="h4">
          {group.name}
          {status && (
            <Box
              sx={{
                display: 'inline',
                ml: 0.75,
                verticalAlign: 'text-bottom',
              }}
            >
              <StatusChip status={status} size={Size.XX_SMALL} />
            </Box>
          )}
        </Heading>
      </Box>
    )
  );
};

export default GroupTitle;
