import { Base as BaseColor } from '@/atoms/colors';
import { Size } from '@/atoms/enums';
import { Paragraph } from '@/atoms/typography';
import { GroupTitle } from '@/molecules/groups';
import { SummaryHeading } from '@/molecules/headings';
import { RoleTitle } from '@/molecules/roles';
import { EntityType } from '@/shared/enums';
import { useScenarioContext } from '@/shared/providers';
import Box from '@mui/material/Box';

const getMovedEntity = ({ moved, scenario, moveType }) => {
  if (moved.role?.[moveType] || moved?.manager?.[moveType]) {
    const id = moved.role?.[moveType] || moved?.manager?.[moveType];

    return scenario?.relationships?.get(EntityType.ROLE)?.get(id);
  }

  if (moved.group?.[moveType]) {
    const id = moved.group?.[moveType];
    return scenario?.relationships?.get(EntityType.GROUP)?.get(id);
  }
};

const MovedEntity = ({ moved }) => {
  const { comparisonScenario, scenario } = useScenarioContext();

  const movedFrom = getMovedEntity({
    moved,
    scenario: comparisonScenario,
    moveType: 'from',
  });
  const movedTo = getMovedEntity({ moved, scenario: scenario, moveType: 'to' });

  return (
    <>
      <SummaryHeading title="Moved" />
      {movedFrom && (
        <Box px={2} mt={1}>
          <Paragraph size={Size.XX_SMALL} overrideStyles={{ mb: 0.5 }}>
            FROM
          </Paragraph>
          <Box
            p={1}
            sx={{
              borderRadius: '2px',
              boxShadow: `0 0 0 1px ${BaseColor.border.primary}`,
            }}
          >
            {movedFrom.__type === EntityType.ROLE && (
              <RoleTitle role={movedFrom} />
            )}
            {movedFrom.__type === EntityType.GROUP && (
              <GroupTitle group={movedFrom} />
            )}
          </Box>
        </Box>
      )}
      {movedTo && (
        <Box px={2} mt={1}>
          <Paragraph size={Size.XX_SMALL} overrideStyles={{ mb: 0.5 }}>
            TO
          </Paragraph>
          <Box
            p={1}
            sx={{
              borderRadius: '2px',
              boxShadow: `0 0 0 1px ${BaseColor.border.primary}`,
            }}
          >
            {movedTo.__type === EntityType.ROLE && <RoleTitle role={movedTo} />}
            {movedTo.__type === EntityType.GROUP && (
              <GroupTitle group={movedTo} />
            )}
          </Box>
        </Box>
      )}
    </>
  );
};

export default MovedEntity;
