import { Collections } from '@pkg/utils';
import shortuuid from '@pkg/uuid';

/**
 * @param {Object} snapshot
 * @param {Object} input
 * @param {Number} input.count
 * @param {String} input.groupId
 * @param {String} input.parentId
 * @param {String} input.newId
 * @param {Object} input.source
 * @returns {Object}
 */
export default function insert(
  snapshot,
  { count = 1, groupId, newId, parentId, source }
) {
  const entities = snapshot.entities;
  const now = Date.now();

  const group = groupId ? { uuid: groupId, updated_at: now } : null;
  const roles = group
    ? Collections.where(entities.roles, 'group_uuid', group.uuid)
    : [];

  const sourceRole = source.snapshot.entities.roles[0];
  const sourceRoleActivities = source.snapshot.entities.activities || [];

  const role = {
    uuid: newId,
    group_uuid: groupId,
    parent_uuid: parentId,
    user_uuid: null,
    title: sourceRole.title,
    order: roles.length,
    fte: sourceRole.fte,
    properties: [],
    skills: [],
    tags: [],
    created_at: now,
    updated_at: now,
  };

  const activities = [];

  let newRoles = Array.from({ length: count }, () => role);

  if (count > 1) {
    newRoles = newRoles.map((role) => {
      return {
        ...role,
        uuid: shortuuid.generate(),
      };
    });
  }

  newRoles.forEach((role) => {
    sourceRoleActivities.forEach((activity) => {
      activities.push({
        uuid: shortuuid.generate(),
        library_uuid: activity.library_uuid,
        owner_uuid: role.uuid,
        owner_type: activity.owner_type,
        hours: activity.hours || 1,
        properties: activity.properties || [],
        tags: activity.tags || [],
        order: activity.order,
        created_at: now,
        updated_at: now,
      });
    });
  });

  const mutation = {
    created_at: now,
    entities: {
      roles: {
        create: newRoles,
      },
      activities: {
        create: activities,
      },
    },
  };

  return mutation;
}
