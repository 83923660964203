import { useEffect, useState } from 'react';
import * as Colors from '@/atoms/colors';
import { Divider } from '@/atoms/dividers';
import { Paragraph } from '@/atoms/typography';
import TagsByCategory from '@/molecules/tags/TagsByCategory';
import { TagOverview } from '@/organisms/tags';
import { useTaggedScenarioActivities } from '@/shared/hooks';
import { useScenarioContext } from '@/shared/providers/ScenarioProvider';
import Stack from '@mui/material/Stack';
import Box from '@/components/Box';
import TagListText from './TagListText';
import useComparisonActivitiesByTag from './useComparisonActivitiesByTag';

const TagListOverview = ({
  combineTagCategories,
  direction = 'column',
  isCondensedView,
  initialSelectedTag,
  onSelectTag,
  showCustomTags,
  showComparisonDifferences,
  visibleTagSet,
  useComparison = false,
}) => {
  const { scenario: primary, comparisonScenario } = useScenarioContext();
  const scenario = useComparison ? comparisonScenario : primary;
  const comparisonTags = useComparisonActivitiesByTag(comparisonScenario);
  const [selectedTag, setSelectedTag] = useState(initialSelectedTag);
  const [showActivityOverview, setShowActivityOverview] = useState(false);
  const tagListText = TagListText[scenario?.entity?.__type];
  const { customTags, primaryTags, taggedActivityMap } =
    useTaggedScenarioActivities({
      combineTagCategories,
      visibleTagSet,
      useComparison,
    });
  const totalHours = taggedActivityMap?.totalHours;

  // This effect sets the active state of the activity overview based on a
  // tag being selected or not.
  useEffect(() => {
    if (!selectedTag) {
      setShowActivityOverview(false);
      return;
    }

    setShowActivityOverview(true);
    onSelectTag?.(selectedTag);
  }, [selectedTag]);

  // This effect updates the selected tag should it's metrics change.
  useEffect(() => {
    if (selectedTag) {
      const tag = taggedActivityMap?.category
        ?.get(selectedTag?.categoryId)
        ?.tags?.get(selectedTag?.uuid);

      setSelectedTag(tag);
    }
  }, [
    JSON.stringify(
      taggedActivityMap?.category
        ?.get(selectedTag?.categoryId)
        ?.tags?.get(selectedTag?.uuid)
    ),
  ]);

  const handleSelectTag = (tagCategoryId, tagId) => {
    if (!tagCategoryId || !tagId) {
      setSelectedTag(null);
    }

    const selectedTag = taggedActivityMap?.category
      ?.get(tagCategoryId)
      ?.tags?.get(tagId);

    setSelectedTag(selectedTag);
  };

  // Allows a parent component to override the currently selected tag.
  useEffect(() => {
    if (initialSelectedTag?.uuid !== selectedTag?.uuid) {
      setSelectedTag(initialSelectedTag);
    }
  }, [initialSelectedTag?.uuid]);

  return (
    <Box sx={{ overflow: 'hidden', position: 'relative' }}>
      {primaryTags?.length > 0 || customTags?.length > 0 ? (
        <Stack
          direction="row"
          divider={!isCondensedView && <Divider orientation="vertical" />}
          sx={{
            transform:
              selectedTag && isCondensedView
                ? 'translate(-100%, 0)'
                : 'translate(0, 0)',
            transition: 'transform 500ms',
          }}
        >
          <Box
            width={selectedTag ? (isCondensedView ? '100%' : '50%') : '100%'}
            minWidth={selectedTag ? (isCondensedView ? '100%' : '50%') : '100%'}
            py={3}
            px={2}
            sx={{ transition: 'all 300ms' }}
          >
            <Stack spacing={3} sx={{ width: '100%' }}>
              {primaryTags?.length > 0 && (
                <TagsByCategory
                  activeTag={selectedTag}
                  isCondensedView={isCondensedView}
                  direction={showComparisonDifferences ? 'row' : direction}
                  tags={primaryTags}
                  comparison={comparisonTags}
                  onClick={handleSelectTag}
                  totalHours={totalHours}
                />
              )}
              {showCustomTags &&
                !combineTagCategories &&
                customTags?.map((tag) => (
                  <TagsByCategory
                    activeTag={selectedTag}
                    isCondensedView={isCondensedView}
                    direction={useComparison ? 'row' : direction}
                    tags={tag.tags}
                    comparison={comparisonTags}
                    title={tag.name}
                    key={tag.uuid}
                    onClick={handleSelectTag}
                    totalHours={totalHours}
                  />
                ))}
            </Stack>
          </Box>
          {showActivityOverview && (
            <Box
              width={selectedTag ? (isCondensedView ? '100%' : '50%') : '100%'}
              minWidth={
                selectedTag ? (isCondensedView ? '100%' : '50%') : '100%'
              }
              p={2}
            >
              <TagOverview
                isCondensedView={isCondensedView}
                tag={selectedTag}
                totalHours={totalHours}
                onClick={handleSelectTag}
                onClose={isCondensedView ? () => handleSelectTag() : null}
              />
            </Box>
          )}
        </Stack>
      ) : (
        <Box m={8} sx={{ textAlign: 'center' }}>
          <Paragraph color={Colors.Base.font.primary}>
            {tagListText?.empty}
          </Paragraph>
        </Box>
      )}
    </Box>
  );
};

export default TagListOverview;
