import { useMutation } from '@pkg/graphql';

const UPDATE_LIBRARY_ROLE = /* GraphQL */ `
  mutation updateLibraryRole($input: UpdateLibraryRoleInput!) {
    updateLibraryRole(input: $input) {
      uuid
    }
  }
`;

/**
 * @return {Function}
 */
export default function useUpdate() {
  const { mutateAsync } = useMutation({
    mutation: UPDATE_LIBRARY_ROLE,
  });

  /**
   * @param {String} organisation_uuid
   * @param {Object} input
   * @param {String} input.uuid
   * @param {Object} input.snapshot
   * @param {Boolean} input.enabled
   * @returns {Promise}
   */
  async function updateLibraryRole(
    organisation_uuid,
    { uuid, snapshot, enabled }
  ) {
    return mutateAsync({ organisation_uuid, uuid, snapshot, enabled });
  }

  return updateLibraryRole;
}
