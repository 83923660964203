import * as Mutations from './mutations';

/**
 * @param {any[]} query
 * @return {Object}
 */
export default function useMutations(query) {
  return {
    delete: Mutations.useDelete(query),
    update: Mutations.useUpdate(query),
  };
}
