import { useState, cloneElement } from 'react';
import { Button } from '@/atoms/buttons';
import { Divider } from '@/atoms/dividers';
import { Size } from '@/atoms/enums';
import { Heading } from '@/atoms/typography';
import { InformationTip } from '@/molecules/modals';
import { OutlineCard } from '@/organisms/cards';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import Box from '@mui/material/Box';
import Grow from '@mui/material/Grow';
import Stack from '@mui/material/Stack';
import TrapFocus from '@mui/material/Unstable_TrapFocus';
import { core } from '@/lib/theme/tokens/palettes';

const ExpandChartButton = ({ onClick, expanded }) => {
  return expanded ? (
    <Button
      color="light-blue"
      variant="naked"
      onClick={onClick}
      label="Exit"
      startIcon={<CloseFullscreenIcon />}
      size={Size.LARGE}
    />
  ) : (
    <Button
      color="light-blue"
      variant="naked"
      onClick={onClick}
      label="Fullscreen"
      startIcon={<OpenInFullIcon />}
      size={Size.LARGE}
    />
  );
};

const ChartCard = ({
  title,
  ControlsComponent,
  InfoComponent,
  showComparison,
  comparisonColor,
  children,
}) => {
  const [expanded, setExpanded] = useState(false);

  const handleExpandCollapse = () => {
    setExpanded(!expanded);
  };

  const chartComponent = cloneElement(children, {
    fullscreen: expanded,
    InfoComponent,
    hideBorder: expanded,
    restrictHeight: !expanded,
    ExpandChartButton,
    onClickExpand: handleExpandCollapse,
  });

  return expanded ? (
    <TrapFocus open={expanded} disableAutoFocus disableEnforceFocus>
      <Box
        position="fixed"
        backgroundColor={core.white}
        width="calc(100vw)"
        height="calc(100vh - 93px)"
        top={93}
        left={0}
        zIndex={5}
        sx={{
          overflowY: 'auto',
          borderLeft: showComparison
            ? `4px solid ${comparisonColor?.fill}`
            : 'none',
        }}
      >
        <Grow in={expanded}>
          <Box>
            <Box
              position="fixed"
              top={93}
              left={0}
              width="100%"
              backgroundColor={core.white}
              zIndex={1}
            >
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                py={1}
                px={2}
              >
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Heading
                    variant="h3"
                    overrideStyles={{
                      fontSize: '1.255rem',
                      lineHeight: '1.625rem',
                    }}
                  >
                    {title}
                  </Heading>

                  {Boolean(InfoComponent) && (
                    <InformationTip>{InfoComponent}</InformationTip>
                  )}
                </Stack>
                <ExpandChartButton
                  onClick={handleExpandCollapse}
                  expanded={expanded}
                />
              </Stack>
              <Box pb={2} px={2}>
                {ControlsComponent}
              </Box>
              <Divider />
            </Box>
            <Box px={2} pb={2} mt={14}>
              {chartComponent}
            </Box>
          </Box>
        </Grow>
      </Box>
    </TrapFocus>
  ) : (
    <OutlineCard
      title={title}
      information={InfoComponent}
      showComparison={showComparison}
      comparisonColor={comparisonColor}
      headerAction={
        <ExpandChartButton onClick={handleExpandCollapse} expanded={expanded} />
      }
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent={ControlsComponent ? 'space-between' : 'flex-end'}
      >
        {ControlsComponent}
      </Stack>
      <Box textAlign="center" py={2}>
        {chartComponent}
      </Box>
    </OutlineCard>
  );
};

export default ChartCard;
