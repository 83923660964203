import PropTypes from 'prop-types';
import { cloneElement, useEffect, useState } from 'react';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import LoadingButton from '@pkg/forms/LoadingButton';
import Button from '@/components/Button';
import Dialog from '@/components/Dialog';

const DialogAction = ({
  children,
  title = 'Confirm action',
  content = 'Are you sure you want to do this action?',
  confirm = 'Yes',
  cancel = 'No',
  onConfirm = () => {},
  buttonProps,
}) => {
  const [open, setOpen] = useState(false);
  const [confirming, setConfirming] = useState(false);

  function handleClickOpen(event) {
    event.stopPropagation();
    setOpen(true);
  }

  async function handleConfirm(event) {
    event.stopPropagation();
    setConfirming(true);
    await onConfirm();
    setConfirming(false);
    setOpen(false);
  }

  function handleClose(event) {
    event.stopPropagation();
    setOpen(false);
  }

  useEffect(() => {
    return () => {
      setConfirming(false);
    };
  }, []);

  return (
    <>
      {cloneElement(children, { onClick: handleClickOpen })}
      <Dialog open={open} onClose={handleClose} role="dialog">
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{content}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            variant="secondary"
            autoFocus
            data-testid="btn-cancel"
          >
            {cancel}
          </Button>
          <LoadingButton
            variant="primary"
            loading={confirming}
            onClick={handleConfirm}
            data-testid="btn-confirm"
            {...buttonProps}
          >
            {confirm}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

DialogAction.propTypes = {
  title: PropTypes.string,
  content: PropTypes.node,
  confirm: PropTypes.string,
  cancel: PropTypes.string,
  onConfirm: PropTypes.func,
};

export default DialogAction;
