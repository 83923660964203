import { useRef } from 'react';
import { Entity as EntityColors } from '@/atoms/colors';
import { PlanRoleCard } from '@/organisms/cards';
import { ActionType, EntityType } from '@/shared/enums';
import { useOutsideClick } from '@/shared/hooks';
import { usePlanContext } from '@/shared/providers';
import Grid from '@mui/material/Grid';

const PlanRoleType = ({ onSelect, roleType }) => {
  const entityColor = EntityColors[EntityType.ROLE];
  const { selectedSummaryItem, selectSummaryItem } = usePlanContext();
  const ref = useRef();

  /**
   * Handles the default selection of the role type screen.
   */
  const handleSelectDefault = (event) => {
    event?.preventDefault();
    event?.stopPropagation();

    // Don't trigger an update if we're already on the default view.
    if (selectedSummaryItem?.id === roleType.id) {
      return;
    }

    selectSummaryItem({
      ...roleType,
      entityType: EntityType.ROLE,
    });
  };

  /**
   * Handles the selection of role cards.
   */
  const handleSelect = (role) => {
    if (!role || role.id === selectedSummaryItem?.id) {
      handleSelectDefault();
      return;
    }

    // Add the moved properties on select.
    const movedAction = role?.actions?.get(ActionType.UPDATED_MANAGER);
    const movedRole = movedAction?.[EntityType.ROLE]?.get(role.id);

    if (movedRole) {
      role.props = {
        ...movedRole.props,
        ...role.props,
      };
    }

    selectSummaryItem({
      ...role,
      entityType: EntityType.ROLE,
    });
  };

  // Handles the click events triggered outside of the role type cards which
  // should default the view back to the role type screen.
  useOutsideClick({
    ref,
    onClick: handleSelectDefault,
    updateProperty: selectedSummaryItem?.id,
  });

  return (
    <Grid container spacing={2} p={2} onClick={handleSelectDefault} ref={ref}>
      {[...roleType[EntityType.ROLE].entries()].map(([id, role]) => (
        <Grid item xs={12} key={id}>
          <PlanRoleCard
            actions={role.actions}
            id={id}
            isSelected={id === selectedSummaryItem?.id}
            metrics={role.metrics}
            onSelect={handleSelect}
            roleProperties={role.properties}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default PlanRoleType;
