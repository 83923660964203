import { useEffect, useState } from 'react';
import { useScenarioContext, useViewModeContext } from '@/shared/providers';
import { aggregateMetrics, summaryMetricList } from '../utils';

const useSummaryMetrics = () => {
  const { comparisonScenario, scenario } = useScenarioContext();
  const { showBudget } = useViewModeContext();

  const [selected, setSelected] = useState();
  const [comparisonMetrics, setComparisonMetrics] = useState();
  const [metricList, setMetricList] = useState();
  const [scenarioMetrics, setScenarioMetrics] = useState();

  const selectSummaryItem = (summaryItem) => {
    if (!summaryItem || summaryItem.id === selected?.id) {
      setSelected(null);
      return;
    }
    setSelected(summaryItem);
  };

  useEffect(() => {
    if (!selected) {
      const comparisonMetrics = comparisonScenario
        ? aggregateMetrics(comparisonScenario.entity)
        : null;
      const scenarioMetrics = scenario
        ? aggregateMetrics(scenario.entity)
        : null;

      const metricList = summaryMetricList({
        comparisonMetrics,
        scenarioMetrics,
        showBudget,
      });

      setComparisonMetrics(comparisonMetrics);
      setScenarioMetrics(scenarioMetrics);
      setMetricList(metricList);

      return;
    }

    const { metrics } = selected;

    const comparisonMetrics = metrics[0];
    const scenarioMetrics = metrics[1];

    const metricList = summaryMetricList({
      comparisonMetrics,
      scenarioMetrics,
      showBudget,
    });

    setComparisonMetrics(comparisonMetrics);
    setScenarioMetrics(scenarioMetrics);
    setMetricList(metricList);
  }, [
    selected,
    showBudget,
    scenario?.details?.hash,
    comparisonScenario?.details?.hash,
  ]);

  return {
    comparisonMetrics,
    metricList,
    scenarioMetrics,
    selectedSummaryItem: selected,
    selectSummaryItem,
  };
};

export default useSummaryMetrics;
