import { defaultMetrics } from '@/organisms/plans';
import { ActionType, EntityType } from '@/shared/enums';

export default function removedRoleAction({ role, comparisonScenario }) {
  const metrics = role.__metrics.self.total;

  const action = {
    type: ActionType.REMOVED_ROLE,
    [EntityType.ROLE]: new Map([
      [
        role.uuid,
        {
          metrics,
        },
      ],
    ]),
    metrics: defaultMetrics({ comparisonScenario }),
  };

  action.metrics[1].activities -= metrics.activities;
  action.metrics[1].budget -= metrics.budget;
  action.metrics[1].fte -= metrics.fte;
  action.metrics[1].hours -= metrics.hours;
  action.metrics[1].roles -= 1;

  return action;
}
