const systemSlice = ({ lastMessages }) => {
  return `

  Last messages;
  ${lastMessages.map((msg) => `${msg.sender}: ${msg.message}`).join('\n')}

  The last messages are included to help you understand the context of the users request. BUT MAKE SURE TO IGNORE THEM IF THEY ARE NOT RELEVANT. Your response prioritizes the users request if previous mesasages aren't relevant.

  `;
};

export default systemSlice;
