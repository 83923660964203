import { tool } from 'ai';
import { z } from 'zod';

const roleAdd = tool({
  description: 'Add a role to the organisational design',
  parameters: z.object({
    total: z.number().describe('The number of this role to add'),
    payload: z
      .object({
        title: z.string().describe('The title of the role'),
        fte: z
          .number()
          .describe(
            'The full-time equivalent (FTE) allocation for this role (default: 1.0)'
          ),
        budget: z
          .number()
          .describe(
            'The annual budget allocated to this role (default: 100000)'
          ),
        parent_uuid: z
          .string()
          .optional()
          .describe('The UUID of the parent role if this is a subrole'),
        group_uuid: z
          .string()
          .optional()
          .describe('The UUID of the group this role belongs to'),
        user_uuid: z
          .string()
          .optional()
          .describe('The UUID of the user assigned to this role'),
      })
      .describe('The input parameters for creating a new role'),
    explanation: z
      .string()
      .describe(
        'An explanation of what is about to mutated. This step will be asked to be confirmed. Phrase it accordingly.'
      ),
    activities: z
      .array(
        z.object({
          description: z
            .string()
            .describe(
              'Activity description (10-20 words, specific and actionable)'
            ),
          hours: z
            .number()
            .describe('Weekly hours for this activity (default: 0)'),
        })
      )
      .describe(
        'List of 10-15 activities associated with this role, each with a detailed description'
      ),
  }),
  execute: async ({ total, payload, explanation, activities }) => ({
    total,
    payload,
    explanation,
    activities,
  }),
});

export default roleAdd;
