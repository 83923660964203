import { Collections, Sort } from '@pkg/utils';
import { ActivityOwner } from '@/lib/enums';

/**
 * @param {Object} snapshot
 * @param {Object} input
 * @param {String[]} [input.ids]
 * @param {String} [input.ownerId]
 * @param {String} [input.ownerType]
 * @param {Number} input.order
 * @returns {Object}
 */
export default function move(snapshot, { ids, ownerId, ownerType, order }) {
  const entities = snapshot.entities;
  const now = Date.now();

  const mutation = {
    created_at: now,
    entities: {
      groups: {
        update: [],
      },
      roles: {
        update: [],
      },
      activities: {
        update: [],
      },
    },
  };

  const activities = structuredClone(entities.activities);
  activities.sort(Sort.order());

  const keyed = Collections.keyById(activities);
  const movedActivities = new Map();

  const touchedGroups = new Set();
  const touchedRoles = new Set();

  ids.forEach((id) => {
    if (Object.hasOwn(keyed, id)) {
      movedActivities.set(id, structuredClone(keyed[id]));
      delete keyed[id];
    }
  });

  if (movedActivities.size === 0) {
    return null;
  }

  if (ownerType === ActivityOwner.GROUP) {
    touchedGroups.add(ownerId);
  } else {
    touchedRoles.add(ownerId);
  }

  let offset = 0;
  movedActivities.forEach((activity) => {
    if (activity.owner_uuid === ownerId && activity.order < order) {
      offset += 1;
    }

    if (activity.owner_type === ActivityOwner.GROUP) {
      touchedGroups.add(activity.owner_uuid);
    } else {
      touchedRoles.add(activity.owner_uuid);
    }

    activity.owner_uuid = ownerId;
    activity.owner_type = ownerType;
    movedActivities.set(activity.uuid, activity);
  });

  const grouped = Collections.groupBy(Object.values(keyed), 'owner_uuid');
  grouped[ownerId] = grouped[ownerId] ?? [];
  grouped[ownerId].splice(order - offset, 0, ...movedActivities.values());

  Object.keys(grouped).forEach((id) => {
    grouped[id].forEach((activity, order) => {
      const update = { uuid: activity.uuid, updated_at: now };
      let isDifferent = false;

      if (movedActivities.has(activity.uuid)) {
        update.owner_type = activity.owner_type;
        update.owner_uuid = activity.owner_uuid;
        isDifferent = true;
      }

      if (activity.order !== order) {
        update.order = order;
        isDifferent = true;
      }

      if (isDifferent) {
        mutation.entities.activities.update.push(update);
      }
    });
  });

  touchedRoles.forEach((id) => {
    mutation.entities.roles.update.push({ uuid: id, updated_at: now });
  });

  touchedGroups.forEach((id) => {
    mutation.entities.groups.update.push({ uuid: id, updated_at: now });
  });

  return mutation;
}
