import { useState } from 'react';
import { DimensionSelect, MetricTypeSelect } from '@/molecules/chartElements';
import { PropertyDimensions } from '@/molecules/chartElements';
import { PropertyStackChartInformation } from '@/molecules/information';
import { ChartCard } from '@/organisms/cards';
import { EmptyInsights } from '@/organisms/insights';
import { useAggregateChartData } from '@/organisms/insights';
import { EntityType } from '@/shared/enums';
import { useInsightsContext } from '@/shared/providers';
import Stack from '@mui/material/Stack';
import { EntityMetric } from '@/lib/enums';
import PropertiesStackedBarChart from './PropertiesStackedBarChart';

const metricOptions = [
  EntityMetric.RELATIVE_PERCENTAGE,
  EntityMetric.PERCENTAGE,
  EntityMetric.FTE,
  EntityMetric.HOURS,
  EntityMetric.BUDGET,
];

const PropertySegments = ({ levelType, showComparison }) => {
  const [metric, setMetric] = useState(EntityMetric.FTE);
  const [dimension, setDimension] = useState(
    PropertyDimensions[levelType]?.[0]
  );
  const [sort, setSort] = useState({
    metric: EntityMetric.FTE,
    type: 'DESC',
  });
  const { filter } = useInsightsContext();

  const { chartData, metricTotals } = useAggregateChartData({
    entityType: EntityType.ROLE,
    dimension,
    filter,
    includeStack: true,
    sort,
    useComparison: showComparison,
  });

  const hasProperties = chartData?.length > 1;

  const handleDimensionChange = (dimension) => {
    setDimension(dimension);
  };

  const handleMetricTypeChange = (metric) => {
    setMetric(metric.id);
    setSort({
      ...sort,
      metric: metric.id === 'percentage' ? 'hours' : metric.id,
    });
  };

  return (
    <ChartCard
      title="How roles are categorised"
      ControlsComponent={
        hasProperties && (
          <Stack direction="row" alignItems="center" spacing={2}>
            <DimensionSelect
              dimensions={PropertyDimensions[levelType]}
              label="Property by"
              onChange={handleDimensionChange}
            />
            <MetricTypeSelect
              initialMetric={metric}
              onChange={handleMetricTypeChange}
              options={metricOptions}
            />
          </Stack>
        )
      }
      InfoComponent={<PropertyStackChartInformation />}
    >
      {hasProperties ? (
        <PropertiesStackedBarChart
          chartData={chartData}
          metric={metric}
          metricTotals={metricTotals}
          sort={sort}
        />
      ) : (
        <EmptyInsights message="No properties were found." />
      )}
    </ChartCard>
  );
};

export default PropertySegments;
