import {
  cloneElement,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { InfoButton } from '@/atoms/buttons';
import MuiPopover from '@mui/material/Popover';
import { Position } from '@/lib/enums';
import ModalColors from '../utils/ModalColors';

const getOriginFromPosition = (position) => {
  const origin = {
    anchor: {
      horizontal: 'center',
      vertical: 'top',
    },
    transform: {
      vertical: 'bottom',
      horizontal: 'center',
    },
  };

  switch (position) {
    case Position.BOTTOM:
      origin.anchor.vertical = 'bottom';
      origin.transform.vertical = 'top';
      break;
    case Position.BOTTOM_LEFT:
      origin.anchor.vertical = 'bottom';
      origin.anchor.horizontal = 'left';
      origin.transform.vertical = 'top';
      origin.transform.horizontal = 'left';
      break;
    case Position.RIGHT:
      origin.anchor.vertical = 'center';
      origin.anchor.horizontal = 'right';
      origin.transform.vertical = 'center';
      origin.transform.horizontal = 'left';
      break;
    default:
  }

  return origin;
};

const Popover = forwardRef(function Popover(
  {
    Component = <InfoButton />,
    children,
    color = 'primary',
    onClose,
    position = Position.BOTTOM,
    maxHeight,
  },
  ref
) {
  const [anchorEl, setAnchorEl] = useState(null);
  const anchorRef = useRef(null);
  const [origin, setOrigin] = useState();
  const colors = ModalColors[color];

  const handleClick = () => {
    setAnchorEl(anchorEl ? null : anchorRef.current);
  };

  const handleClose = () => {
    setAnchorEl(null);
    onClose?.();
  };

  useEffect(() => {
    setOrigin(getOriginFromPosition(position));
  }, [position]);

  // Make the open event available to parent components.
  useImperativeHandle(ref, () => ({
    open() {
      setAnchorEl(anchorRef.current);
    },
  }));

  return (
    <>
      {cloneElement(Component, { ref: anchorRef, onClick: handleClick })}
      {anchorEl && (
        <MuiPopover
          anchorOrigin={origin?.anchor}
          transformOrigin={origin?.transform}
          PaperProps={{
            elevation: 1,
            sx: {
              ...colors,
              borderRadius: '4px',
              mt: 0.5,
              maxHeight,
            },
          }}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClose}
        >
          {cloneElement(children, { onClose: handleClose })}
        </MuiPopover>
      )}
    </>
  );
});

export default Popover;
