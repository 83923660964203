import { useEffect, useState } from 'react';
import { Chip } from '@/atoms/chips';
import { PlanActionCard } from '@/organisms/cards';
import {
  EmptyPlanList,
  mapAggregateActions,
  PlanListSkeleton,
} from '@/organisms/plans';
import { EntityType } from '@/shared/enums';
import {
  usePlanContext,
  useScenarioContext,
  useViewModeContext,
} from '@/shared/providers';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { groupActions } from './utils';

const ACTION_GROUP_ORDER = [
  EntityType.GROUP,
  EntityType.ROLE,
  EntityType.ACTIVITY,
];

const PlanActionsTypeList = ({ onSelect }) => {
  const { comparisonScenario, scenario } = useScenarioContext();
  const [actions, setActions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isEmpty, setIsEmpty] = useState(true);
  const { showBudget } = useViewModeContext();
  const { selectedSummaryItem, selectSummaryItem } = usePlanContext();

  const handleSummarySelect = (event, action) => {
    event.preventDefault();
    event.stopPropagation();

    selectSummaryItem({
      ...action,
    });
  };

  const handleActionSelect = (event, action) => {
    event.preventDefault();
    event.stopPropagation();

    if (action && selectSummaryItem?.id !== action.id) {
      selectSummaryItem({
        ...action,
      });
    }

    onSelect(action);
  };

  useEffect(() => {
    if (!comparisonScenario || !scenario) {
      return;
    }

    const actions = mapAggregateActions({
      comparisonScenario,
      scenario,
      showBudget,
    });

    if (!actions) {
      return;
    }

    if (!actions.size) {
      setActions([]);
      setLoading(false);
      setIsEmpty(true);
      return;
    }

    // Group the actions by their entity type.
    setActions(
      groupActions(
        [...actions.entries()].map(([id, action]) => {
          return {
            actionType: id,
            ...action,
            id,
          };
        })
      )
    );
    setLoading(false);
    setIsEmpty(false);
  }, [scenario?.details?.hash, comparisonScenario?.details?.hash, showBudget]);

  return loading ? (
    <PlanListSkeleton />
  ) : (
    <>
      {isEmpty ? (
        <EmptyPlanList
          message={`No changes have been found in this scenario.`}
        />
      ) : (
        <>
          {ACTION_GROUP_ORDER.map(
            (entity, index) =>
              actions[entity] && (
                <Grid
                  container
                  key={entity}
                  spacing={2}
                  mt={index > 0 ? 2 : 0}
                  p={2}
                >
                  <Grid
                    item
                    xs={12}
                    pl={8}
                    pt={index === 0 ? '0 !important' : 2}
                  >
                    <Box ml="144px">
                      <Chip
                        fontWeight={700}
                        name={actions[entity].label}
                        colorProps={actions[entity].colorProps}
                        expanded={true}
                      />
                    </Box>
                  </Grid>
                  {actions[entity].actions.map((action) => (
                    <Grid
                      item
                      xs={12}
                      key={action.type}
                      onClick={(event) => handleSummarySelect(event, action)}
                    >
                      <PlanActionCard
                        actionType={action.type}
                        entityType={action.entity}
                        isSelected={action.id === selectedSummaryItem?.id}
                        onEdit={(event) => handleActionSelect(event, action)}
                        metric={action.metric}
                        metrics={action.metrics}
                        title={action.title}
                      />
                    </Grid>
                  ))}
                </Grid>
              )
          )}
        </>
      )}
    </>
  );
};

export default PlanActionsTypeList;
