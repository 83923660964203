import { useRef } from 'react';
import { Entity as EntityColors } from '@/atoms/colors';
import { PlanActivityCard } from '@/organisms/cards';
import { ActionType, EntityType } from '@/shared/enums';
import { useOutsideClick } from '@/shared/hooks';
import { usePlanContext } from '@/shared/providers';
import Grid from '@mui/material/Grid';

const PlanActivityType = ({ onSelect, activityType }) => {
  const entityColor = EntityColors[EntityType.ACTIVITY];
  const { selectedSummaryItem, selectSummaryItem } = usePlanContext();
  const ref = useRef();

  /**
   * Handles the default selection of the activity type screen.
   */
  const handleSelectDefault = (event) => {
    event?.preventDefault();
    event?.stopPropagation();

    // Don't trigger an update if we're already on the default view.
    if (selectedSummaryItem?.id === activityType.id) {
      return;
    }

    selectSummaryItem({
      ...activityType,
      entityType: EntityType.ACTIVITY,
    });
  };

  const handleSelect = (activity) => {
    if (!activity || activity.id === selectedSummaryItem?.id) {
      handleSelectDefault();
      return;
    }

    // Add the moved properties on select.
    const movedAction = activity.actions?.get(ActionType.MOVED_ACTIVITY);
    const movedActivity = movedAction?.[EntityType.ACTIVITY]?.get(activity.id);

    if (movedActivity) {
      activity.props = {
        ...activity.props,
        ...movedActivity.props,
      };
    }

    selectSummaryItem({
      ...activity,
      entityType: EntityType.ACTIVITY,
    });
  };

  // Handles the click events triggered outside of the role type cards which
  // should default the view back to the role type screen.
  useOutsideClick({
    ref,
    onClick: handleSelectDefault,
    updateProperty: selectedSummaryItem?.id,
  });

  return (
    <Grid container spacing={2} p={2} onClick={handleSelectDefault} ref={ref}>
      {[...activityType[EntityType.ACTIVITY].entries()].map(
        ([id, activity]) => (
          <Grid item xs={12} key={id}>
            <PlanActivityCard
              id={id}
              actions={activity.actions}
              metrics={activity.metrics}
              onSelect={handleSelect}
              isSelected={id === selectedSummaryItem?.id}
            />
          </Grid>
        )
      )}
    </Grid>
  );
};

export default PlanActivityType;
