import { tool } from 'ai';
import { z } from 'zod';

const createFilter = tool({
  description: 'Create a filter configuration based on user requirements',
  parameters: z.object({
    filters: z
      .object({
        conditions: z
          .array(
            z.object({
              entity: z.object({
                key: z.string().describe('Entity key (e.g., TAG, ROLE, GROUP)'),
                label: z
                  .string()
                  .describe('Human readable label for the entity'),
              }),
              property: z.object({
                key: z.string().describe('Property key to filter on'),
                label: z
                  .string()
                  .describe('Human readable label for the property'),
              }),
              type: z
                .string()
                .describe('Type of the filter (e.g., TEXT, MULTI_SELECT)'),
              propertyId: z.nullable(z.string()),
              comparator: z.object({
                key: z.string().describe('Comparison operator key'),
                label: z
                  .string()
                  .describe('Human readable label for the comparator'),
              }),
              value: z
                .union([
                  z.number(),
                  z.string(),
                  z.array(
                    z.object({
                      id: z.string(),
                      label: z.string(),
                    })
                  ),
                ])
                .describe(
                  'Filter value - can be string or array of objects for multi-select'
                ),
            })
          )
          .describe('Array of filter conditions'),
      })
      .describe('Filter configuration object'),
    explanation: z
      .string()
      .describe('Short explanation of the created filter configuration'),
  }),
  execute: async ({ filters, explanation }) => ({
    filters,
    explanation,
  }),
});

export default createFilter;
