import { Chip } from '@/atoms/chips';
import { Entity as EntityColors } from '@/atoms/colors';
import { Size } from '@/atoms/enums';
import { Heading } from '@/atoms/typography';
import Box from '@mui/material/Box';
import { core } from '@/lib/theme/tokens/palettes';

const ActionSummaryOverview = ({ action }) => {
  const entityColor = EntityColors[action.entity];

  return (
    <>
      <Box>
        <Chip
          id={action.actionType}
          name={action.actionType.toUpperCase()}
          colorProps={{
            normal: {
              backgroundColor: entityColor.primary,
              color: core.white,
            },
          }}
          size={Size.XX_SMALL}
          fontWeight={600}
          sx={{ height: 20, px: 1 }}
        />
      </Box>
      <Heading variant="h4" overrideStyles={{ mt: 1 }}>
        {action.title}
      </Heading>
    </>
  );
};

export default ActionSummaryOverview;
