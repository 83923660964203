import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { Select } from '@/atoms/inputs';
import { SkeletonItem } from '@/atoms/skeletons';
import { useScenarioContext } from '@/shared/providers';
import Box from '@mui/material/Box';
import DesignOption from './DesignOption';
import useDesignOptions from './useDesignOptions';

const INPUT_WIDTH = 320;

const DesignSelector = ({ viewMode }) => {
  const { scenario } = useScenarioContext();
  const { options } = useDesignOptions();
  const router = useRouter();
  const [initialValue, setInitialValue] = useState();

  const handleChange = (option) => {
    if (!option) {
      return;
    }

    const { query } = router;
    delete query.parts;

    router.push({
      pathname: option.path,
      query,
    });
  };

  useEffect(() => {
    if (initialValue || !options?.length || !scenario?.details?.designId) {
      return;
    }

    const isScenario = scenario.details?.isScenario;

    const selected = options.find(
      ({ id }) =>
        (id === 'main' && !isScenario) || id === scenario.details.designId
    );

    setInitialValue(selected);
  }, [options, scenario?.details?.designId]);

  return initialValue ? (
    <Select
      initialValue={initialValue}
      options={options}
      OptionComponent={<DesignOption />}
      onChange={handleChange}
      width={INPUT_WIDTH}
    />
  ) : (
    <Box width={INPUT_WIDTH}>
      <SkeletonItem color="secondary" height={30} />
    </Box>
  );
};

export default DesignSelector;
