import { useState } from 'react';
import { ActivityConfig } from '@/organisms/activities';
import {
  ActivityCardList,
  EntityOverviewCard,
  TagListCard,
} from '@/organisms/cards';
import { RoleActionBar } from '@/organisms/toolbars';
import { useCollaborators } from '@/shared/hooks';
import { useComparisonMetrics } from '@/shared/hooks';
import FlowsProvider from '@/shared/providers/FlowsProvider';
import { useScenarioContext } from '@/shared/providers/ScenarioProvider';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

export const RoleLayout = () => {
  const { scenario, comparisonScenario } = useScenarioContext();
  const { permission } = useCollaborators();
  const [showComparison, setShowComparison] = useState(false);

  const { metrics, activityMap } = useComparisonMetrics(
    scenario,
    comparisonScenario
  );

  const hasComparison = Boolean(comparisonScenario);

  const handleComparisonToggle = (value) => {
    setShowComparison(value);
  };

  return (
    <>
      <Grid
        container
        sx={{
          pb: 12,
          width: '100%',
          overflowX: 'hidden',
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            mb: 4,
            transform: showComparison
              ? 'translate(0, 0)'
              : 'translate(-22.5%, 0)',
            transition: 'transform 300ms',
          }}
        >
          <Stack direction="row">
            <Box width="45%">
              {showComparison && (
                <Fade in={showComparison} style={{ transitionDelay: '300ms' }}>
                  <div>
                    <EntityOverviewCard
                      isEditable={permission?.edit}
                      useComparison={true}
                    />
                  </div>
                </Fade>
              )}
            </Box>
            <Box width="55%" pl={showComparison ? 6 : 0}>
              <EntityOverviewCard isEditable={permission?.edit} />
            </Box>
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            mb: 6,
            transform: showComparison
              ? 'translate(0, 0)'
              : 'translate(-22.5%, 0)',
            transition: 'transform 300ms',
          }}
        >
          <Stack direction="row">
            <Box width="45%">
              {showComparison && (
                <Fade in={showComparison} style={{ transitionDelay: '300ms' }}>
                  <div>
                    <ActivityCardList
                      settings={ActivityConfig}
                      useComparison={true}
                    />
                  </div>
                </Fade>
              )}
            </Box>
            <Box width="55%" pl={showComparison ? 6 : 0}>
              <ActivityCardList
                settings={{
                  ...ActivityConfig,
                  isEditable: true,
                  showDragDrop: true,
                }}
                comparisonActivityMap={activityMap}
              />
            </Box>
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            mb: 6,
            transform: showComparison
              ? 'translate(0, 0)'
              : 'translate(-22.5%, 0)',
            transition: 'transform 300ms',
          }}
        >
          <Stack direction="row">
            <Box width="45%">
              {showComparison && (
                <Fade in={showComparison} style={{ transitionDelay: '300ms' }}>
                  <div>
                    <TagListCard
                      title="Activity tags"
                      direction="row"
                      isCondensedView
                      useComparison={true}
                    />
                  </div>
                </Fade>
              )}
            </Box>
            <Box width="55%" pl={showComparison ? 6 : 0}>
              <TagListCard
                title="Activity tags"
                direction="row"
                isCondensedView
                showComparisonDifferences
              />
            </Box>
          </Stack>
        </Grid>
      </Grid>
      {hasComparison && (
        <FlowsProvider>
          <RoleActionBar metrics={metrics} onCompare={handleComparisonToggle} />
        </FlowsProvider>
      )}
    </>
  );
};

export default RoleLayout;
