import { defaultMetrics } from '@/organisms/plans/utils';
import { ActionType, EntityType } from '@/shared/enums';

export default function movedActivity({
  activity,
  comparisonScenario,
  originalActivity,
}) {
  const action = {
    type: ActionType.MOVED_ACTIVITY,
    [EntityType.ACTIVITY]: new Map(),
    metrics: defaultMetrics({ comparisonScenario }),
  };

  const activityProperties = {
    metrics: {
      hours: activity.hours,
    },
    moved: {},
  };

  if (activity.owner_type === EntityType.ROLE) {
    activityProperties.moved = {
      role: {
        to: activity.owner_uuid,
      },
    };
  }
  if (originalActivity.owner_type === EntityType.ROLE) {
    const role = activityProperties?.moved?.role;
    activityProperties.moved = {
      ...activityProperties.moved,
      role: {
        ...role,
        from: originalActivity.owner_uuid,
      },
    };
  }
  if (activity.owner_type === EntityType.GROUP) {
    activityProperties.moved = {
      ...activityProperties.moved,
      group: {
        to: activity.owner_uuid,
      },
    };
  }
  if (originalActivity.owner_type === EntityType.GROUP) {
    activityProperties.moved = {
      ...activityProperties.moved,
      group: {
        from: originalActivity.owner_uuid,
      },
    };
  }

  action[EntityType.ACTIVITY].set(activity.uuid, { props: activityProperties });

  action.metrics[1].moved += 1;

  return action;
}
